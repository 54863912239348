import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEntity } from "@/legacy/useEntity";
import { useCache } from "@/Hooks/useCache";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import { EntityViewerToolbar } from "../EntityViewerToolbar";
import { getImageUploadHandler } from "../../../Components/FormComponents/HtmlEditor/imageUploadHandler";
import { FormError } from "@shared/Components/Form/FormError";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";
import { FormSection } from "@shared/Components/Form/FormSection";
import { CannedReply } from "@shared/Entities/EntityTypes";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Entities } from "@shared/Entities/Entities";
import W_HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/W_HtmlEditor";
import { sanitizeHtml } from "@shared/Helpers/HtmlFunctions";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

export function CannedReplyViewer() {
	const [id, setId] = useState<number>();
	const [editmode, setEditmode] = useState<boolean>(false);
	const cache = useCache();

	const navigate = useNavigate();
	const params = useParams();
	const { data, formError, fieldErrors, updateEntity, createEntity, deleteEntity } = useEntity<CannedReply>(id, Entities.CANNEDREPLY);
	const { control, handleSubmit, reset, setValue, setError, watch } = useForm<CannedReply>({ defaultValues: data });

	useEffect(() => {
		// Override form data when data changes.
		reset(data);
	}, [data]);

	useEffect(() => {
		// Override errors when they change in props.
		OtherFunctions.setFieldErrors(setError, fieldErrors);
	}, [fieldErrors]);

	// Get id from url.
	useEffect(() => {
		if (params.id != null && !isNaN(parseInt(params.id))) {
			const paramsId = parseInt(params.id);
			setId(paramsId);

			if (paramsId == -1) {
				setEditmode(true);
			}
		}
	}, [params.id]);

	async function onDelete() {
		const deleteSuccess = await deleteEntity();

		if (deleteSuccess) {
			navigate("/config/manage/cannedreplies");
		}
	}

	function cancelEdit() {
		setEditmode(false);
		reset(data);

		if (id == -1) {
			navigate("/config/manage/cannedreplies");
		}
	}

	async function formSubmit(data: CannedReply) {
		if (data.id > 0) {
			const updateSuccess = await updateEntity(data);

			if (updateSuccess) {
				setEditmode(false);
			}
		} else {
			const newId = await createEntity(data);

			if (newId != null && newId > -1) {
				setEditmode(false);
				navigate("/config/manage/cannedreplies/" + newId);
			}
		}
	}

	const name = watch("name");

	return (
		id != null && data != null ?
			<form onSubmit={handleSubmit(formSubmit)}>
				<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={cancelEdit} />

				<EntityViewerToolbar
					editmode={editmode}
					isNew={id == -1}
					backUrl="/config/manage/cannedreplies"
					onEdit={() => setEditmode(true)}
					onCancel={cancelEdit}
					onDelete={onDelete}
				/>

				{editmode ?
					<FormParent title={id == -1 ? "New Canned Reply" : (name ?? "")}>

						<div className="px-3">
							<FormError error={formError} />
						</div>

						<FormSection title="Details">
							<W_TextInput
								control={control}
								dataname="name"
								label="Name"
								mandatory
								autoFocus={id < 0}
							/>

							<W_TextInput
								control={control}
								dataname="description"
								label="Description"
							/>
						</FormSection>

						<FormSection title="Content" className="w-[50rem]">
							<W_HtmlEditor
								control={control}
								dataname="note"
								agentFeatures
								handleCancel={cancelEdit}
								handleSubmit={(note) => {
									setValue("note", note);
									handleSubmit(formSubmit)();
								}}
								cannedReplies={cache.CannedReplies}
								imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
							/>
						</FormSection>
					</FormParent>
					:
					<Display data={data} />}
			</form>
			:
			null
	);
}

interface DisplayProps {
	data: CannedReply;
}

function Display(props: DisplayProps) {
	const noHtmlNote = sanitizeHtml(props.data.note);
	const note = <div dangerouslySetInnerHTML={{ __html: noHtmlNote }}></div>;

	return (
		<FormParent title={props.data.name ?? ""}>
			<FormSection title="Details">
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={props.data.name}
					/>
					<FormReadPair
						name="Description"
						value={props.data.description}
					/>
				</FormReadBox>
			</FormSection>

			<FormSection title="Content" className="w-[50rem]">
				<div className="text-base p-3 bg-white text-gray-700 rounded border border-gray-200">
					{note}
				</div>
			</FormSection>

			<FormSection>
				<FormReadBox>
					<FormReadPair
						name="Updated"
						value={datetimeToWhenString(props.data.modified)}
					/>
					<FormReadPair
						name="Created"
						value={datetimeToWhenString(props.data.created)}
					/>
				</FormReadBox>
			</FormSection>
		</FormParent>
	);
}
