import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaCircle } from "react-icons/fa";
import { Button, ButtonRed } from "../../../Components/Button/Button";
import TimeAmount from "../../../Components/FormComponents/TimeAmount/TimeAmount";
import OtherFunctions from "../../../Helpers/OtherFunctions";
import DeleteWarningModal from "../../../Components/Utility/Modals/DeleteWarningModal";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Sla } from "@shared/Entities/EntityTypes";

interface SlaViewerProps {
	id: number;
	data?: Sla;
	createNew(data: Sla): void;
	updateData(data: Sla): void;
	delete(): Promise<void>;
}

export default function SlaViewer(props: SlaViewerProps) {
	const [internalData, setInternalData] = useState<Sla | undefined>(props.data);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();
	const { handleSubmit } = useForm();

	useEffect(() => {
		// Override internal data when data changes in props.
		setInternalData(props.data);
	}, [props.data]);

	function handleChangeInFormComponent(key: string, newValue: any) {
		const dataCopy = Object.assign({}, internalData);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		setInternalData(newData);
	}

	function cancelButtonClick() {
		navigate("/config/manage/slas");
	}

	async function deleteSla() {
		await props.delete();
		navigate("/config/manage/slas");
	}

	function formSubmit() {
		if (internalData != null) {
			if (internalData.id > 0) {
				props.updateData(internalData);
			} else {
				props.createNew(internalData);
			}
		}
	}

	return (
		props.data != null ?
			<form onSubmit={handleSubmit(formSubmit)} className="max-w-xl">
				<div style={{ padding: "5px 15px" }}>
					<TextInput
						value={props.data.name}
						dataname="name"
						handleSubmit={handleChangeInFormComponent}
						label="Name"
						mandatory
						autoFocus={props.id < 0}
					/>
				</div>

				<PriorityGrid data={internalData} handleChange={handleChangeInFormComponent} />

				<div className="flex items-center">
					{props.id > 0 ?
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Update" />
							<ButtonRed className="ml-2" label="Delete" onClick={() => setDeleteModalOpen(true)} />
						</>
						:
						<>
							<Button className="ml-4" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Create" />
						</>}
				</div>

				<DeleteWarningModal isOpen={deleteModalOpen} onDelete={deleteSla} onCancel={() => setDeleteModalOpen(false)} message="This will remove this SLA from any tickets using it, leaving them without an SLA." />
			</form>
			:
			null
	);
}

interface PriorityGridProps {
	data?: Sla;
	handleChange(key: string, newValue: number | undefined): void;
}

function PriorityGrid(props: PriorityGridProps) {
	return (
		<div className="grid auto-cols-min bg-white border border-gray-200 shadow-sm" style={{ margin: "20px 15px", gridTemplateColumns: "130px 1fr 1fr" }}>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Priority</div>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Response time</div>
			<div className="p-3 bg-gray-100 text-gray-900 font-semibold">Resolution time</div>

			<PriorityRow
				label="P1"
				labelColor="#F03232"
				handleChange={props.handleChange}
				responseValue={props.data?.p1Response}
				responseUnit={props.data?.p1ResponseUnit}
				responseValueName="p1Response"
				responseUnitName="p1ResponseUnit"
				resolutionValue={props.data?.p1Resolution}
				resolutionUnit={props.data?.p1ResolutionUnit}
				resolutionValueName="p1Resolution"
				resolutionUnitName="p1ResolutionUnit"
			/>

			<PriorityRow
				label="P2"
				labelColor="#F07E32"
				handleChange={props.handleChange}
				responseValue={props.data?.p2Response}
				responseUnit={props.data?.p2ResponseUnit}
				responseValueName="p2Response"
				responseUnitName="p2ResponseUnit"
				resolutionValue={props.data?.p2Resolution}
				resolutionUnit={props.data?.p2ResolutionUnit}
				resolutionValueName="p2Resolution"
				resolutionUnitName="p2ResolutionUnit"
			/>

			<PriorityRow
				label="P3"
				labelColor="#F0E332"
				handleChange={props.handleChange}
				responseValue={props.data?.p3Response}
				responseUnit={props.data?.p3ResponseUnit}
				responseValueName="p3Response"
				responseUnitName="p3ResponseUnit"
				resolutionValue={props.data?.p3Resolution}
				resolutionUnit={props.data?.p3ResolutionUnit}
				resolutionValueName="p3Resolution"
				resolutionUnitName="p3ResolutionUnit"
			/>

			<PriorityRow
				label="P4"
				labelColor="#4EDB27"
				handleChange={props.handleChange}
				responseValue={props.data?.p4Response}
				responseUnit={props.data?.p4ResponseUnit}
				responseValueName="p4Response"
				responseUnitName="p4ResponseUnit"
				resolutionValue={props.data?.p4Resolution}
				resolutionUnit={props.data?.p4ResolutionUnit}
				resolutionValueName="p4Resolution"
				resolutionUnitName="p4ResolutionUnit"
			/>

			<PriorityRow
				label="P5"
				labelColor="#D5D5D5"
				handleChange={props.handleChange}
				responseValue={props.data?.p5Response}
				responseUnit={props.data?.p5ResponseUnit}
				responseValueName="p5Response"
				responseUnitName="p5ResponseUnit"
				resolutionValue={props.data?.p5Resolution}
				resolutionUnit={props.data?.p5ResolutionUnit}
				resolutionValueName="p5Resolution"
				resolutionUnitName="p5ResolutionUnit"
			/>
		</div>
	);
}

interface PriorityRowProps {
	label: string;
	labelColor: string;
	responseValue?: number;
	responseUnit?: number;
	responseValueName: string;
	responseUnitName: string;
	resolutionValue?: number;
	resolutionUnit?: number;
	resolutionValueName: string;
	resolutionUnitName: string;
	handleChange(key: string, newValue: number | undefined): void;
}

function PriorityRow(props: PriorityRowProps) {
	return (
		<>
			<div className="border-t inline-flex items-center p-2">
				<FaCircle className="text-sm mr-2" style={{ color: props.labelColor }} />{props.label}
			</div>
			<div className="border-t p-1">
				<TimeAmount
					value={props.responseValue ?? 0}
					unit={props.responseUnit}
					valueName={props.responseValueName}
					unitName={props.responseUnitName}
					onChange={props.handleChange}
				/>
			</div>
			<div className="border-t p-1">
				<TimeAmount
					value={props.resolutionValue ?? 0}
					unit={props.resolutionUnit}
					valueName={props.resolutionValueName}
					unitName={props.resolutionUnitName}
					onChange={props.handleChange}
				/>
			</div>
		</>
	);
}
