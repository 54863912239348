import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../Components/Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalContent from "@shared/Components/Modal/ModalContent";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import { BillingCurrency, BillingPaymentTerm, BillingPlan, SalablePlan, getCurrencySymbol, getPlanPrice } from "@shared/Models/BillingObj";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";

interface ChangePlanModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export function ChangePlanModal(props: ChangePlanModalProps) {
	const [isLoading, setIsLoading] = useState(false);

	const { handleSubmit, reset, control, watch } = useForm<SalablePlan>({
		defaultValues: {
			agentCount: 3,
			paymentTerm: BillingPaymentTerm.MONTHLY,
			plan: BillingPlan.BASIC,
			currency: BillingCurrency.GBP
		}
	});

	useEffect(() => {
		if (props.isOpen) {
			fetch();
		}

		async function fetch() {
			// Try to set init values on mount.
			// setIsLoading(true);
			// const billingStatus = await ApiGetBillingStatus();
			// setIsLoading(false);

			// if (billingStatus.successful && billingStatus.data != null) {
			// 	// TODO: Reenable
			// 	// reset(billingStatus.data.salablePlan);
			// } else {
			// 	toast.error("Could not fetch licence.");
			// 	return;
			// }
		}
	}, [reset, props.isOpen]);

	const modalStyle = {
		padding: "4px",
		maxWidth: "350px",
		minWidth: "450px",
		maxHeight: "650px",
		border: "none",
		borderRadius: "10px",
		boxShadow: "0 1px 6px -3px rgba(0,0,0, 0.5)",
	};

	function close() {
		reset();
		props.closeModal();
	}

	async function onSubmit(data: SalablePlan) {
		// Try and get the checkout link.
		// const checkoutRes = await ApiGetCheckoutLink(data);

		// if (checkoutRes.successful && checkoutRes.data != null) {
		// 	const checkoutLink = checkoutRes.data;

		// 	window.location.href = checkoutLink;
		// } else {
		// 	toast.error("Error. Could not get checkout link: " + checkoutRes.errorMsg);
		// 	reset();
		// 	props.closeModal();
		// }
	}

	const currentPlan = watch("plan");
	const currentAgentCount = watch("agentCount");
	const currentCurrency = watch("currency");
	const currentPaymentTerm = watch("paymentTerm");

	const currentCurrencySymbol = getCurrencySymbol(currentCurrency);
	const currentPlanPrice = getPlanPrice(currentPlan, currentCurrency, currentPaymentTerm) ?? 1; // Null coalescence as a workaround.

	const totalPrice = currentPlanPrice * currentAgentCount;

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={close}
			modalStyle={modalStyle}
			title="Edit plan"
		>

			<ModalContent>
				<form onSubmit={handleSubmit(onSubmit)} className="pb-2">
					<div className="text-base">Choose the plan that's right for you.</div>

					<div className="my-6">

						<div className="w-72">
							<W_Dropdown<BillingPlan>
								options={[
									{ label: "Basic", value: BillingPlan.BASIC },
									{ label: "Pro", value: BillingPlan.PRO }
								]}
								control={control}
								dataname="plan"
								mandatory
								label="Plan"
							/>
						</div>

						<div className="w-72">
							<W_TextInput
								control={control}
								dataname="agentCount"
								label="Agents"
								mandatory
								type={InputTypes.Number}
								min={1}
								max={100}
							/>
						</div>

						<div className="w-72">
							<W_Dropdown<BillingPaymentTerm>
								options={[
									{ label: "Monthly", value: BillingPaymentTerm.MONTHLY },
									{ label: "Yearly", value: BillingPaymentTerm.YEARLY },
								]}
								control={control}
								dataname="paymentTerm"
								mandatory
								label="Billing cycle"
								toolTip="20% Discount on yearly subscription"
							/>
						</div>

						<div className="w-72">
							<W_Dropdown<BillingCurrency>
								options={[
									{ label: "USD", value: BillingCurrency.USD },
									{ label: "GBP", value: BillingCurrency.GBP },
									{ label: "EUR", value: BillingCurrency.EUR }
								]}
								control={control}
								dataname="currency"
								mandatory
								label="Currency"
							/>
						</div>
					</div>

					<div className="flex flex-row w-full place-content-between font-semibold border-y border-t-gray-300 border-b-gray-300 py-4">
						<div className="grid grid-cols-4 grid-rows-2 gap-x-3 gap-y-3">
							<div className="text-base">Plan</div>
							<div className="text-base"></div>
							<div className="text-base">Agents</div>
							<div className="text-base"></div>
							<div className="text-base">{currentCurrencySymbol + currentPlanPrice}</div>
							<div className="text-base">×</div>
							<div className="text-base">{currentAgentCount}</div>
							<div className="text-base self-center">=</div>
						</div>
						<div className="self-end text-lg tabular-nums">
							{"Total: " + currentCurrencySymbol + totalPrice}
						</div>
					</div>

					<div className="flex flex-auto justify-end py-2 bottom-0 bg-white">
					</div>
				</form>
			</ModalContent>

			<ModalButtons>
				<Button btnClass="btn-grey"label="Cancel" onClick={close} />
				<Button
					btnClass="btn-blue"
					className="px-4"
					onClick={() => handleSubmit(onSubmit)()}
					label="Confirm & purchase"
					disabled={isLoading}
				/>
			</ModalButtons>
		</Modal>
	);
}
