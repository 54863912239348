import { IconButton, Tooltip } from "@mui/material";
import { RxCross1 } from "react-icons/rx";
import { getGetMyNotificationsQueryKey, useDeleteNotification } from "@/Api/genApi";
import { AnnouncementNotification, AssignedToMeNotification, AssignedToTeamNotification, NewReplyNotification, NewTicketNotification, Notification } from "@/Api/genApi.schemas";
import { NewTicketContent } from "@/Components/Header/NotificationCenter/Notifications/NewTicketContent";
import { queryClient } from "@/Api/UseQueryProvider";
import { NewReplyContent } from "@/Components/Header/NotificationCenter/Notifications/NewReplyContent";
import { AssignedToMeContent } from "@/Components/Header/NotificationCenter/Notifications/AssignedToMeContent";
import { AnnouncementContent } from "@/Components/Header/NotificationCenter/Notifications/AnnouncementContent";
import { AssignedToTeamContent } from "@/Components/Header/NotificationCenter/Notifications/AssignedToTeamContent";

interface NotificationCardProps {
	notification: Notification;
	closeNotifications(): void;
}

export function NotificationCard(props: NotificationCardProps) {
	const deleteNotificationReq = useDeleteNotification({ mutation: {
		onSettled: () => queryClient.invalidateQueries({ queryKey: getGetMyNotificationsQueryKey() }),
	} });

	return (
		<div className="px-3 py-3 flex gap-3 justify-between">
			{getContent(props.notification, props.closeNotifications)}

			<div className="shrink-0">
				<Tooltip title="Clear">
					<IconButton
						onClick={() => deleteNotificationReq.mutate({ id: props.notification.id })}
					>
						<RxCross1 fontSize="1rem" />
					</IconButton>
				</Tooltip>
			</div>
		</div>
	);
}

function getContent(notification: Notification, closeNotifications: () => void) {
	if (notification.type == "NewTicketNotification") {
		return <NewTicketContent notification={notification as NewTicketNotification} closeNotifications={closeNotifications} />;
	}

	if (notification.type == "NewReplyNotification") {
		return <NewReplyContent notification={notification as NewReplyNotification} closeNotifications={closeNotifications} />;
	}

	if (notification.type == "AssignedToMeNotification") {
		return <AssignedToMeContent notification={notification as AssignedToMeNotification} closeNotifications={closeNotifications} />;
	}

	if (notification.type == "AssignedToTeamNotification") {
		return <AssignedToTeamContent notification={notification as AssignedToTeamNotification} closeNotifications={closeNotifications} />;
	}

	if (notification.type == "AnnouncementNotification") {
		return <AnnouncementContent notification={notification as AnnouncementNotification} closeNotifications={closeNotifications} />;
	}
}
