import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTicketsByPriorityReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TicketsByPriorityProps {
	dateRange: [DateTime, DateTime];
}

export function TicketsByPriority(props: TicketsByPriorityProps) {
	const cache = useCache();

	const reportReq = useGetTicketsByPriorityReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getPriority(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Tickets by Priority"
			subtitle="Shows tickets in the reporting period split by priority."
			axisTitles={{ xLabel: "Priority", yLabel: "Tickets" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
