import GenericListPage from "./_GenericListPage";
import { Entities } from "@shared/Entities/Entities";

export default function ManageTeams() {
	return (
		<GenericListPage
			urlTitle="Teams | Config"
			title="Teams"
			description="Teams group your agents to handle tickets more efficiently."
			entityType={Entities.TEAM}
		/>
	);
}
