import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import { Button } from "../../Components/Button/Button";
import { getImageUploadHandler } from "../../Components/FormComponents/HtmlEditor/imageUploadHandler";
import { Tickbox } from "@shared/Components/FormComponents/Tickbox/Tickbox";
import { QuestionTooltip } from "@shared/Components/QuestionTooltip/QuestionTooltip";
import { EmailNotifications } from "@shared/Enums/Enums";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import HtmlEditor from "@shared/Components/FormComponents/HtmlEditor/HtmlEditor";
import { Entities } from "@shared/Entities/Entities";
import { Setting, EmailNotification } from "@shared/Entities/EntityTypes";

interface SettingsEmailNotificationsProps {
	data?: Setting[];
	updateData(settingsData: Setting[]): void;
	refreshData?(): void;
}

/**
 * TODO: This. Component. Sucks.
 * Refactor immediately needed lol. Settings pages + settings code need a rework. Improving useEntity may help too.
 */
export function SettingsEmailNotifications(props: SettingsEmailNotificationsProps) {
	const [data, setData] = useState<Map<string, string | undefined> | undefined>();
	const { handleSubmit } = useForm();
	const navigate = useNavigate();
	const cache = useCache();

	const [ticketReceipt, setTicketReceipt] = useState<EmailNotification>({ id: 1 });
	const ticketReceiptContent = ticketReceipt.content;

	const [oohTicketReceipt, setOohTicketReceipt] = useState<EmailNotification>({ id: 2 });
	const oohTicketReceiptContent = oohTicketReceipt.content;

	useEffect(() => {
		const settingsMap = new Map();

		if (props.data != null) {
			props.data.forEach((setting) => {
				settingsMap.set(setting.key, setting.value);
			});
		}

		setData(settingsMap);
	}, [props.data]);

	// Fetch ticketReceipt and oohTicketResponse on first load.
	useEffect(() => {
		async function fetch() {
			const res1 = await api.getEntity<EmailNotification>(Entities.EMAILNOTIFICATION, EmailNotifications.USER_TICKETRECEIPT);
			const res2 = await api.getEntity<EmailNotification>(Entities.EMAILNOTIFICATION, EmailNotifications.USER_OOHTICKETRESPONSE);

			if (res1.successful && res1.data != null) {
				setTicketReceipt(res1.data);
			} else {
				toast.error("Could not fetch data.");
			}

			if (res2.successful && res2.data != null) {
				setOohTicketReceipt(res2.data);
			} else {
				toast.error("Could not fetch data");
			}
		}

		fetch();
	}, []);

	async function formSubmit() {
		// Update settings.
		if (data != null) {
			const settings: Setting[] = [];

			data.forEach((value, key) => {
				settings.push({ key: key, value: value });
			});

			props.updateData(settings);
			navigate("/config/");
		}

		// Update email notification.
		await ApiUpdateEntity(Entities.EMAILNOTIFICATION, EmailNotifications.USER_TICKETRECEIPT, ticketReceipt);
	}

	function handleChangeBool(key: string, newValue: boolean | undefined) {
		handleChange(key, newValue ? "1" : "0");
	}

	function handleChange(key: string, newValue: string | undefined) {
		const dataCopy = new Map(data ?? []);

		if (newValue != null) {
			dataCopy.set(key, newValue);
		} else {
			dataCopy.set(key, undefined);
		}

		setData(dataCopy);
	}

	function cancelButtonClick() {
		navigate("/config");
	}

	const userTicketReceiptsEnabled = data?.get("emailNotifications.userTicketReceiptEnabled") == "1";
	const oohResponseEnabled = data?.get("emailNotifications.oohResponseEnabled") == "1";

	return (
		props.data != null ?
			<FormParent
				title="Automated Emails"
				description="Control which emails are sent and when."
			>
				<form onSubmit={handleSubmit(formSubmit)}>
					<FormSection>
						<div className="text-xl py-3 flex items-center">
							<div>User Ticket Receipts</div>
							<QuestionTooltip tip={<span>An email will be sent to your users whenever they log a new ticket.</span>} />
						</div>

						<Tickbox valueName="emailNotifications.userTicketReceiptEnabled" onChange={handleChangeBool} value={userTicketReceiptsEnabled} label="Enable" />

						{userTicketReceiptsEnabled &&
							<>
								{/** <TextInput value={ticketReceipt?.subject} dataname="" handleSubmit={(key, value) => {setTicketReceipt(notification => {notification.subject = value; return notification;})}} label="Email Subject" />**/}

								<div className="w-[700px]">
									<HtmlEditor
										value={ticketReceiptContent ?? ""}
										handleChange={(_key, value) => {
											const newTicketReceipt = { ...ticketReceipt };
											newTicketReceipt.content = value;

											setTicketReceipt(newTicketReceipt);
										}}
										dataname=""
										handleCancel={() => {}}
										handleSubmit={() => {}}
										label="Body"
										agentFeatures
										cannedReplies={cache.CannedReplies}
										imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
									/>
								</div>
							</>}

						<br />

						<div className="flex items-center">
							<Button className="" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Update" />
						</div>
					</FormSection>
				</form>

				<form onSubmit={handleSubmit(formSubmit)} style={{ width: "30em" }} >
					<FormSection>
						<div className="text-xl py-3 flex items-center mt-5">
							<div>Out of Hours Responses</div>
							<QuestionTooltip tip={<span>An email will be sent to your users whenever they log a new ticket that is outside of your working hours.</span>} />
						</div>

						<Tickbox valueName="emailNotifications.oohResponseEnabled" onChange={handleChangeBool} value={oohResponseEnabled} label="Enable" />

						{oohResponseEnabled &&
							<div className="w-[700px]">
								<HtmlEditor
									value={oohTicketReceiptContent ?? ""}
									handleChange={(_key, value) => {
										const newOohTicketReceipt = { ...oohTicketReceipt };
										newOohTicketReceipt.content = value;

										setOohTicketReceipt(newOohTicketReceipt);
									}}
									dataname=""
									handleCancel={() => {}}
									handleSubmit={() => {}}
									label="Body"
									agentFeatures
									cannedReplies={cache.CannedReplies}
									imageUploadHandler={getImageUploadHandler(cache.getTenantId())}
								/>
							</div>}

						<br />

						<div className="flex items-center">
							<Button className="" onClick={cancelButtonClick} label="Cancel" />
							<input type="submit" className="ml-2 btn-blue" value="Update" />
						</div>
					</FormSection>
				</form>
			</FormParent>
			:
			null
	);
}
