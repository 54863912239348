import Grid from "@mui/material/Unstable_Grid2";
import { Control, FieldPath, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Agent } from "@/Api/genApi.schemas";
import { getGetMeAsAgentQueryKey, useGetMeAsAgent, useUpdateAgents } from "@/Api/genApi";
import { W_MUI_TickBox } from "@/Components/FormComponents/MuiWrapped/W_MUI_TickBox";
import { queryClient } from "@/Api/UseQueryProvider";
import { SaveBar } from "@/Fragments/EntityViewer/SaveBar";
import { PushSubscriptionManager } from "@/Fragments/EntityViewer/EntitySpecific/Agent/PushSubscriptionManager";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";

export function SettingsNotificationsPage() {
	const getMeReq = useGetMeAsAgent({ query: {
		staleTime: Infinity
	} });

	if (getMeReq.isPending || getMeReq.isError || getMeReq.data.data == null) {
		return;
	}

	return (
		<FormParent
			title="Notification Settings"
			description="Choose which notifications you want to receive."
		>
			<NotificationsForm
				defaultData={getMeReq.data.data}
			/>
		</FormParent>
	);
}

interface NotificationsFormProps {
	defaultData: Agent;
}

function NotificationsForm(props: NotificationsFormProps) {
	const updateAgentReq = useUpdateAgents({ mutation: {
		onSettled: () => queryClient.invalidateQueries({ queryKey: getGetMeAsAgentQueryKey() }),
	} });

	const { handleSubmit, control, formState, reset } = useForm<Agent>({ defaultValues: props.defaultData });

	async function onSubmit(agent: Agent) {
		if (agent.id == null) {
			return;
		}

		await updateAgentReq.mutateAsync({ id: agent.id, data: agent }, {
			onSuccess: data => {
				toast.success("Updated.");

				if (data.data != null) {
					reset(data.data);
				}
			},
			onError: error => toast.error("Could not update preferences: " + error.errorMsg)
		});
	}

	return (
		<>
			<form>
				<FormSection title="Ticket Notifications">
					<Grid container spacing={3}>
						<NotificationHeader />

						<NotificationRow
							description="New ticket created"
							control={control}
							godeskNotificationKey="prefs.notificationNewTickets.godesk"
							emailNotificationKey="prefs.notificationNewTickets.email"
							pushNotificationKey="prefs.notificationNewTickets.push"
						/>

						<NotificationRow
							description="New responses on my tickets"
							control={control}
							godeskNotificationKey="prefs.notificationMyNewReplies.godesk"
							emailNotificationKey="prefs.notificationMyNewReplies.email"
							pushNotificationKey="prefs.notificationMyNewReplies.push"
						/>

						{/* <NotificationRow
							description="Ticket assigned to me"
							control={control}
							godeskNotificationKey="prefs.notificationAssignedToMe.godesk"
							emailNotificationKey="prefs.notificationAssignedToMe.email"
							pushNotificationKey="prefs.notificationAssignedToMe.push"
						/>

						<NotificationRow
							description="Ticket assigned to my team"
							control={control}
							godeskNotificationKey="prefs.notificationAssignedToTeam.godesk"
							emailNotificationKey="prefs.notificationAssignedToTeam.email"
							pushNotificationKey="prefs.notificationAssignedToTeam.push"
						/> */}
					</Grid>
				</FormSection>

				{/* <FormSection title="SLA Notifications">
					<Grid container spacing={3}>
						<NotificationHeader />

						<NotificationRow
							description="First response SLA breach warnings for my tickets"
							control={control}
							godeskNotificationKey="prefs.notificationMineFirstResponse.godesk"
							emailNotificationKey="prefs.notificationMineFirstResponse.email"
							pushNotificationKey="prefs.notificationMineFirstResponse.push"
						/>

						<NotificationRow
							description="Resolution SLA breach warnings for my tickets"
							control={control}
							godeskNotificationKey="prefs.notificationMineResolution.godesk"
							emailNotificationKey="prefs.notificationMineResolution.email"
							pushNotificationKey="prefs.notificationMineResolution.push"
						/>

						<NotificationRow
							description="First response SLA breach warnings for my team's tickets"
							control={control}
							godeskNotificationKey="prefs.notificationTeamFirstResponse.godesk"
							emailNotificationKey="prefs.notificationTeamFirstResponse.email"
							pushNotificationKey="prefs.notificationTeamFirstResponse.push"
						/>

						<NotificationRow
							description="Resolution SLA breach warnings for my team's tickets"
							control={control}
							godeskNotificationKey="prefs.notificationTeamResolution.godesk"
							emailNotificationKey="prefs.notificationTeamResolution.email"
							pushNotificationKey="prefs.notificationTeamResolution.push"
						/>
					</Grid>
				</FormSection> */}

				<FormSection title="Push Notifications">
					<div>
						<PushSubscriptionManager />
					</div>
				</FormSection>
			</form>

			<SaveBar formState={formState} onSave={async () => handleSubmit(onSubmit)()} onCancel={reset} />
		</>
	);
}

function NotificationHeader() {
	return (
		<>
			<Grid xs={6}>
				Notification
			</Grid>
			<Grid xs={2} className="flex justify-center">
				GoDesk
			</Grid>
			<Grid xs={2} className="flex justify-center">
				Email
			</Grid>
			<Grid xs={2} className="flex justify-center">
				Push
			</Grid>
		</>
	);
}

interface NotificationRowProps {
	description: string;
	control: Control<Agent>;
	godeskNotificationKey: FieldPath<Agent>;
	emailNotificationKey: FieldPath<Agent>;
	pushNotificationKey: FieldPath<Agent>;
}

function NotificationRow(props: NotificationRowProps) {

	return (
		<>
			<Grid xs={6} paddingY={1} className="font-medium flex items-center">
				{props.description}
			</Grid>
			<Grid xs={2} paddingY={1} className="flex justify-center">
				<W_MUI_TickBox
					control={props.control}
					dataname={props.godeskNotificationKey}
				/>
			</Grid>
			<Grid xs={2} paddingY={1} className="flex justify-center">
				<W_MUI_TickBox
					control={props.control}
					dataname={props.emailNotificationKey}
				/>
			</Grid>
			<Grid xs={2} paddingY={1} className="flex justify-center">
				<W_MUI_TickBox
					control={props.control}
					dataname={props.pushNotificationKey}
				/>
			</Grid>
		</>
	);
}
