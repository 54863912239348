import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetBusiestTeamReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface BusiestTeamProps {
	dateRange: [DateTime, DateTime];
}

export function BusiestTeam(props: BusiestTeamProps) {
	const cache = useCache();

	const reportReq = useGetBusiestTeamReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getTeam(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Busiest Team"
			subtitle="Shows a teams tickets divided by how many agents a team has."
			axisTitles={{ xLabel: "Team", yLabel: "Workload" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
