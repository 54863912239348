import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { cacheUpdated } from "@/Store/Reducers/cache/cacheSlice";
import { useCache } from "@/Hooks/useCache";
import { ErrorBoundary } from "@/lib/ErrorBoundary";
import api from "@/Api/Api";
import { AppCache } from "@/Store/Reducers/cache/CacheTypes";
import { ParentPageRoutes } from "@/Routes/ParentPageRoutes";
import { BadDomainError } from "@/Routes/BadDomainError";
import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";
import NavHotkeys from "../Components/Utility/Hotkeys/NavHotkeys";
import { NoCacheError } from "./NoCacheError";
import { useAppDispatch } from "../Store/hooks";
import LoadingSpinner from "@shared/Components/LoadingSpinner";
import { SettingString } from "@shared/Enums/SettingEnums";

export default function ParentPage() {
	const dispatch = useAppDispatch();
	const cache = useCache();
	const [cacheFetchFailed, setCacheFetchFailed] = useState(false);
	const [cacheNotFound, setCacheNotFound] = useState(false);

	useEffect(() => {
		getCache();

		async function getCache() {
			const result = await api.BasicGet<AppCache>("/cache", { "new": "true" });

			if (!result.successful || result.data == null) {
				if (result.errorCode == 404) {
					setCacheNotFound(true);
					return;
				}

				setCacheFetchFailed(true);
				toast.error("Error fetching cache: " + result.errorMsg);
			} else {
				dispatch(cacheUpdated(result.data));
			}
		}
	}, [dispatch]);

	useEffect(() => {
		// Prevent default drag & drop action
		window.addEventListener("dragover", function(e) {
			e.preventDefault();
		}, false);
		window.addEventListener("drop", function(e) {
			e.preventDefault();
		}, false);
	}, []);

	const faviconPath = cache.getSettingString(SettingString.HELPDESK_FAVICON_PATH);
	const link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

	if (faviconPath != undefined && link != null) {
		link.href = cache.getPicturesDir() + faviconPath;
	}

	const cacheReady = cache.Agents.length > 0 || cache.Users.length > 0 || cache.Teams.length > 0;

	return (
		<div className="h-screen flex">
			<Sidebar />

			<div className="grow overflow-y-auto flex flex-col">
				<Header />
				<div className="grow overflow-y-auto">
					<ErrorBoundary>
						{cacheFetchFailed ?
							<NoCacheError />
							:
							cacheNotFound ?
								<BadDomainError />
								:
								cacheReady ?
									<ParentPageRoutes />
									:
									<LoadingSpinner />
						}
					</ErrorBoundary>
				</div>
			</div>

			<NavHotkeys />
		</div>
	);
}
