import { useState } from "react";
import { Link } from "react-router-dom";
import { BiTrash } from "react-icons/bi";
import { BsChevronBarUp, BsPencil } from "react-icons/bs";
import { GoNote } from "react-icons/go";
import { HiOutlineMail } from "react-icons/hi";
import Tooltip from "@mui/material/Tooltip";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import { useGetFullActionNote } from "@/Api/genApi";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { TicketNewAction } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketNewAction";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { UserAvatar } from "@/Components/Avatar/UserAvatar";
import { CacheFunctions } from "@/Helpers/CacheFunctions";
import { useAppDispatch } from "@/Store/hooks";
import ActionContextMenu from "@/Fragments/EntityViewer/EntitySpecific/Ticket/Action/ActionContextMenu";
import { fileExtensionIsImage } from "@shared/Helpers/OtherFunctions";
import { Ticket, Action } from "@shared/Entities/EntityTypes";
import { datetimeToLocaleString, datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import { ActionAttachment } from "@shared/Attachments/ActionAttachment";

interface TicketActionProps {
	ticket: Ticket;
	action: Action;
	updatePrivateNote(action: Action): void;
	deletePrivateNote(id: number): void;
}

export function TicketAction(props: TicketActionProps) {
	const cache = useCache();
	const dispatch = useAppDispatch();
	const loggedInAgent = useAgent();

	const [showFullNote, setShowFullNote] = useState<boolean>(false);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [deleteActionModalOpen, setDeleteActionModalOpen] = useState(false);

	const getFullNoteReq = useGetFullActionNote(props.action.id, { query: {
		enabled: showFullNote,
		staleTime: Infinity
	} });

	async function updateAction(action: Action) {
		props.updatePrivateNote(action);
		setEditMode(false);
	}

	const agent = cache.getAgent(props.action.agentId);
	const user = cache.getUser(props.action.userId);

	let actionCreatorString = "";
	let actionCreatorLink = null;
	if (user != null) {
		actionCreatorString = user.name ?? "";
		actionCreatorLink = "/users/" + props.action.userId;
	} else if (agent != null) {
		actionCreatorString = agent.name ?? "";
		if (!agent.disabled) {
			actionCreatorLink = "/config/manage/agents/" + props.action.agentId;
		}
	} else if (props.action.userId != null) {
		// Temp workaround for if a user is created after we got our cache so the getUser() is a miss. Tries to refetch cache.
		CacheFunctions.cacheRefreshForUser(props.action.userId, dispatch);
		actionCreatorString = "";
	} else {
		actionCreatorString = "CREATOR UNKNOWN";
	}

	const deleted = user?.deleted || agent?.deleted ? true : false;

	let note = null;
	let noteExpanded = null;

	if (props.action.noteBody != null && props.action.noteBody != "") {
		note = <div dangerouslySetInnerHTML={{ __html: props.action.noteBody }}></div>;
	}

	if (showFullNote && getFullNoteReq.data != null) {
		noteExpanded = <div dangerouslySetInnerHTML={{ __html: getFullNoteReq.data }}></div>;
	}

	const isSystemAction = props.action.agentId == null && props.action.userId == null;

	let actionBoxStyles = "";
	let actionBoxColor = undefined;

	if (props.action.agentId != null) {
		actionBoxStyles = "rounded bg-gray-100";

		if (!props.action.isPublic) {
			actionBoxColor = "#FFF7ED";
		}
	}

	let actionIcon = null;
	let actionTitle = undefined;
	switch (props.action.isPublic) {
		case true: actionIcon = <HiOutlineMail />; actionTitle = "Email"; break;
		case false: actionIcon = <GoNote />; actionTitle = "Private Note"; break;
	}

	// Enable attachment previews if there is an image attachment.
	const showAttachmentPreviews = props.action.attachments?.some(attachment => {
		return fileExtensionIsImage(attachment.fileNameOriginal != undefined ? attachment.fileNameOriginal : "");
	});

	if (isSystemAction) {
		return null;
	}

	return (
		<div className={"mt-2 p-3 " + actionBoxStyles} style={{ backgroundColor: actionBoxColor }}>
			<div className="flex">
				<div className="mr-2">
					{props.action.userId != null ?
						<UserAvatar user={user} widthPx={35} />
						:
						<AgentAvatar agent={agent} widthPx={35} />}
				</div>
				<div className="flex-grow">
					<div className="font-semibold text-gray-700" style={{ width: "fit-content" }}>
						{actionCreatorLink != null && !deleted ?
							<Link to={actionCreatorLink} className="blue-link">
								{actionCreatorString}
							</Link>
							:
							actionCreatorString}
					</div>
					<div>
						<div className="text-gray-500">
							<span className="mr-1" title={actionTitle}>{actionIcon}</span>
							<span className="italic" title={datetimeToLocaleString(props.action.customCreated)}>{datetimeToWhenString(props.action.customCreated)}</span>
						</div>
					</div>
				</div>
				<div>
					{!props.action.isPublic ?
						<>
							<Tooltip title="Edit" placement="bottom">
								<button onClick={() => setEditMode(!editMode)} className="border border-gray-300 bg-white rounded p-1 w-7 h-7 mr-1"><BsPencil /></button>
							</Tooltip>

							{props.action.agentId == loggedInAgent?.id || loggedInAgent?.isAdmin ?
								<Tooltip title="Delete" placement="bottom">
									<button onClick={() => setDeleteActionModalOpen(true)} className="border border-gray-300 bg-white rounded p-1 w-7 h-7 mr-1"><BiTrash /></button>
								</Tooltip>
								: null}
						</>
						:
						<ActionContextMenu ticket={props.ticket} action={props.action} />}
				</div>
			</div>
			<div className="ml-11 mt-2 htmlNote">
				{editMode ?
					<TicketNewAction
						defaultAction={props.action}
						clearActionPassback={() => {setEditMode(false);}}
						addActionPassback={updateAction}
						ticket={{ id: -1 }}
						isPublic={false}
					/>
					:
					<>
						{showFullNote && getFullNoteReq.data != null ? noteExpanded : note}

						{props.action.hasExpandedNote &&
							<Tooltip title={!showFullNote ? "Show full message" : "Hide full message"}>
								<button
									onClick={() => setShowFullNote(!showFullNote)}
									disabled={getFullNoteReq.isFetching}
									className="btn-grey"
								>
									{showFullNote ? <BsChevronBarUp /> : "..."}
								</button>
							</Tooltip>}
					</>
				}
				<div className="flex flex-wrap gap-3 mt-2 ml-[-0.25rem]">
					{props.action.attachments?.map(attachment => (
						<ActionAttachment
							key={attachment.id}
							attachmentsUrl={cache.getAttachmentsDir()}
							attachment={attachment}
							showPreview={showAttachmentPreviews}
						/>
					))}
				</div>
			</div>

			<DeleteWarningModal
				isOpen={deleteActionModalOpen}
				onDelete={() => props.deletePrivateNote(props.action.id)}
				onCancel={() => setDeleteActionModalOpen(false)}
				message={"Are you sure you want to permanently delete this private note?"}
			/>
		</div>
	);
}
