import { AiFillMinusCircle } from "react-icons/ai";
import { motion } from "framer-motion";
import { useCache } from "@/Hooks/useCache";
import { Ticket } from "@shared/Entities/EntityTypes";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface TicketListProps {
	ticketList: Ticket[];
	setTicketList(data: Ticket[]): void;
}

export default function TicketList(props: TicketListProps) {
	const cache = useCache();

	function removeTicket(id: number) {
		const tickets = props.ticketList.filter(
			(ticket: Ticket) => ticket.id != id
		);

		props.setTicketList(tickets ? tickets : []);
	}

	function swapElement(array: any[], indexA: number, indexB: number) {
		const copyArray = [...array];
		const tmp = copyArray[indexA];
		copyArray[indexA] = copyArray[indexB];
		copyArray[indexB] = tmp;
		return copyArray;
	}

	function onPrimaryTicketChange(id: number) {
		const arrangedTickets = swapElement(props.ticketList, id, 0);
		props.setTicketList(arrangedTickets);
	}

	const TicketCards = props.ticketList.map((value, index) => {
		const agent = cache.getAgent(value.agentId);

		return (
			<motion.li
				transition={{
					type: "spring",
					stiffness: 250,
					damping: 30,
				}}
				layout
				className="flex"
				style={{ background: "white" }}
				key={value.id}
			>
				<div
					className="flex border border-gray-300 p-3 rounded-md overflow-hidden "
					style={{ width: "100%" }}
				>
					<div className="mr-4 flex">
						<label className="flex flex-col justify-center my-auto cursor-pointer">
							<input
								type="radio"
								value={value.id}
								name="primary"
								defaultChecked={index == 0}
								onChange={() => onPrimaryTicketChange(index)}
								className="cursor-pointer"
							/>
							{"Primary"}
						</label>
					</div>
					<div
						className="flex flex-col gap-1 overflow-hidden pr-2"
						style={{
							minWidth: "100px",
							textOverflow: "ellipsis",
							maxWidth: "200px",
						}}
					>
						<div className="flex gap-1">
							<div className="text-gray-500">#{value.id}</div>
							<div
								className=""
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{value.description}
							</div>
						</div>
						{agent?.name != "" && (
							<div className="flex gap-1">
								<div className="text-gray-500">Agent:</div>
								<div
									className=""
									style={{
										whiteSpace: "nowrap",
										overflow: "hidden",
										textOverflow: "ellipsis",
									}}
								>
									{agent?.name}
								</div>
							</div>
						)}
						<div className="flex gap-1">
							<div className="text-gray-500">Status:</div>
							<div
								className=""
								style={{
									whiteSpace: "nowrap",
									overflow: "hidden",
									textOverflow: "ellipsis",
								}}
							>
								{value.statusId == 1
									? "Open"
									: value.statusId == 2
										? "With User"
										: "Closed"}
							</div>
						</div>
					</div>
					<div style={{ marginLeft: "auto" }}>
						<div
							className="text-gray-500"
							style={{ display: "flex", width: "100%", whiteSpace: "nowrap" }}
						>
							<div className="hidden sm:block">Created:&nbsp;</div>
							{datetimeToWhenString(value.customCreated)}
						</div>
					</div>
				</div>
				{index != 0 && (
					<div className="flex flex-col justify-center px-5 ">
						<div className="flex cursor-pointer merge-remove">
							{
								<AiFillMinusCircle
									className="h-7 w-7 rounded-md "
									title="remove"
									onClick={() => removeTicket(value.id)}
								/>
							}
						</div>
					</div>
				)}
			</motion.li>
		);
	});

	return (
		<motion.ul
			style={{
				borderRadius: "6px",
				overflowY: "auto",
				maxHeight: "100%",
				height: "100%",
				width: "100%",
				// boxShadow:
				// "inset 0  8px 8px -8px rgba(0,0,0,.75), inset 0 -8px 8px -8px rgba(0,0,0,.75)",
			}}
			className="flex flex-col gap-3 px-3"
			layoutScroll
		>
			{TicketCards}
		</motion.ul>
	);
}
