import { ActionSelector } from "@/Fragments/EntityViewer/EntitySpecific/AutomationViewerComponents/ActionSelector";
import { Button } from "../../../../Components/Button/Button";
import { AutomationAction } from "@shared/Entities/EntityTypes";

interface ActionManagerProps {
	data: AutomationAction[];
	updateData(data: AutomationAction[]): void;
}

export default function ActionManager(props: ActionManagerProps) {
	function addAction() {
		const dataCopy = [...props.data];
		let minId = -1; // New Id's are random minus numbers. Later set to undefined in AutomationViewer.formSubmit().

		dataCopy.forEach(action => {
			if (action.id != null && action.id <= minId) {
				minId = action.id - 1;
			}
		});

		dataCopy.push({ id: minId });
		props.updateData(dataCopy);
	}

	function updateAction(data: AutomationAction) {
		const dataCopy = [...props.data];

		const index = dataCopy.findIndex(action => action.id != null && action.id == data.id);
		if (index > -1) {
			dataCopy[index] = data;
		}

		props.updateData(dataCopy);
	}

	function deleteAction(data: AutomationAction) {
		const dataCopy = [...props.data];

		const index = dataCopy.findIndex(action => action.id != null && action.id == data.id);
		if (index > -1) {
			dataCopy.splice(index, 1);
		}

		props.updateData(dataCopy);
	}

	return (
		<>
			{props.data.map((action, index, array) => (
				<>
					<ActionSelector data={action} deletable={props.data.length != null && props.data.length > 1} updateData={updateAction} delete={deleteAction} />
					{index + 1 < array.length ? <>And:</> : null}
				</>
			))}
			<br />
			<Button className="" onClick={addAction} label="Add Action" />
		</>
	);
}
