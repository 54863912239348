import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { globalTabReplaced, globalTabUpdated } from "@/Store/Reducers/globalTabsSlice";
import { useAppDispatch } from "@/Store/hooks";
import { CacheFunctions } from "@/Helpers/CacheFunctions";
import { FieldError } from "@/legacy/ApiResponse";
import { ApiDeleteEntity, RefreshCache } from "@/legacy/ApiCallerOld";
import { GlobalTab, HeaderTabEntities } from "@/Components/Header/GlobalTab";
import api from "@/Api/Api";
import { EmailSecurity } from "@shared/Enums/Enums";
import { Entities } from "@shared/Entities/Entities";

/**
 * This hook is for entity viewers to interact with the API.
 * Its functions may have side effects which depend on which entity you are working with.
 * It also handles changing the document title.
 *
 * TODO: this could contain a useForm. Could expose methods. Then I could remove the ID watch and error setting effects from the entityviewers.
 */
export function useEntity<T>(id: number | undefined, entityType: Entities) {
	/** Flag to avoid double gets. See getEntity(). */
	const [lastCreatedEntity, setLastCreatedEntity] = useState<{ id: number; entityType: Entities } | undefined>();

	const [data, setData] = useState<T>();
	const [formError, setFormError] = useState<string | undefined>();
	const [fieldErrors, setFieldErrors] = useState<FieldError[] | undefined>();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const params: any = useParams();

	// Get data when ID changes.
	useEffect(() => {
		console.info("useEntity ID changed: " + id + ". lastCreatedEntity: " + lastCreatedEntity);

		if (id == null) {
			return;
		} else if (id > -1) {
			getEntity();
		} else if (id == -1) {
			setData(getDefaultNewEntity(entityType));
		}
	}, [id, entityType]);

	// Set document.title according to the dependencies.
	useEffect(() => {
		const titlePath = CacheFunctions.getEntityTitlePath(entityType);

		if (params.id == null) {
			document.title = titlePath;
		} else if (params.id == -1) {
			document.title = "New | " + titlePath;
		} else if (data != null) {
			switch (entityType) {
				case Entities.AGENT: document.title = (data as any).name + " | " + titlePath; break;
				case Entities.TICKET: document.title = (data as any).description + " | " + titlePath; break;
				case Entities.KBARTICLE: document.title = "KB | GoDesk"; break;
				default: document.title = (data as any).name + " | " + titlePath; break;
			}
		}
	}, [data, params, entityType]);

	/**
	 * This uses a lastCreated flag to check it is not being called immediately following a createEntity call. e.g:
	 * createEntity() -> redirect to new url -> new ID passed to useEntity -> getEntity().
	 * This may cause bugs in the future!
	 */
	async function getEntity(): Promise<void> {
		console.info("useEntity getEntity(). lastCreatedEntity: " + lastCreatedEntity);

		if (id == null) {
			return;
		}

		if (lastCreatedEntity != null) {
			if (lastCreatedEntity.id == id && lastCreatedEntity.entityType == entityType) {
				// This has been called immediately following this entity's createEntity() call and redirect. Just return.
				return;
			}

			setLastCreatedEntity(undefined);
			console.info("useEntity getEntity() called after create. Not doing GET.");
		}

		const response = await api.getEntity<T>(entityType, id);

		if (response != null && response.data != null) {
			// Set local data
			setData(response.data);

			const fetchedObj: any = response.data;

			// TODO: Maybe this should be moved to the users of useEntity.
			// Update global tab
			if (entityType == Entities.TICKET) {
				dispatch(globalTabUpdated({ id: id, title: fetchedObj.description, entity: HeaderTabEntities.TICKETS }));
			} else if (entityType == Entities.USER) {
				dispatch(globalTabUpdated({ id: id, title: fetchedObj.name, entity: HeaderTabEntities.USERS }));
			}
		}
	}

	/**
	 * @returns The ID of the created entity, or undefined if it could not be created.
	 */
	async function createEntity(data: T): Promise<number | undefined> {
		console.info("useEntity createEntity()");
		const response = await api.createEntity(entityType, data as any);
		let newId = undefined;

		if (response.data != null && response.data != null) {
			const createdObj: any = response.data;
			setData(createdObj);
			newId = createdObj.id;
			setFormError(undefined);
			setFieldErrors(undefined);
			setLastCreatedEntity({ id: newId, entityType: entityType });

			// TODO: Move navigate() and new tab stuff to the users of useEntity.

			// Change URL and add a tab if needed.
			if (entityType == Entities.TICKET) {
				dispatch(globalTabReplaced({
					currentTab: { id: -1, entity: HeaderTabEntities.TICKETS },
					replaceTab: { id: createdObj.id, title: createdObj.description, entity: HeaderTabEntities.TICKETS }
				}));
				navigate(getNewUrlAfterTabCreation({ id: createdObj.id, title: createdObj.description, entity: HeaderTabEntities.TICKETS }));
			} else if (entityType == Entities.USER) {
				dispatch(globalTabReplaced({
					currentTab: { id: -1, entity: HeaderTabEntities.USERS },
					replaceTab: { id: createdObj.id, title: createdObj.name, entity: HeaderTabEntities.USERS }
				}));
				navigate(getNewUrlAfterTabCreation({ id: createdObj.id, title: createdObj.name, entity: HeaderTabEntities.USERS }));
			} else if (entityType == Entities.AGENT) {
				// navigate("/config/manage/agents/" + createdObj.id);
			} else if (entityType == Entities.CANNEDREPLY) {
				navigate("/config/manage/cannedreplies/" + createdObj.id);
			} else if (entityType == Entities.SLA) {
				navigate("/config/manage/slas/" + createdObj.id);
			} else if (entityType == Entities.VIEW) {
				navigate("/config/manage/views/" + createdObj.id);
			}

			// Refresh the cache.
			if (entityType != Entities.TICKET) {
				await RefreshCache(dispatch);
			}
		} else {
			setFormError(response.errorMsg);
			setFieldErrors(response.fieldErrors);
		}

		return newId;
	}

	function getNewUrlAfterTabCreation(newTab: GlobalTab): string {
		if (newTab != null) {
			switch (newTab.entity) {
				case HeaderTabEntities.TICKETS:
					return "/tickets/" + newTab.id;
				case HeaderTabEntities.USERS:
					return "/users/" + newTab.id;
			}
		}

		return "/home";
	}

	/**
	 * @returns True on success, false on failure.
	 */
	async function updateEntity(data: T): Promise<boolean> {
		console.info("useEntity updateEntity()");
		if (id != null && id > -1) {
			const response = await api.updateEntity(entityType, id, data as any);

			if (response.data != null) {
				setData(response.data);
				setFormError(undefined);
				setFieldErrors(undefined);

				// Refresh the cache.
				if (entityType != Entities.TICKET) {
					await RefreshCache(dispatch);
				}

				return true;
			} else {
				setFormError(response.errorMsg);
				setFieldErrors(response.fieldErrors);
				return false;
			}
		}

		return false;
	}

	/**
	 * @returns True on success, false on failure.
	 */
	async function deleteEntity(): Promise<boolean> {
		console.info("useEntity deleteEntity()");
		if (id != null && id != -1) {
			const deleteRes = await ApiDeleteEntity(entityType, id);

			if (!deleteRes.successful) {
				toast.error("Could not delete. " + deleteRes.errorCode + ": " + deleteRes.errorMsg);
				return false;
			} else {
				toast.success("Deleted.");

				// Refresh the cache.
				if (entityType != Entities.TICKET) {
					await RefreshCache(dispatch);
				}

				return true;
			}
		}

		return false;
	}

	return {
		data: data,
		formError: formError,
		fieldErrors: fieldErrors,
		getEntity: getEntity,
		createEntity: createEntity,
		updateEntity: updateEntity,
		deleteEntity: deleteEntity
	};
}

function getDefaultNewEntity(entityType: Entities): any {
	switch (entityType) {
		case Entities.CHANNELSEMAIL:
			return { id: -1, isGodeskManaged: true, imapPort: 993, imapSecurity: EmailSecurity.STARTTLS, smtpPort: 587, smtpSecurity: EmailSecurity.STARTTLS };
		case Entities.SLA:
			return { id: -1, p1Resolution: 4, p1Response: 15, p1ResolutionUnit: 60, p1ResponseUnit: 1, p2Resolution: 1, p2Response: 1, p2ResolutionUnit: 1440, p2ResponseUnit: 60, p3Resolution: 2, p3Response: 2, p3ResolutionUnit: 1440, p3ResponseUnit: 60, p4Resolution: 4, p4Response: 4, p4ResolutionUnit: 1440, p4ResponseUnit: 60, p5Resolution: 0, p5Response: 0, p5ResolutionUnit: 60, p5ResponseUnit: 60 };
		case Entities.KBARTICLE:
			return { id: -1, name: null, kbSectionId: null, body: null };
		case Entities.TEAM:
			return { id: -1, autoAssignMethod: null };
		default:
			return { id: -1 };
	}
}
