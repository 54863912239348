import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { DraggableResizableModal } from "@/Components/Utility/Modals/DraggableResizableModal/DraggableResizableModal";
import api from "@/Api/Api";
import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { KbArticle, Search, kbArticleContent } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";
import { getKbArticleSlug } from "@shared/Helpers/OtherFunctions";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface KbBrowserModalProps {
	isOpen: boolean;
	onClose(): void;
	onLinkInsert(text: string, link: string): void;
}

export function KbBrowserModal(props: KbBrowserModalProps) {
	const [kbArticles, setKbArticles] = useState<KbArticle[]>();
	const [kbArticleToDisplay, setKbArticleToDisplay] = useState<KbArticle>();
	const [searchTerm, setSearchTerm] = useState<string>();
	const [searchResults, setSearchResults] = useState<KbArticle[]>();

	useEffect(() => {
		async function getArticles() {
			const res = await api.getEntities<KbArticle>(Entities.KBARTICLE, 5, 0,
				[{ colId: "viewsLast30Days", sort: "desc" },
					{ colId: "views", sort: "desc" }],
				{ published: { type: "bool", comparison: "equals", value: true } }
			);

			if (res.successful && res.data != null) {
				setKbArticles(res.data);
			}
		}

		getArticles();
	}, []);

	useEffect(() => {
		async function searchArticles() {
			if (searchTerm == null || searchTerm == "") {
				setSearchResults(undefined);
				return;
			}

			const res = await api.BasicGet<Search>("/search", {
				"entity": "kbarticle",
				"query": searchTerm,
				"limit": 10,
				"offset": 0
			});

			if (res.successful && res.data != null && res.data.articles != null) {
				setSearchResults(res.data.articles);
			}
		}

		searchArticles();
	}, [searchTerm]);

	return (
		<DraggableResizableModal
			isOpen={props.isOpen}
			onClose={props.onClose}
			title="Knowledge Base"
			initialPositionPx={{ x: window.innerWidth - 30 - 430, y: 30 }}
			initialSizePx={{
				width: 430,
				height: 580,
			}}
		>
			<div className="p-4">
				{kbArticleToDisplay ?
					<KbArticleViewer
						kbArticle={kbArticleToDisplay}
						setKbArticleViewerPage={() => setKbArticleToDisplay(undefined)}
						onLinkInsert={props.onLinkInsert}
					/>
					:
					<>
						<div className="">
							<TextInput
								dataname=""
								onChangeDebounced={(_key, value) => setSearchTerm(value)}
								autoFocus
								clearable
								label="Search the knowledge base"
							/>
						</div>

						{searchResults != null ?
							<div className="overflow-y-auto mt-7 mb-4">
								Search results

								{searchResults?.map(article =>
									<TopKbArticle
										key={article.id}
										kbArticle={article}
										onClick={() => setKbArticleToDisplay(article)}
									/>
								)}
							</div>
							:
							<div className="overflow-y-auto mt-7 mb-4">
								Top articles

								{kbArticles?.map(article =>
									<TopKbArticle
										key={article.id}
										kbArticle={article}
										onClick={() => setKbArticleToDisplay(article)}
									/>
								)}
							</div>}
					</>
				}
			</div>
		</DraggableResizableModal>
	);
}

interface TopKbArticleProps {
	kbArticle: KbArticle;
	onClick(): void;
}

function TopKbArticle(props: TopKbArticleProps) {
	const articleName = props.kbArticle.articleContent?.find(articleContent => articleContent.id.language == "en_GB")?.title;

	return (
		<div
			onClick={props.onClick}
			className="rounded-lg shadow hover:shadow-md border flex flex-col cursor-pointer justify-between h-full bg-white border-gray-200 py-1 px-4 sm:p-4 my-2"
		>
			<div className="mb-6 text-base line-clamp-2 ">
				{articleName}
			</div>
			<div className="text-sm text-gray-500">
				{props.kbArticle.viewsLast30Days + " views in the last 30 days"}
			</div>
		</div>
	);
}

interface KbArticleViewerProps {
	kbArticle: KbArticle;
	setKbArticleViewerPage(): void;
	onLinkInsert(text: string, link: string): void;
}

function KbArticleViewer(props: KbArticleViewerProps) {
	const [articleContent, setArticleContent] = useState<kbArticleContent>();

	const articleName = props.kbArticle.articleContent?.find(articleContent => articleContent.id.language == "en_GB")?.title;
	const dateString = datetimeToWhenString(props.kbArticle.modified);

	const cache = useCache();

	const kbArticleLink = cache.getPortalURL() + "knowledge-base/" + props.kbArticle.id + "/" + getKbArticleSlug(props.kbArticle);

	useEffect(() => {
		async function getArticleContent() {
			const res = await api.BasicGet<kbArticleContent>("/kb_article_content/" + props.kbArticle.id + "/en_GB");

			if (res.successful && res.data != null) {
				setArticleContent(res.data);
			}
		}

		getArticleContent();
	}, [props.kbArticle.id]);

	return (
		<div className="overflow-y-auto">
			<div className="border-b border-gray-300 ">
				<div>
					<div className="border-b border-gray-300 pb-4 flex justify-between">
						<div className="self-center text-center" onClick={props.setKbArticleViewerPage}>
							<Button
								btnClass="btn-grey"
								icon={<IoIosArrowBack />}
								label="Go back"
							/>
						</div>
						<Button
							btnClass="btn-grey"
							onClick={() => {
								props.onLinkInsert(articleName ?? "Article", kbArticleLink);
							}}
							icon={<HiOutlineClipboardCopy />}
							label="Insert article link"
						/>
					</div>
					<div className="text-2xl pt-4">
						{articleName}
					</div>

					<div className="pb-4 pt-2">
						Created by: {props.kbArticle.creatorName}
						<br />
						Last edited: {dateString}
					</div>
				</div>

			</div>
			{articleContent ?
				<div className="py-4 htmlNote overflow-y-auto" dangerouslySetInnerHTML={{ __html: articleContent.body }}></div>
				:
				<div>Could not find the article content</div>
			}
		</div>
	);
}
