import { useCreateLayouts } from "@/Api/genApi";
import { Layout } from "@/Api/genApi.schemas";
import { LayoutModal } from "@/Components/Layouts/LayoutModal/LayoutModal";
import { layoutApplied } from "@/Store/Reducers/ticketsPageSlice";
import { useAppDispatch } from "@/Store/hooks";
import { RefreshCache } from "@/legacy/ApiCallerOld";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import Modal from "@shared/Components/Modal/Modal";
import { Entities } from "@shared/Entities/Entities";

interface LayoutModalCreateProps {
	close(): void;
}

export function LayoutModalCreate(props: LayoutModalCreateProps) {
	const createLayout = useCreateLayouts();
	const dispatch = useAppDispatch();

	function onSubmit(layout: Layout) {
		createLayout.mutate(
			{ data: layout },
			{
				onSuccess: async (createdLayout) => {
					await RefreshCache(dispatch);
					props.close();

					// Activate the created layout.
					dispatch(layoutApplied(createdLayout));
				}
			}
		);
	}

	if (createLayout.error != null) {
		return (
			<Modal isOpen={true} onClose={props.close} title="Layout" >
				<AlertBox alertType={"error"}>
					Could not edit layout: {createLayout.error.errorMsg}
				</AlertBox>
			</Modal>
		);
	}

	return (
		<LayoutModal
			entity={Entities.TICKET}
			close={props.close}
			onSubmit={onSubmit}
			isSubmitting={createLayout.isPending}
			error={createLayout.error}
		/>
	);
}
