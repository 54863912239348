import { useState } from "react";
import toast from "react-hot-toast";
import { ApiGoogleGetOauthCode } from "@/legacy/ApiCallerOld";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";

export interface OauthButtonProps {
	channel: ChannelsEmail;
	/** Calls and awaits this BEFORE sending the user off on the auth flow. Should return the channel as returned by the API */
	prefireCallback?(): Promise<ChannelsEmail | undefined>;
}

export function GmailOauthButton(props: OauthButtonProps) {
	const [awaitingCode, setAwaitingCode] = useState(false);

	async function onClick() {
		if (awaitingCode) {
			return;
		}

		const channel = props.prefireCallback != null ? await props.prefireCallback() : props.channel;

		if (channel == null) {
			return;
		}

		setAwaitingCode(true);

		// Send request for our state code to the BE.
		const res = await ApiGoogleGetOauthCode(channel);

		setAwaitingCode(false);

		if (!res.successful || res.data == undefined || res.data.length < 1) {
			toast.error("Error. " + res.errorCode + ": " + res.errorMsg);
			return;
		}

		const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";

		let redirectUri: string;

		if (process.env.NODE_ENV == "development") {
			// TODO: Get this working for Gmail dev env.
			redirectUri = "http://localhost:8080/oauth"; // Dev env.
		} else if (window.location.hostname == "support.getgodesk.com" || window.location.hostname == "emailtest.getgodesk.com") {
			redirectUri = "https://oauth-stag.getgodesk.com"; // Staging.
		} else {
			redirectUri = "https://oauth.getgodesk.com"; // Production.
		}

		const searchParams = new URLSearchParams({
			client_id: "955862405053-moarhh7ivfvr3cmajd8o153b9uv8dokf.apps.googleusercontent.com",
			redirect_uri: redirectUri,
			scope: "https://www.googleapis.com/auth/gmail.send",
			response_type: "code",
			access_type: "offline",
			prompt: "consent",
			state: res.data[0]
		});

		if (channel.address != null) {
			searchParams.append("login_hint", channel.address);
		}

		const authUrl = googleAuthUrl + "?" + searchParams.toString();

		window.location.href = authUrl;
	}

	return (
		<img
			title="Activate Gmail"
			alt="Activate Gmail"
			className="cursor-pointer w-[191px]"
			onClick={onClick}
			src="/google-signin-light.png"
		/>
	);
}
