/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * GoDesk API
 * Beta release of the API documentation. All endpoints and parameters are subject to change.
 * OpenAPI spec version: 0.1
 */
export type GetAllViewsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type UpdateUsersBody = User | Agent;

export type CreateUsersBody = User | Agent;

export type GetAllUsersParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllTicketsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllTeamsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllSLAsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetSettingsFromKeysParams = {
/**
 * A comma separated list of the settings you want.
 */
	keys: string;
};

export type GetCnameChangeStatus200 = typeof GetCnameChangeStatus200[keyof typeof GetCnameChangeStatus200];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCnameChangeStatus200 = {
	NONE: "NONE",
	ADDING: "ADDING",
	ADD_COMPLETED: "ADD_COMPLETED",
	ADD_FAILED: "ADD_FAILED",
	REMOVING: "REMOVING",
	REMOVE_COMPLETED: "REMOVE_COMPLETED",
	REMOVE_FAILED: "REMOVE_FAILED",
} as const;

export type ActivateCnameParams = {
	domain: string;
};

export type GetAllSettingsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type KbPublicSearchParams = {
	reqParams: { [key: string]: string };
};

export type GlobalSearchParams = {
	query: string;
};

export type EntitySearchParams = {
	query: string;
	entity: string;
	offset?: number;
	limit?: number;
};

export type GetTotalTicketRepliesReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetTicketsPerUserReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetTicketsPerCompanyReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetTicketsByStatusReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetTicketsByPriorityReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetTicketsByCategoryReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetMissedSearchResultsReport200 = { [key: string]: number };

export type GetMissedSearchResultsReportParams = {
	dateFrom: string;
	dateTo: string;
	kbId: number;
};

export type GetDashboardReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type GetBusiestTeamReportParams = {
	dateFrom: string;
	dateTo: string;
};

export type RedeemPasswordResetTokenParams = {
	token: string;
};

export type CreatePasswordResetTokenParams = {
	email: string;
};

export type CheckTokenIsValidParams = {
	token: string;
};

export type GetMyNotifications200Item = AnnouncementNotification | AssignedToMeNotification | AssignedToTeamNotification | NewReplyNotification | NewTicketNotification;

export type GetAllLayoutsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllKBSectionsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type VoteParams = {
	isUpvote?: boolean;
};

export type GetAllKBArticlesParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllKnowledgeBasesParams = {
	offset: number;
	limit: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllEmailNotificationsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllCompanyNotesParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllCompaniesParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllEmailChannelsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllCategoriesParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllCannedRepliesParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllAutomationsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type UploadMultiAttachmentParams = {
	file: Blob[];
};

export type GetAllAttachmentsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type GetAllAgentsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type RevokeAgentInviteParams = {
	email: string;
};

export type RemoveAllCollisionNotesParams = {
	agentId?: number;
};

export type GetAllActionsParams = {
	offset?: number;
	limit?: number;
	sort?: SortModel[];
	filter?: { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };
};

export type WorkdayTimeZone = {
	id?: string;
	rules?: WorkdayTimeZoneRules;
};

export interface Workday {
	timeZone?: WorkdayTimeZone;
}

export type WorkdayTimeZoneRulesTransitionsItemOffsetBefore = {
	id?: string;
	totalSeconds?: number;
};

export type WorkdayTimeZoneRulesTransitionsItemOffsetAfter = {
	id?: string;
	totalSeconds?: number;
};

export type WorkdayTimeZoneRulesTransitionsItem = {
	dateTimeAfter?: string;
	dateTimeBefore?: string;
	duration?: WorkdayTimeZoneRulesTransitionsItemDuration;
	gap?: boolean;
	instant?: string;
	offsetAfter?: WorkdayTimeZoneRulesTransitionsItemOffsetAfter;
	offsetBefore?: WorkdayTimeZoneRulesTransitionsItemOffsetBefore;
	overlap?: boolean;
};

export type WorkdayTimeZoneRules = {
	fixedOffset?: boolean;
	transitionRules?: WorkdayTimeZoneRulesTransitionRulesItem[];
	transitions?: WorkdayTimeZoneRulesTransitionsItem[];
};

export type WorkdayTimeZoneRulesTransitionsItemDurationUnitsItem = {
	dateBased?: boolean;
	durationEstimated?: boolean;
	timeBased?: boolean;
};

export type WorkdayTimeZoneRulesTransitionsItemDuration = {
	nano?: number;
	negative?: boolean;
	seconds?: number;
	units?: WorkdayTimeZoneRulesTransitionsItemDurationUnitsItem[];
	zero?: boolean;
};

export type WorkdayTimeZoneRulesTransitionRulesItemTimeDefinition = typeof WorkdayTimeZoneRulesTransitionRulesItemTimeDefinition[keyof typeof WorkdayTimeZoneRulesTransitionRulesItemTimeDefinition];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkdayTimeZoneRulesTransitionRulesItemTimeDefinition = {
	UTC: "UTC",
	WALL: "WALL",
	STANDARD: "STANDARD",
} as const;

export type WorkdayTimeZoneRulesTransitionRulesItemStandardOffset = {
	id?: string;
	totalSeconds?: number;
};

export type WorkdayTimeZoneRulesTransitionRulesItemOffsetBefore = {
	id?: string;
	totalSeconds?: number;
};

export type WorkdayTimeZoneRulesTransitionRulesItemOffsetAfter = {
	id?: string;
	totalSeconds?: number;
};

export type WorkdayTimeZoneRulesTransitionRulesItemMonth = typeof WorkdayTimeZoneRulesTransitionRulesItemMonth[keyof typeof WorkdayTimeZoneRulesTransitionRulesItemMonth];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkdayTimeZoneRulesTransitionRulesItemMonth = {
	JANUARY: "JANUARY",
	FEBRUARY: "FEBRUARY",
	MARCH: "MARCH",
	APRIL: "APRIL",
	MAY: "MAY",
	JUNE: "JUNE",
	JULY: "JULY",
	AUGUST: "AUGUST",
	SEPTEMBER: "SEPTEMBER",
	OCTOBER: "OCTOBER",
	NOVEMBER: "NOVEMBER",
	DECEMBER: "DECEMBER",
} as const;

export type WorkdayTimeZoneRulesTransitionRulesItemDayOfWeek = typeof WorkdayTimeZoneRulesTransitionRulesItemDayOfWeek[keyof typeof WorkdayTimeZoneRulesTransitionRulesItemDayOfWeek];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkdayTimeZoneRulesTransitionRulesItemDayOfWeek = {
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
	SUNDAY: "SUNDAY",
} as const;

export type WorkdayTimeZoneRulesTransitionRulesItem = {
	dayOfMonthIndicator?: number;
	dayOfWeek?: WorkdayTimeZoneRulesTransitionRulesItemDayOfWeek;
	localTime?: LocalTime;
	midnightEndOfDay?: boolean;
	month?: WorkdayTimeZoneRulesTransitionRulesItemMonth;
	offsetAfter?: WorkdayTimeZoneRulesTransitionRulesItemOffsetAfter;
	offsetBefore?: WorkdayTimeZoneRulesTransitionRulesItemOffsetBefore;
	standardOffset?: WorkdayTimeZoneRulesTransitionRulesItemStandardOffset;
	timeDefinition?: WorkdayTimeZoneRulesTransitionRulesItemTimeDefinition;
};

export interface ViewsVisibilityTeam {
	teamId?: number;
	viewId?: number;
}

export interface ViewsVisibilityAgent {
	agentId?: number;
	viewId?: number;
}

export type ViewVisibility = typeof ViewVisibility[keyof typeof ViewVisibility];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ViewVisibility = {
	PRIVATE: "PRIVATE",
	TEAMS: "TEAMS",
	AGENTS: "AGENTS",
	GLOBAL: "GLOBAL",
} as const;

export type ViewFilters = { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };

export interface View {
	creatorAgentId?: number;
	filters?: ViewFilters;
	icon?: string;
	id?: number;
	name?: string;
	visibility?: ViewVisibility;
	visibilityAgents?: ViewsVisibilityAgent[];
	visibilityAgentsIds?: number[];
	visibilityTeams?: ViewsVisibilityTeam[];
	visibilityTeamsIds?: number[];
}

export interface UserMassUpdateReq {
	disabled?: boolean;
	ids?: number[];
}

export interface UserInviteRedeem {
	code?: string;
	name?: string;
	password?: string;
}

export interface UserInvite {
	code?: string;
	email?: string;
}

export interface User {
	activationHash?: string;
	activationSentTime?: string;
	companyId?: number;
	created?: string;
	deleted?: boolean;
	disabled?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	email?: string;
	id?: number;
	isAgent?: boolean;
	isPrimaryContact?: boolean;
	kbArticleFavourites?: KbArticleFavourite[];
	lastOnline?: string;
	modified?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
	password?: string;
	/**
   * @minLength 0
   * @maxLength 45
   */
	phoneNumber?: string;
	portalInvitePending?: boolean;
	portalUser?: boolean;
	profilePictureData?: string;
	profilePictureFileName?: string;
	profilePictureMimetype?: string;
}

export interface UpsertImageReq {
	dataBase64?: string;
	fileName?: string;
	mimeType?: string;
}

export interface TinymceImageUpload {
	dataBase64?: string;
	mimeType?: string;
}

export interface TicketList {
	agentId?: number;
	categoryId?: number;
	channelId?: number;
	companyId?: number;
	customCreated?: string;
	deleted?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	description?: string;
	firstUserActionNote?: string;
	id?: number;
	priorityId?: number;
	resolutionDue?: string;
	responseDue?: string;
	slaId?: number;
	statusId?: number;
	teamId?: number;
	ticketread?: boolean;
	userId?: number;
}

export interface TicketMassUpdateReq {
	agentId?: number;
	deleted?: boolean;
	ids: number[];
	statusId?: number;
	teamId?: number;
}

export interface TicketHistory {
	customCreated?: string;
	deleted?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	description?: string;
	id?: number;
	statusId?: number;
	userId?: number;
}

export interface TicketExportReq {
	filters?: TicketExportReqFilters;
	includeFirstUserAction?: boolean;
}

export type TicketCountsViewCounts = { [key: string]: number };

export type TicketCountsAgentCounts = { [key: string]: number };

export interface TicketCounts {
	agentCounts: TicketCountsAgentCounts;
	mine: number;
	statusClosed: number;
	statusOpen: number;
	statusUser: number;
	teams: TeamTicketCounts[];
	unresolved: number;
	viewCounts: TicketCountsViewCounts;
}

export type TicketChannelInfo = { [key: string]: string };

export interface Ticket {
	actions?: Action[];
	agentId?: number;
	bcc?: string[];
	categoryId?: number;
	cc?: string[];
	channelId?: number;
	channelInfo?: TicketChannelInfo;
	companyId?: number;
	customCreated?: string;
	deleted?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	description?: string;
	id?: number;
	mergedIntoTicket?: TicketList;
	mergedTickets?: TicketList[];
	priorityId?: number;
	resolutionDue?: string;
	responseDue?: string;
	slaId?: number;
	statusId?: number;
	teamId?: number;
	ticketHistory?: TicketHistory[];
	ticketread?: boolean;
	userId?: number;
}

export type TextFilterAllOfComparison = typeof TextFilterAllOfComparison[keyof typeof TextFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TextFilterAllOfComparison = {
	empty: "empty",
	equals: "equals",
	notEqual: "notEqual",
	lessThan: "lessThan",
	lessThanOrEqual: "lessThanOrEqual",
	greaterThan: "greaterThan",
	greaterThanOrEqual: "greaterThanOrEqual",
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	blank: "blank",
	notBlank: "notBlank",
} as const;

export type TextFilterAllOf = {
	comparison?: TextFilterAllOfComparison;
	value?: string;
};

export type TextFilter = Filter & TextFilterAllOf;

export type TicketExportReqFilters = { [key: string]: BoolFilter | CombinedSimpleFilter | DateFilter | NumberFilter | SetFilter | TextFilter };

export type TeamTicketCountsAgentCounts = { [key: string]: number };

export interface TeamTicketCounts {
	agentCounts?: TeamTicketCountsAgentCounts;
	teamId?: number;
	total?: number;
	unassigned?: number;
}

export type TeamAutoAssignMethod = typeof TeamAutoAssignMethod[keyof typeof TeamAutoAssignMethod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TeamAutoAssignMethod = {
	ROUND_ROBIN: "ROUND_ROBIN",
	LOAD_BALANCE: "LOAD_BALANCE",
} as const;

export interface Team {
	agentIds?: number[];
	agents?: AgentsTeams[];
	autoAssignMethod?: TeamAutoAssignMethod;
	created?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	description?: string;
	disabled?: boolean;
	icon?: string;
	id?: number;
	modified?: string;
	/**
   * @minLength 0
   * @maxLength 45
   */
	name?: string;
	roundRobinLastAgentId?: number;
}

export interface SuperKbArticleContentPK {
	articleId?: number;
	language?: string;
}

export interface Status {
	deleted?: boolean;
	editable?: boolean;
	id?: number;
	/**
   * @minLength 0
   * @maxLength 45
   */
	name?: string;
	stopsSla?: boolean;
}

export interface SseEmitter {
	timeout?: number;
}

export interface SortModel {
	colId?: string;
	sort?: string;
}

export interface Sla {
	id?: number;
	/**
   * @minLength 0
   * @maxLength 45
   */
	name?: string;
	p1Resolution?: number;
	p1ResolutionUnit?: number;
	p1Response?: number;
	p1ResponseUnit?: number;
	p2Resolution?: number;
	p2ResolutionUnit?: number;
	p2Response?: number;
	p2ResponseUnit?: number;
	p3Resolution?: number;
	p3ResolutionUnit?: number;
	p3Response?: number;
	p3ResponseUnit?: number;
	p4Resolution?: number;
	p4ResolutionUnit?: number;
	p4Response?: number;
	p4ResponseUnit?: number;
	p5Resolution?: number;
	p5ResolutionUnit?: number;
	p5Response?: number;
	p5ResponseUnit?: number;
}

export interface Setting {
	/**
   * @minLength 0
   * @maxLength 255
   */
	key?: string;
	/**
   * @minLength 0
   * @maxLength 16777215
   */
	value?: string;
}

export type SetFilterAllOfComparison = typeof SetFilterAllOfComparison[keyof typeof SetFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SetFilterAllOfComparison = {
	contains: "contains",
	notContains: "notContains",
} as const;

export type SetFilterAllOf = {
	comparison?: SetFilterAllOfComparison;
	values?: number[];
};

export type SetFilter = Filter & SetFilterAllOf;

export type SalablePlanPlan = typeof SalablePlanPlan[keyof typeof SalablePlanPlan];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalablePlanPlan = {
	TRIAL: "TRIAL",
	APPSUMO: "APPSUMO",
	BASIC: "BASIC",
	PRO: "PRO",
} as const;

export type SalablePlanPaymentTerm = typeof SalablePlanPaymentTerm[keyof typeof SalablePlanPaymentTerm];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalablePlanPaymentTerm = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY",
} as const;

export type SalablePlanCurrency = typeof SalablePlanCurrency[keyof typeof SalablePlanCurrency];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SalablePlanCurrency = {
	USD: "USD",
	GBP: "GBP",
	EUR: "EUR",
} as const;

export interface SalablePlan {
	agentCount?: number;
	currency?: SalablePlanCurrency;
	paymentTerm?: SalablePlanPaymentTerm;
	plan?: SalablePlanPlan;
}

export type ReportDashboardTicketStatus = { [key: string]: number };

export type ReportDashboardTicketPriority = { [key: string]: number };

export interface ReportDashboard {
	avgAgentResponsesPerClosedTicket?: number;
	/** @deprecated */
	avgFirstResponseTimeMins?: number;
	avgFirstResponseTimeRawMins?: number;
	avgFirstResponseTimeWorkingMins?: number;
	/** @deprecated */
	avgResponseTimeMins?: number;
	avgResponseTimeRawMins?: number;
	avgResponseTimeWorkingMins?: number;
	createdTickets?: number;
	oneTouchClosedTicketsPercent?: number;
	resolvedTickets?: number;
	ticketDayGraph?: number[];
	ticketHourGraph?: number[];
	ticketPriority?: ReportDashboardTicketPriority;
	ticketStatus?: ReportDashboardTicketStatus;
	unresolvedTickets?: number;
}

export interface PushSubscriptionDTO {
	endpoint: string;
	keys: Keys;
}

export interface Priority {
	id?: number;
	/**
   * @minLength 0
   * @maxLength 45
   */
	name?: string;
}

export interface PortalEmailUpdateReq {
	email?: string;
}

export interface PortalEmailChangeRedemptionReq {
	code?: string;
}

export type PayloadVoidFormFieldErrorCode = typeof PayloadVoidFormFieldErrorCode[keyof typeof PayloadVoidFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadVoidFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadVoidFormErrorCode = typeof PayloadVoidFormErrorCode[keyof typeof PayloadVoidFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadVoidFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export type PayloadVoidData = { [key: string]: unknown };

export interface PayloadVoid {
	data?: PayloadVoidData;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadVoidFormErrorCode;
	formFieldErrorCode?: PayloadVoidFormFieldErrorCode;
}

export type PayloadViewFormFieldErrorCode = typeof PayloadViewFormFieldErrorCode[keyof typeof PayloadViewFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadViewFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadViewFormErrorCode = typeof PayloadViewFormErrorCode[keyof typeof PayloadViewFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadViewFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadView {
	data?: View;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadViewFormErrorCode;
	formFieldErrorCode?: PayloadViewFormFieldErrorCode;
}

export type PayloadUserFormFieldErrorCode = typeof PayloadUserFormFieldErrorCode[keyof typeof PayloadUserFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadUserFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadUserFormErrorCode = typeof PayloadUserFormErrorCode[keyof typeof PayloadUserFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadUserFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export type PayloadUserData = User | Agent;

export interface PayloadUser {
	data?: PayloadUserData;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadUserFormErrorCode;
	formFieldErrorCode?: PayloadUserFormFieldErrorCode;
}

export type PayloadTicketFormFieldErrorCode = typeof PayloadTicketFormFieldErrorCode[keyof typeof PayloadTicketFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadTicketFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadTicketFormErrorCode = typeof PayloadTicketFormErrorCode[keyof typeof PayloadTicketFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadTicketFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadTicket {
	data?: Ticket;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadTicketFormErrorCode;
	formFieldErrorCode?: PayloadTicketFormFieldErrorCode;
}

export type PayloadTeamFormFieldErrorCode = typeof PayloadTeamFormFieldErrorCode[keyof typeof PayloadTeamFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadTeamFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadTeamFormErrorCode = typeof PayloadTeamFormErrorCode[keyof typeof PayloadTeamFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadTeamFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadTeam {
	data?: Team;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadTeamFormErrorCode;
	formFieldErrorCode?: PayloadTeamFormFieldErrorCode;
}

export type PayloadStringFormFieldErrorCode = typeof PayloadStringFormFieldErrorCode[keyof typeof PayloadStringFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadStringFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadStringFormErrorCode = typeof PayloadStringFormErrorCode[keyof typeof PayloadStringFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadStringFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadString {
	data?: string;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadStringFormErrorCode;
	formFieldErrorCode?: PayloadStringFormFieldErrorCode;
}

export type PayloadSlaFormFieldErrorCode = typeof PayloadSlaFormFieldErrorCode[keyof typeof PayloadSlaFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadSlaFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadSlaFormErrorCode = typeof PayloadSlaFormErrorCode[keyof typeof PayloadSlaFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadSlaFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadSla {
	data?: Sla;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadSlaFormErrorCode;
	formFieldErrorCode?: PayloadSlaFormFieldErrorCode;
}

export type PayloadSettingFormFieldErrorCode = typeof PayloadSettingFormFieldErrorCode[keyof typeof PayloadSettingFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadSettingFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadSettingFormErrorCode = typeof PayloadSettingFormErrorCode[keyof typeof PayloadSettingFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadSettingFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadSetting {
	data?: Setting;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadSettingFormErrorCode;
	formFieldErrorCode?: PayloadSettingFormFieldErrorCode;
}

export interface PayloadPagination {
	limit: number;
	offset: number;
	total: number;
}

export type PayloadKbSectionFormFieldErrorCode = typeof PayloadKbSectionFormFieldErrorCode[keyof typeof PayloadKbSectionFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbSectionFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadKbSectionFormErrorCode = typeof PayloadKbSectionFormErrorCode[keyof typeof PayloadKbSectionFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbSectionFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadKbSection {
	data?: KbSection;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadKbSectionFormErrorCode;
	formFieldErrorCode?: PayloadKbSectionFormFieldErrorCode;
}

export type PayloadKbArticleContentFormFieldErrorCode = typeof PayloadKbArticleContentFormFieldErrorCode[keyof typeof PayloadKbArticleContentFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbArticleContentFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadKbArticleContentFormErrorCode = typeof PayloadKbArticleContentFormErrorCode[keyof typeof PayloadKbArticleContentFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbArticleContentFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadKbArticleContent {
	data?: KbArticleContent;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadKbArticleContentFormErrorCode;
	formFieldErrorCode?: PayloadKbArticleContentFormFieldErrorCode;
}

export type PayloadKbArticleFormFieldErrorCode = typeof PayloadKbArticleFormFieldErrorCode[keyof typeof PayloadKbArticleFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbArticleFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadKbArticleFormErrorCode = typeof PayloadKbArticleFormErrorCode[keyof typeof PayloadKbArticleFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbArticleFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadKbArticle {
	data?: KbArticle;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadKbArticleFormErrorCode;
	formFieldErrorCode?: PayloadKbArticleFormFieldErrorCode;
}

export type PayloadKbFormFieldErrorCode = typeof PayloadKbFormFieldErrorCode[keyof typeof PayloadKbFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadKbFormErrorCode = typeof PayloadKbFormErrorCode[keyof typeof PayloadKbFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadKbFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadKb {
	data?: Kb;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadKbFormErrorCode;
	formFieldErrorCode?: PayloadKbFormFieldErrorCode;
}

export type PayloadGlobalSearchFormFieldErrorCode = typeof PayloadGlobalSearchFormFieldErrorCode[keyof typeof PayloadGlobalSearchFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadGlobalSearchFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadGlobalSearchFormErrorCode = typeof PayloadGlobalSearchFormErrorCode[keyof typeof PayloadGlobalSearchFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadGlobalSearchFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadGlobalSearch {
	data?: GlobalSearch;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadGlobalSearchFormErrorCode;
	formFieldErrorCode?: PayloadGlobalSearchFormFieldErrorCode;
}

export type PayloadEmailNotificationFormFieldErrorCode = typeof PayloadEmailNotificationFormFieldErrorCode[keyof typeof PayloadEmailNotificationFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadEmailNotificationFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadEmailNotificationFormErrorCode = typeof PayloadEmailNotificationFormErrorCode[keyof typeof PayloadEmailNotificationFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadEmailNotificationFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadEmailNotification {
	data?: EmailNotification;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadEmailNotificationFormErrorCode;
	formFieldErrorCode?: PayloadEmailNotificationFormFieldErrorCode;
}

export type PayloadCompanyNoteFormFieldErrorCode = typeof PayloadCompanyNoteFormFieldErrorCode[keyof typeof PayloadCompanyNoteFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCompanyNoteFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadCompanyNoteFormErrorCode = typeof PayloadCompanyNoteFormErrorCode[keyof typeof PayloadCompanyNoteFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCompanyNoteFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadCompanyNote {
	data?: CompanyNote;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadCompanyNoteFormErrorCode;
	formFieldErrorCode?: PayloadCompanyNoteFormFieldErrorCode;
}

export type PayloadCompanyFormFieldErrorCode = typeof PayloadCompanyFormFieldErrorCode[keyof typeof PayloadCompanyFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCompanyFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadCompanyFormErrorCode = typeof PayloadCompanyFormErrorCode[keyof typeof PayloadCompanyFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCompanyFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadCompany {
	data?: Company;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadCompanyFormErrorCode;
	formFieldErrorCode?: PayloadCompanyFormFieldErrorCode;
}

export interface PayloadCollectionView {
	data: View[];
	pagination: PayloadPagination;
}

export type PayloadCollectionUserDataItem = User | Agent;

export interface PayloadCollectionUser {
	data: PayloadCollectionUserDataItem[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionTicket {
	data: Ticket[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionTeam {
	data: Team[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionString {
	data: string[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionSla {
	data: Sla[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionSetting {
	data: Setting[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionLayout {
	data: Layout[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionKbSection {
	data: KbSection[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionKbArticle {
	data: KbArticle[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionKb {
	data: Kb[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionFeedItem {
	data: FeedItem[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionEmailNotification {
	data: EmailNotification[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionCompanyNote {
	data: CompanyNote[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionCompany {
	data: Company[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionChannelsEmail {
	data: ChannelsEmail[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionCategory {
	data: Category[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionCannedReply {
	data: CannedReply[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionAutomation {
	data: Automation[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionAttachment {
	data: Attachment[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionAgent {
	data: Agent[];
	pagination: PayloadPagination;
}

export interface PayloadCollectionAction {
	data: Action[];
	pagination: PayloadPagination;
}

export type PayloadChannelsEmailFormFieldErrorCode = typeof PayloadChannelsEmailFormFieldErrorCode[keyof typeof PayloadChannelsEmailFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadChannelsEmailFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadChannelsEmailFormErrorCode = typeof PayloadChannelsEmailFormErrorCode[keyof typeof PayloadChannelsEmailFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadChannelsEmailFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadChannelsEmail {
	data?: ChannelsEmail;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadChannelsEmailFormErrorCode;
	formFieldErrorCode?: PayloadChannelsEmailFormFieldErrorCode;
}

export type PayloadCategoryFormFieldErrorCode = typeof PayloadCategoryFormFieldErrorCode[keyof typeof PayloadCategoryFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCategoryFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadCategoryFormErrorCode = typeof PayloadCategoryFormErrorCode[keyof typeof PayloadCategoryFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCategoryFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadCategory {
	data?: Category;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadCategoryFormErrorCode;
	formFieldErrorCode?: PayloadCategoryFormFieldErrorCode;
}

export type PayloadCannedReplyFormFieldErrorCode = typeof PayloadCannedReplyFormFieldErrorCode[keyof typeof PayloadCannedReplyFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCannedReplyFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadCannedReplyFormErrorCode = typeof PayloadCannedReplyFormErrorCode[keyof typeof PayloadCannedReplyFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadCannedReplyFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadCannedReply {
	data?: CannedReply;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadCannedReplyFormErrorCode;
	formFieldErrorCode?: PayloadCannedReplyFormFieldErrorCode;
}

export type PayloadBooleanFormFieldErrorCode = typeof PayloadBooleanFormFieldErrorCode[keyof typeof PayloadBooleanFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadBooleanFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadBooleanFormErrorCode = typeof PayloadBooleanFormErrorCode[keyof typeof PayloadBooleanFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadBooleanFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadBoolean {
	data?: boolean;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadBooleanFormErrorCode;
	formFieldErrorCode?: PayloadBooleanFormFieldErrorCode;
}

export type PayloadAutomationFormFieldErrorCode = typeof PayloadAutomationFormFieldErrorCode[keyof typeof PayloadAutomationFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAutomationFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadAutomationFormErrorCode = typeof PayloadAutomationFormErrorCode[keyof typeof PayloadAutomationFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAutomationFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadAutomation {
	data?: Automation;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadAutomationFormErrorCode;
	formFieldErrorCode?: PayloadAutomationFormFieldErrorCode;
}

export type PayloadAttachmentFormFieldErrorCode = typeof PayloadAttachmentFormFieldErrorCode[keyof typeof PayloadAttachmentFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAttachmentFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadAttachmentFormErrorCode = typeof PayloadAttachmentFormErrorCode[keyof typeof PayloadAttachmentFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAttachmentFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadAttachment {
	data?: Attachment;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadAttachmentFormErrorCode;
	formFieldErrorCode?: PayloadAttachmentFormFieldErrorCode;
}

export type PayloadAgentFormFieldErrorCode = typeof PayloadAgentFormFieldErrorCode[keyof typeof PayloadAgentFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAgentFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadAgentFormErrorCode = typeof PayloadAgentFormErrorCode[keyof typeof PayloadAgentFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadAgentFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadAgent {
	data?: Agent;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadAgentFormErrorCode;
	formFieldErrorCode?: PayloadAgentFormFieldErrorCode;
}

export type PayloadActionFormFieldErrorCode = typeof PayloadActionFormFieldErrorCode[keyof typeof PayloadActionFormFieldErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadActionFormFieldErrorCode = {
	EMAIL_TAKEN_BY_USER: "EMAIL_TAKEN_BY_USER",
	EMAIL_TAKEN_BY_EMAIL_CHANNEL: "EMAIL_TAKEN_BY_EMAIL_CHANNEL",
	EMAIL_TAKEN_BY_AGENT: "EMAIL_TAKEN_BY_AGENT",
} as const;

export type PayloadActionFormErrorCode = typeof PayloadActionFormErrorCode[keyof typeof PayloadActionFormErrorCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PayloadActionFormErrorCode = {
	SUPPORT_AGENT_ACTION: "SUPPORT_AGENT_ACTION",
} as const;

export interface PayloadAction {
	data?: Action;
	dataNumber1?: number;
	errorField?: string;
	errorMsg?: string;
	formErrorCode?: PayloadActionFormErrorCode;
	formFieldErrorCode?: PayloadActionFormFieldErrorCode;
}

export interface PasswordDTO {
	password?: string;
}

export type NumberFilterAllOfComparison = typeof NumberFilterAllOfComparison[keyof typeof NumberFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NumberFilterAllOfComparison = {
	empty: "empty",
	equals: "equals",
	notEqual: "notEqual",
	lessThan: "lessThan",
	lessThanOrEqual: "lessThanOrEqual",
	greaterThan: "greaterThan",
	greaterThanOrEqual: "greaterThanOrEqual",
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	blank: "blank",
	notBlank: "notBlank",
} as const;

export type NumberFilterAllOf = {
	comparison?: NumberFilterAllOfComparison;
	value?: number;
	valueTo?: number;
};

export type NumberFilter = Filter & NumberFilterAllOf;

export interface NotificationPref {
	email?: boolean;
	godesk?: boolean;
	push?: boolean;
}

export interface Notification {
	ctime: string;
	id: number;
	type: string;
}

export type NewTicketNotificationAllOf = {
	ticketId?: number;
	userId?: number;
};

export type NewTicketNotification = Notification & NewTicketNotificationAllOf;

export type NewReplyNotificationAllOf = {
	ticketId?: number;
	userId?: number;
};

export type NewReplyNotification = Notification & NewReplyNotificationAllOf;

export interface MultiUserInvite {
	emailsToInvite: string;
}

export interface MoveAllTicketsReq {
	targetTeamId: number;
}

export interface MetricEvent {
	name?: string;
	properties?: JSONObject;
}

export interface MergeTicketsReq {
	mergedTicketIds: number[];
	ownerTicketId: number;
}

export interface LocalTime {
	hour?: number;
	minute?: number;
	nano?: number;
	second?: number;
}

export type LayoutVisibility = typeof LayoutVisibility[keyof typeof LayoutVisibility];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LayoutVisibility = {
	PRIVATE: "PRIVATE",
	TEAMS: "TEAMS",
	AGENTS: "AGENTS",
	GLOBAL: "GLOBAL",
} as const;

export type LayoutEntity = typeof LayoutEntity[keyof typeof LayoutEntity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LayoutEntity = {
	TICKET: "TICKET",
} as const;

export interface Layout {
	columns: string[];
	creatorAgentId: number;
	entity: LayoutEntity;
	id?: number;
	name: string;
	sortModels?: SortModel[];
	visibility: LayoutVisibility;
	visibilityAgentsIds?: number[];
	visibilityTeamsIds?: number[];
}

export interface Keys {
	auth: string;
	p256dh: string;
}

export interface KbArticleFavourite {
	kbArticleId?: number;
	userId?: number;
}

export interface KbArticleContentList {
	id?: SuperKbArticleContentPK;
	/**
   * @minLength 0
   * @maxLength 255
   */
	title?: string;
}

export interface KbArticleContent {
	/**
   * @minLength 0
   * @maxLength 16777215
   */
	body?: string;
	id?: SuperKbArticleContentPK;
	/**
   * @minLength 0
   * @maxLength 255
   */
	title?: string;
}

export interface KbArticle {
	articleContent?: KbArticleContentList[];
	created?: string;
	creatorId?: number;
	creatorName?: string;
	defaultArticleTitle?: string;
	downvotes?: number;
	editorId?: number;
	id?: number;
	kbId?: number;
	kbSectionId?: number;
	modified?: string;
	myUpvote?: boolean;
	published?: boolean;
	sectionName?: string;
	upvotes?: number;
	views?: number;
	viewsLast30Days?: number;
}

export interface KbSection {
	articles?: KbArticle[];
	childFolders?: KbSection[];
	/**
   * @minLength 0
   * @maxLength 500
   */
	description?: string;
	id?: number;
	kbId?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
	parentId?: number;
	pictureData?: string;
	pictureFileName?: string;
	pictureMimetype?: string;
}

export interface Kb {
	bannerFileData?: string;
	bannerFileMimetype?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	bannerFileName?: string;
	faviconFileData?: string;
	faviconFileMimetype?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	faviconFileName?: string;
	id?: number;
	isPrivate?: boolean;
	logoFileData?: string;
	logoFileMimetype?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	logoFileName?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
	/**
   * @minItems 0
   * @maxItems 255
   */
	readonly otherLanguages?: readonly string[];
	/**
   * @minLength 0
   * @maxLength 10
   */
	primaryLanguage?: string;
	sections?: KbSection[];
	whitelistedCategoryIds?: number[];
}

export interface JSONObject {
	empty?: boolean;
}

export type GlobalSearchUsersItem = User | Agent;

export interface GlobalSearch {
	agents?: Agent[];
	agentsPagination?: PayloadPagination;
	articles?: KbArticle[];
	articlesPagination?: PayloadPagination;
	companies?: Company[];
	companiesPagination?: PayloadPagination;
	tickets?: Ticket[];
	ticketsPagination?: PayloadPagination;
	timeTakenMs: number;
	totalResultCount: number;
	users?: GlobalSearchUsersItem[];
	usersPagination?: PayloadPagination;
}

export interface Filter {
	field?: string;
	id?: number;
	type: string;
	viewId?: number;
}

export interface FeedItem {
	agentId?: number;
	customCreated?: string;
	description?: string;
	id: number;
	noteBody?: string;
	ticketId: number;
	userId?: number;
}

export interface EmailNotification {
	/**
   * @minLength 0
   * @maxLength 65535
   */
	content?: string;
	id?: number;
	subject?: string;
}

export interface EmailCheckReq {
	email?: string;
}

export interface EmailAddress {
	email?: string;
	personalName?: string;
}

export interface Email {
	actionId?: number;
	attachments?: Attachment[];
	bccList?: EmailAddress[];
	categoryId?: number;
	ccList?: EmailAddress[];
	channelId?: number;
	email_in_reply_to?: string;
	email_message_id?: string;
	email_references?: string;
	from?: EmailAddress;
	fromPortal?: string;
	hash?: number;
	messageHtml?: string;
	noteBody?: string;
	sentDate?: string;
	subject?: string;
	tenantId?: number;
	toList?: EmailAddress[];
}

export type DateFilterAllOfComparison = typeof DateFilterAllOfComparison[keyof typeof DateFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DateFilterAllOfComparison = {
	empty: "empty",
	equals: "equals",
	notEqual: "notEqual",
	lessThan: "lessThan",
	lessThanOrEqual: "lessThanOrEqual",
	greaterThan: "greaterThan",
	greaterThanOrEqual: "greaterThanOrEqual",
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	blank: "blank",
	notBlank: "notBlank",
} as const;

export type DateFilterAllOf = {
	comparison?: DateFilterAllOfComparison;
	value?: string;
	valueTo?: string;
};

export type DateFilter = Filter & DateFilterAllOf;

export interface CustomFieldOptions {
	customFieldId?: number;
	id?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	label: string;
}

export type CustomFieldType = typeof CustomFieldType[keyof typeof CustomFieldType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFieldType = {
	TEXT_INPUT: "TEXT_INPUT",
	TEXT_AREA: "TEXT_AREA",
	DROPDOWN: "DROPDOWN",
	MULTISELECT: "MULTISELECT",
} as const;

export type CustomFieldEntity = typeof CustomFieldEntity[keyof typeof CustomFieldEntity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomFieldEntity = {
	USER: "USER",
	COMPANY: "COMPANY",
} as const;

export interface CustomField {
	/**
   * @minLength 0
   * @maxLength 500
   */
	description?: string;
	displayOrder?: number;
	entity: CustomFieldEntity;
	hidden?: boolean;
	id?: number;
	isGlobalSearchable?: boolean;
	/**
   * @minLength 0
   * @maxLength 100
   */
	label: string;
	mandatory?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name: string;
	options?: CustomFieldOptions[];
	/**
   * @minLength 0
   * @maxLength 255
   */
	tooltip?: string;
	type: CustomFieldType;
}

export interface CompanyNote {
	companyId?: number;
	created?: string;
	creatorId?: number;
	id?: number;
	modified?: string;
	note?: string;
}

export type CompanyUsersItem = User | Agent;

export type CompanyCustomValues = { [key: string]: string };

export interface Company {
	accountManagerId?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	address_city?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	address_line1?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	address_line2?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	address_postcode?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	address_region?: string;
	customValues?: CompanyCustomValues;
	/**
   * @minLength 0
   * @maxLength 150
   */
	description?: string;
	disabled?: boolean;
	/**
   * @minLength 0
   * @maxLength 255
   */
	emailDomain?: string;
	id?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
	primaryUserId?: number;
	profilePictureData?: string;
	profilePictureFileName?: string;
	profilePictureMimetype?: string;
	ticketCount?: number;
	userCount?: number;
	users?: CompanyUsersItem[];
}

export type CombinedSimpleFilterAllOf = {
	comparison?: CombinedSimpleFilterAllOfComparison;
	condition1?: Filter;
	condition2?: Filter;
	operator?: CombinedSimpleFilterAllOfOperator;
};

export type CombinedSimpleFilter = Filter & CombinedSimpleFilterAllOf;

export type CombinedSimpleFilterAllOfOperator = typeof CombinedSimpleFilterAllOfOperator[keyof typeof CombinedSimpleFilterAllOfOperator];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CombinedSimpleFilterAllOfOperator = {
	AND: "AND",
	OR: "OR",
} as const;

export type CombinedSimpleFilterAllOfComparison = typeof CombinedSimpleFilterAllOfComparison[keyof typeof CombinedSimpleFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CombinedSimpleFilterAllOfComparison = {
	empty: "empty",
	equals: "equals",
	notEqual: "notEqual",
	lessThan: "lessThan",
	lessThanOrEqual: "lessThanOrEqual",
	greaterThan: "greaterThan",
	greaterThanOrEqual: "greaterThanOrEqual",
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	blank: "blank",
	notBlank: "notBlank",
} as const;

export type CollisionNoteEntity = typeof CollisionNoteEntity[keyof typeof CollisionNoteEntity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollisionNoteEntity = {
	TICKET: "TICKET",
} as const;

export type CollisionNoteAction = typeof CollisionNoteAction[keyof typeof CollisionNoteAction];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CollisionNoteAction = {
	VIEWING: "VIEWING",
	EDITING: "EDITING",
	ADDING: "ADDING",
	STOPPED_VIEWING: "STOPPED_VIEWING",
	STOPPED_EDITING: "STOPPED_EDITING",
	STOPPED_ADDING: "STOPPED_ADDING",
} as const;

export interface CollisionNote {
	action: CollisionNoteAction;
	agentId: number;
	entity: CollisionNoteEntity;
	entityId: number;
	time?: string;
}

export type ChannelsEmailSmtpSecurity = typeof ChannelsEmailSmtpSecurity[keyof typeof ChannelsEmailSmtpSecurity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChannelsEmailSmtpSecurity = {
	STARTTLS: "STARTTLS",
	TLSSSL: "TLSSSL",
} as const;

export type ChannelsEmailImapSecurity = typeof ChannelsEmailImapSecurity[keyof typeof ChannelsEmailImapSecurity];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChannelsEmailImapSecurity = {
	STARTTLS: "STARTTLS",
	TLSSSL: "TLSSSL",
} as const;

export interface ChannelsEmail {
	address?: string;
	disabled?: boolean;
	gmailAccessToken?: string;
	gmailAccessTokenExpires?: string;
	gmailRefreshToken?: string;
	readonly hasGmailTokens?: boolean;
	readonly hasImapPass?: boolean;
	readonly hasMsTokens?: boolean;
	readonly hasSmtpPass?: boolean;
	id?: number;
	imapPass?: string;
	imapPort?: number;
	imapSecurity?: ChannelsEmailImapSecurity;
	imapServer?: string;
	imapUser?: string;
	isGmail?: boolean;
	isGodeskManaged?: boolean;
	isMicrosoft?: boolean;
	msAccessToken?: string;
	msAccessTokenExpires?: string;
	msRefreshToken?: string;
	msSubscriptionExpires?: string;
	msSubscriptionId?: string;
	name?: string;
	smtpPass?: string;
	smtpPort?: number;
	smtpSecurity?: ChannelsEmailSmtpSecurity;
	smtpServer?: string;
	smtpUser?: string;
	teamId: number;
}

export interface Channel {
	disabled?: boolean;
	id?: number;
	name?: string;
	teamId: number;
}

export interface Category {
	created?: string;
	id?: number;
	modified?: string;
	/**
   * @minLength 0
   * @maxLength 45
   */
	name?: string;
	parentId?: number;
}

export interface CannedReply {
	allowAll?: boolean;
	allowOwner?: boolean;
	allowTeamId?: number;
	created?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	description?: string;
	id?: number;
	modified?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
	/**
   * @minLength 0
   * @maxLength 65535
   */
	note?: string;
	ownerAgentId?: number;
}

export type CacheUsersItem = User | Agent;

export interface Cache {
	Agents?: Agent[];
	CannedReplies?: CannedReply[];
	Categories?: Category[];
	Channels?: Channel[];
	Companies?: Company[];
	CustomFields?: CustomField[];
	hash?: string;
	Kbs?: Kb[];
	KbSections?: KbSection[];
	Layouts?: Layout[];
	Priorities?: Priority[];
	Settings?: Setting[];
	Slas?: Sla[];
	Statuses?: Status[];
	Teams?: Team[];
	userId?: number;
	Users?: CacheUsersItem[];
	Views?: View[];
	Workdays?: Workday[];
}

export type BoolFilterAllOfComparison = typeof BoolFilterAllOfComparison[keyof typeof BoolFilterAllOfComparison];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoolFilterAllOfComparison = {
	empty: "empty",
	equals: "equals",
	notEqual: "notEqual",
	lessThan: "lessThan",
	lessThanOrEqual: "lessThanOrEqual",
	greaterThan: "greaterThan",
	greaterThanOrEqual: "greaterThanOrEqual",
	inRange: "inRange",
	contains: "contains",
	notContains: "notContains",
	startsWith: "startsWith",
	endsWith: "endsWith",
	blank: "blank",
	notBlank: "notBlank",
} as const;

export type BoolFilterAllOf = {
	comparison?: BoolFilterAllOfComparison;
	value?: boolean;
};

export type BoolFilter = Filter & BoolFilterAllOf;

export interface BillingStatus {
	cancelled?: boolean;
	nextPaymentDate?: string;
	salablePlan?: SalablePlan;
	subscriptionUuid?: string;
}

export interface AutomationCondition {
	automationId?: number;
	/**
   * @minLength 0
   * @maxLength 45
   */
	field?: string;
	id?: number;
	/**
   * @minLength 0
   * @maxLength 45
   */
	operator?: string;
	/**
   * @minLength 0
   * @maxLength 45
   */
	value?: string;
}

export interface AutomationAction {
	/**
   * @minLength 0
   * @maxLength 45
   */
	action?: string;
	automationId?: number;
	dataInt?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	dataString?: string;
	id?: number;
}

export interface Automation {
	actions?: AutomationAction[];
	conditions?: AutomationCondition[];
	id?: number;
	/**
   * @minLength 0
   * @maxLength 255
   */
	name?: string;
}

export interface AttachmentUpload {
	dataBase64?: string;
	originalFileName?: string;
}

export interface Attachment {
	actionId?: number;
	created?: string;
	data?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	fileNameOriginal?: string;
	/**
   * @minLength 0
   * @maxLength 255
   */
	fileNameStorage?: string;
	fileSizeBytes?: number;
	id?: number;
}

export type AssignedToTeamNotificationAllOf = {
	teamId?: number;
	ticketId?: number;
	userId?: number;
};

export type AssignedToTeamNotification = Notification & AssignedToTeamNotificationAllOf;

export type AssignedToMeNotificationAllOf = {
	ticketId?: number;
	userId?: number;
};

export type AssignedToMeNotification = Notification & AssignedToMeNotificationAllOf;

export type AnnouncementNotificationAllOf = {
	messageHtml?: string;
};

export type AnnouncementNotification = Notification & AnnouncementNotificationAllOf;

export interface AgentsTeams {
	agentId?: number;
	teamId?: number;
}

export interface AgentPrefs {
	emailGreeting?: string;
	hideSidebar?: boolean;
	notificationAssignedToMe?: NotificationPref;
	notificationAssignedToTeam?: NotificationPref;
	notificationMineFirstResponse?: NotificationPref;
	notificationMineResolution?: NotificationPref;
	notificationMyNewReplies?: NotificationPref;
	notificationNewTickets?: NotificationPref;
	notificationTeamFirstResponse?: NotificationPref;
	notificationTeamResolution?: NotificationPref;
}

export interface AgentMassUpdateReq {
	disabled?: boolean;
	ids?: number[];
}

export interface AgentInviteReq {
	email: string;
	isAdmin: boolean;
	teamIds: number[];
}

export interface AgentInviteRedeem {
	code: string;
	name: string;
	password: string;
}

export interface AgentInvite {
	email: string;
	isAdmin: boolean;
	teams: number[];
}

export type AgentAllOf = {
	isAdmin?: boolean;
	/**
   * @minLength 0
   * @maxLength 45
   */
	jobTitle?: string;
	prefs?: AgentPrefs;
	/**
   * @minLength 0
   * @maxLength 65535
   */
	signature?: string;
	status?: number;
	teamIds?: number[];
};

export type Agent = User & AgentAllOf;

export interface Action {
	actionTypeId?: number;
	agentId?: number;
	attachments?: Attachment[];
	customCreated?: string;
	fullNote?: string;
	readonly hasExpandedNote?: boolean;
	id?: number;
	isPublic?: boolean;
	/**
   * @minLength 0
   * @maxLength 16777215
   */
	noteBody?: string;
	sendToUser?: boolean;
	statusId?: number;
	ticketId?: number;
	userId?: number;
}
