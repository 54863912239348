import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTotalTicketRepliesReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TotalTicketRepliesProps {
	dateRange: [DateTime, DateTime];
}

export function TotalTicketReplies(props: TotalTicketRepliesProps) {
	const cache = useCache();

	const reportReq = useGetTotalTicketRepliesReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getAgent(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Total Ticket Replies"
			subtitle="Details the number of replies each agent has logged for the reporting period."
			axisTitles={{ xLabel: "Agent", yLabel: "Replies" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
