import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { UserViewer } from "@/Fragments/EntityViewer/EntitySpecific/UserViewer";
import { globalTabAdded, globalTabReplaced, globalTabUpdated } from "@/Store/Reducers/globalTabsSlice";
import AutomationViewer from "@/Fragments/EntityViewer/EntitySpecific/AutomationViewer";
import SlaViewer from "@/Fragments/EntityViewer/EntitySpecific/SlaViewer";
import { useAppDispatch } from "@/Store/hooks";
import api from "@/Api/Api";
import { GlobalTab, HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { ApiDeleteEntity, RefreshCache } from "@/legacy/ApiCallerOld";
import { FieldError } from "@/legacy/ApiResponse";
import { TicketViewer } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketViewer";
import { EmailSecurity } from "@shared/Enums/Enums";
import { Entities } from "@shared/Entities/Entities";

interface EntityViewerProps {
	id?: number;
	entityType: Entities;
	updateCallback?(): void;
	titlePath?: string;
}

/**
 * This handles most interactions with the API. Gives the EntitySpecific viewers callbacks to do so.
 * @deprecated use useEntity instead. See AgentViewer for example.
 */
function EntityViewer(props: EntityViewerProps) {
	const [entityId, setEntityId] = useState<number | undefined>();
	const [entityData, setEntityData] = useState<any | undefined>(); // Any so it don't have to have a different state var for every entity type.

	const [formError, setFormError] = useState<string | undefined>();
	const [fieldErrors, setFieldErrors] = useState<FieldError[] | undefined>();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const params: any = useParams();

	useEffect(() => {
		if (props.titlePath != null) {
			if (params.id == null) {
				document.title = props.titlePath;
			} else if (params.id == -1) {
				document.title = "New | " + props.titlePath;
			} else if (entityData != null) {
				switch (props.entityType) {
					case Entities.AGENT: document.title = (entityData.name ?? entityData.email) + " | " + props.titlePath; break;
					case Entities.TICKET: document.title = entityData.description + " | " + props.titlePath; break;
					case Entities.USER: document.title = (entityData.name ?? entityData.email) + " | " + props.titlePath; break;
					default: document.title = entityData.name + " | " + props.titlePath; break;
				}
			}
		}
	}, [entityData]);

	// Attempt to get id from url if not provided.
	useEffect(() => {
		if (props.id != null) {
			setEntityId(props.id);
		} else if (params.id != null && !isNaN(parseInt(params.id))) {
			const paramsId: number = parseInt(params.id);

			setEntityId(paramsId);
			if (props.entityType == Entities.TICKET) {
				dispatch(globalTabAdded({ id: paramsId, title: paramsId == -1 ? "New Ticket" : "Ticket #" + paramsId, entity: HeaderTabEntities.TICKETS }));
			} else if (props.entityType == Entities.USER) {
				dispatch(globalTabAdded({ id: paramsId, title: "User #" + paramsId, entity: HeaderTabEntities.USERS }));
			}
		}
	}, [props.id, params.id, dispatch]);

	// Get data when ID changes
	useEffect(() => {
		if (entityId != null && entityId > -1) {
			getEntity();
		} else if (entityId == -1) {
			setEntityData(getDefaultNewEntity());
		}
	}, [entityId]); // props.entityType not included because it caused this to run before the entityId changed.

	function getDefaultNewEntity(): any {
		switch (props.entityType) {
			case Entities.CHANNELSEMAIL:
				return { id: -1, isGodeskManaged: true, imapPort: 993, imapSecurity: EmailSecurity.STARTTLS, smtpPort: 587, smtpSecurity: EmailSecurity.STARTTLS };
			case Entities.SLA:
				return { id: -1, p1Resolution: 4, p1Response: 15, p1ResolutionUnit: 60, p1ResponseUnit: 1, p2Resolution: 1, p2Response: 1, p2ResolutionUnit: 1440, p2ResponseUnit: 60, p3Resolution: 2, p3Response: 2, p3ResolutionUnit: 1440, p3ResponseUnit: 60, p4Resolution: 4, p4Response: 4, p4ResolutionUnit: 1440, p4ResponseUnit: 60, p5Resolution: 0, p5Response: 0, p5ResolutionUnit: 60, p5ResponseUnit: 60 };
			default:
				return { id: -1 };
		}
	}

	async function getEntity() {
		if (entityId != null) {
			const response = await api.getEntity<any>(props.entityType, entityId);

			if (response != null && response.data != null) {
				// Set local data
				setEntityData(response.data);

				// Update global tab
				if (props.entityType == Entities.TICKET) {
					dispatch(globalTabUpdated({ id: entityId, title: response.data.description, entity: HeaderTabEntities.TICKETS }));
				} else if (props.entityType == Entities.USER) {
					dispatch(globalTabUpdated({ id: entityId, title: response.data.name, entity: HeaderTabEntities.USERS }));
				}
			}
		}
	}

	function getNewUrlAfterTabCreation(newTab: GlobalTab): string {
		if (newTab != null) {
			switch (newTab.entity) {
				case HeaderTabEntities.TICKETS:
					return "/tickets/" + newTab.id;
				case HeaderTabEntities.USERS:
					return "/users/" + newTab.id;
			}
		}

		return "/home";
	}

	function updateCallback() {
		if (props.updateCallback) {
			props.updateCallback();
		}
	}

	async function newEntity(data: any) {
		const response = await api.createEntity(props.entityType, data) as any;

		if (response.data != null) {
			setEntityData(response.data);
			setEntityId(response.data.id);
			setFormError(undefined);
			setFieldErrors(undefined);

			if (props.entityType == Entities.TICKET) {
				dispatch(globalTabReplaced({
					currentTab: { id: -1, entity: HeaderTabEntities.TICKETS },
					replaceTab: { id: response.data.id, title: response.data.description, entity: HeaderTabEntities.TICKETS }
				}));
				navigate(getNewUrlAfterTabCreation({ id: response.data.id, title: response.data.description, entity: HeaderTabEntities.TICKETS }));
			} else if (props.entityType == Entities.USER) {
				dispatch(globalTabReplaced({
					currentTab: { id: -1, entity: HeaderTabEntities.USERS },
					replaceTab: { id: response.data.id, title: response.data.name, entity: HeaderTabEntities.USERS }
				}));
				navigate(getNewUrlAfterTabCreation({ id: response.data.id, title: response.data.name, entity: HeaderTabEntities.USERS }));
			} else if (props.entityType == Entities.AGENT) {
				navigate("/config/manage/agents/" + response.data.id);
			} else if (props.entityType == Entities.CANNEDREPLY) {
				navigate("/config/manage/cannedreplies/" + response.data.id);
			} else if (props.entityType == Entities.SLA) {
				navigate("/config/manage/slas/" + response.data.id);
			} else if (props.entityType == Entities.VIEW) {
				navigate("/config/manage/views/" + response.data.id);
			}

			if (props.entityType != Entities.TICKET) { // Refresh Cache.
				await RefreshCache(dispatch);
			}

			updateCallback();
		} else {
			setFormError(response.errorMsg);
			setFieldErrors(response.fieldErrors);
		}
	}

	async function updateEntity(data: any) {
		if (entityId != null) {
			const response = await api.updateEntity(props.entityType, entityId, data);

			if (response.data != null) {
				setEntityData(response.data);
				setFormError(undefined);
				setFieldErrors(undefined);

				if (props.entityType != Entities.TICKET) { // Refresh Cache.
					await RefreshCache(dispatch);
				}

				updateCallback();
			} else {
				setFormError(response.errorMsg);
				setFieldErrors(response.fieldErrors);
			}
		}
	}

	async function deleteEntity() {
		if (entityId != null) {
			await ApiDeleteEntity(props.entityType, entityId);
			toast.success("Deleted");

			updateCallback();
		}
	}

	return (
		entityId != null ?
			props.entityType == Entities.AUTOMATION ?
				<AutomationViewer
					id={entityId}
					data={entityData}
					createNew={newEntity}
					updateData={updateEntity}
					delete={deleteEntity}
				/>
				:
				props.entityType == Entities.SLA ?
					<SlaViewer
						id={entityId}
						data={entityData}
						createNew={newEntity}
						updateData={updateEntity}
						delete={deleteEntity}
					/>
					:
					props.entityType == Entities.TICKET ?
						<TicketViewer
							id={entityId}
							data={entityData}
							createNew={newEntity}
							updateData={updateEntity}
							refreshData={getEntity}
						/>
						:
						props.entityType == Entities.USER ?
							<UserViewer
								id={entityId}
								data={entityData}
								updateData={updateEntity}
								delete={deleteEntity}
								refreshData={getEntity}
							/>
							:
							<div>No EntityViewer found.</div>
			:
			<div>No Id found.</div>
	);
}

export default EntityViewer;
