import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { HiOutlineMail } from "react-icons/hi";
import { NewReplyNotification } from "@/Api/genApi.schemas";
import { UserAvatar } from "@/Components/Avatar/UserAvatar";
import { useCache } from "@/Hooks/useCache";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface NewReplyContentProps {
	notification: NewReplyNotification;
	closeNotifications(): void;
}

export function NewReplyContent(props: NewReplyContentProps) {
	const cache = useCache();

	const user = cache.getUser(props.notification.userId);

	const ctime = DateTime.fromISO(props.notification.ctime);
	const when = datetimeToWhenString(ctime);

	return (
		<Link to={"/tickets/" + props.notification.ticketId} onClick={props.closeNotifications}>
			<div className="flex gap-3 hover:underline">
				<UserAvatar
					widthPx={40}
					user={user}
				/>

				<div>
					<div className="pt-1 pb-1.5 flex items-start gap-1">
						<HiOutlineMail fontSize="1rem" className="opacity-70 shrink-0 mt-0.5" /> {user?.name} sent a new message
					</div>

					<div className="text-xs font-me opacity-80">
						{when}
					</div>
				</div>
			</div>
		</Link>
	);
}
