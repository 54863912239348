import { ChangeEvent, ReactNode, useEffect, useState } from "react";

interface TimeAmountProps {
	value?: number;
	unit?: number;
	valueName: string;
	unitName: string;
	label?: ReactNode;
	onChange(key: string, newValue: number | undefined): void;
	inline?: boolean;
	mandatory?: boolean;
}

enum TimeUnits {
	MINUTE = 1, HOUR = 60, DAY = 1440
}

export default function TimeAmount(props: TimeAmountProps) {
	const [unit, setUnit] = useState<TimeUnits>(TimeUnits.HOUR);

	useEffect(() => {
		if (props.unit != null) {
			if (props.unit >= 1440) {
				setUnit(TimeUnits.DAY);
			} else if (props.unit >= 60) {
				setUnit(TimeUnits.HOUR);
			} else {
				setUnit(TimeUnits.MINUTE);
			}
		}
	}, [props.unit]);

	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if (event.target.value != null) {
			let valueMins: number | undefined = parseInt(event.target.value);

			if (isNaN(valueMins)) {
				valueMins = undefined;
			}

			props.onChange(props.valueName, valueMins);
		}
	}

	function changeUnit(valueString: string) {
		let newUnit: TimeUnits;
		
		const valueMins = parseInt(valueString);
		if (valueMins == 1) {
			newUnit = TimeUnits.MINUTE;
		} else if (valueMins == 60) {
			newUnit = TimeUnits.HOUR;
		} else {
			newUnit = TimeUnits.DAY;
		}

		props.onChange(props.unitName, newUnit);
	}

	const styles = props.inline ? "inline" : "Form_Component";

	return (
		<div className={styles}>
			<input
				value={props.value}
				className="p-2 w-14 h-[37px] border-gray-300 focus:border-blue-500 border rounded-l-md"
				type="text"
				inputMode="numeric"
				pattern="[0-9]*"
				min="1"
				step="1"
				onChange={handleChange}
			/>

			<select className="py-2 px-1 h-[37px] shadow-sm border-gray-300 focus:border-blue-500 border-r border-t border-b rounded-r-md" value={unit} onChange={(event) => { changeUnit(event.target.value); }} required>
				<option value={TimeUnits.MINUTE}>Minutes</option>
				<option value={TimeUnits.HOUR}>Hours</option>
				<option value={TimeUnits.DAY}>Days</option>
			</select>
		</div>
	);
}
