import { useState } from "react";
import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import CacheDropdown from "@/Components/FormComponents/CacheDropdown/CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { SimpleReport } from "@/Pages/Reporting/SimpleReport";
import { BarData } from "@/Pages/Reporting/BarCount";
import { useGetMissedSearchResultsReport } from "@/Api/genApi";

interface MissedSearchResultProps {
	dateRange: [DateTime, DateTime];
}

export function MissedSearchResult(props: MissedSearchResultProps) {
	const cache = useCache();
	const defaultKb = cache.Kbs.length > 0 ? cache.Kbs[0].id : undefined;

	const [kbId, setKbId] = useState<number | undefined>(defaultKb);

	const reportReq = useGetMissedSearchResultsReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate(), kbId: kbId ?? 0 });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	if (reportReq.data == undefined) {
		return null;
	}

	const data: BarData[] = [];

	for (const key in reportReq.data) {
		data.push({ label: key, data: reportReq.data[key] });
	}

	return (
		<>
			<div className="max-w-[300px]">
				<CacheDropdown
					dataname=""
					label="Knowledge base"
					mandatory
					cacheSlice={CacheSlices.Kbs}
					onChange={(_key, newValue) => {setKbId(newValue ?? undefined);}}
					value={kbId}
				/>
			</div>

			<SimpleReport
				title="Missed Search Results"
				subtitle="Shows knowledge base searches without results."
				axisTitles={{ xLabel: "Searches", yLabel: "Frequency" }}
				dataPreprocessed={data}
				dataXTransformer={() => ""}
				dateRange={props.dateRange}
			/>
		</>
	);
}
