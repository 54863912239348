import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTicketsPerUserReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TicketsPerUserProps {
	dateRange: [DateTime, DateTime];
}

export function TicketsPerUser(props: TicketsPerUserProps) {
	const cache = useCache();

	const reportReq = useGetTicketsPerUserReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getUser(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Tickets per User"
			subtitle="Details how many tickets your top 20 users have logged in the reporting period."
			axisTitles={{ xLabel: "User", yLabel: "Tickets" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
