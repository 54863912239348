import Tooltip from "@mui/material/Tooltip";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { CollisionNote } from "@/Api/genApi.schemas";
import { useCache } from "@/Hooks/useCache";
import { useAgent } from "@/Hooks/useAgent";
import OtherFunctions from "@/Helpers/OtherFunctions";

interface AgentStatusesProps {
	collisionNotes: CollisionNote[];
}

export function TicketCollisionNotes(props: AgentStatusesProps) {
	const cache = useCache();
	const loggedinAgent = useAgent();

	const filteredNotes = OtherFunctions.filterCollisionNotes(props.collisionNotes, loggedinAgent?.id);
	const visibleNotes = filteredNotes.slice(0, 3);
	const hiddenNotes = filteredNotes.slice(3);

	console.log("props.collisionNotes", props.collisionNotes);
	console.log("filteredNotes", filteredNotes);
	console.log("visibleNotes", visibleNotes);

	return (
		<div className="relative flex items-center gap-4">
			{visibleNotes.map(note => (
				<Tooltip key={note.agentId} title={`${cache.getAgent(note.agentId)?.name} is ${note.action.toLowerCase()} this ticket`}>
					<span className="relative flex items-center justify-center cursor-pointer">
						<AgentAvatar widthPx={28} agent={cache.getAgent(note.agentId)} />
					</span>
				</Tooltip>
			))}

			{hiddenNotes.length > 0 && (
				<Tooltip title={hiddenNotes.map(note => `${cache.getAgent(note.agentId)?.name} is ${note.action.toLowerCase()} this ticket`).join(", ")}>
					<span className="flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full cursor-pointer">
						+{hiddenNotes.length}
					</span>
				</Tooltip>
			)}
		</div>
	);
}
