import { useCache } from "@/Hooks/useCache";
import EntityTable from "../EntityTable";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";
import { SettingInt } from "@shared/Enums/SettingEnums";

interface ChannelsEmailTableProps {
	view?: View;
}

export function ChannelsEmailTable(props: ChannelsEmailTableProps) {
	const cache = useCache();

	let entityLimit = undefined;
	const channelLimit = cache.getSettingInt(SettingInt.LIMITS_CHANNELS);

	if (channelLimit != null) {
		entityLimit = {
			limit: channelLimit,
			errorMsg: "Max channel count (" + channelLimit + ") exceeded. Contact support to add more channels."
		};
	}

	return (
		<EntityTable
			entity={Entities.CHANNELSEMAIL}
			view={props.view}
			entityLimit={entityLimit}
			newEntityUrl="/config/manage/channels/email/-1"
			editEntityUrl="/config/manage/channels/email/"
			multiDeleteWarningMsg="This will permanently delete these channels."
		/>
	);
}
