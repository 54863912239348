import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import { IoTicketOutline } from "react-icons/io5";
import { AssignedToMeNotification } from "@/Api/genApi.schemas";
import { useCache } from "@/Hooks/useCache";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface AssignedToMeContentProps {
	notification: AssignedToMeNotification;
	closeNotifications(): void;
}

export function AssignedToMeContent(props: AssignedToMeContentProps) {
	const cache = useCache();

	const agent = cache.getAgent(props.notification.userId);

	const ctime = DateTime.fromISO(props.notification.ctime);
	const when = datetimeToWhenString(ctime);

	return (
		<Link to={"/tickets/" + props.notification.ticketId} onClick={props.closeNotifications}>
			<div className="flex gap-3 hover:underline">
				<AgentAvatar
					widthPx={40}
					agent={agent}
					hideAgentStatus
				/>

				<div>
					<div className="pt-1 pb-1.5 flex items-start gap-1">
						<IoTicketOutline fontSize="1.1rem" className="opacity-70 shrink-0 mt-0.5" /> {agent?.name} assigned a ticket to you
					</div>

					<div className="text-xs font-me opacity-80">
						{when}
					</div>
				</div>
			</div>
		</Link>
	);
}
