import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Alert } from "@mui/material";
import toast from "react-hot-toast";
import { Button, ButtonBlue } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import { useMoveAllTickets } from "@/Api/genApi";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";

interface MoveTicketsForm {
	toTeamId?: number;
}

interface MoveTicketsModalProps {
	isOpen: boolean;
	onCancel(): void;
	onComplete(): void;
	fromTeamIds: number[];
}

export function MoveTicketsModal(props: MoveTicketsModalProps) {
	const cache = useCache();
	const moveAllTicketsReq = useMoveAllTickets();

	const { control, handleSubmit, reset } = useForm<MoveTicketsForm>();

	useEffect(() => {
		reset();
	}, [props.fromTeamIds, props.isOpen, reset]);

	async function onSubmit(data: MoveTicketsForm) {
		if (data.toTeamId == null) {
			toast.error("You must select a team to move the tickets to.");
			return;
		}

		let didError = false;

		for (let i = 0; i < props.fromTeamIds.length; i++) {
			await moveAllTicketsReq.mutateAsync({ id: props.fromTeamIds[i], data: { targetTeamId: data.toTeamId } }, {
				onError: () => {
					toast.error("Could not move tickets. Please contact support.");
					didError = true;
				}
			});
		}

		if (!didError) {
			props.onComplete();
		}
	}

	const availableTeams = cache.Teams.filter(team => !props.fromTeamIds.includes(team.id));

	const teamOptions = availableTeams.map(team => {
		return {
			label: team.name,
			value: team.id
		};
	});

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onCancel}
			title="Move tickets"
		>
			<ModalContent>
				<div className="w-96">
					You are about to delete a team that has tickets.<br /><br />
					Please select a team from the dropdown below to move the tickets to.
					<br /><br />

					<Alert severity="info">
						A ticket must always be assigned to a team.
					</Alert>
				</div>

				<W_Dropdown<number>
					control={control}
					options={teamOptions}
					dataname="toTeamId"
					label="Destination team"
					mandatory
				/>
			</ModalContent>
			<ModalButtons>
				<Button onClick={props.onCancel} label="Cancel" />
				<ButtonBlue onClick={() => handleSubmit(onSubmit)()} label="Move tickets" loading={moveAllTicketsReq.isPending} />
			</ModalButtons>
		</Modal>

	);
}
