import { Link } from "react-router-dom";
import { HiOutlineMail } from "react-icons/hi";
import { MdOutlinePhone } from "react-icons/md";
import { useCache } from "@/Hooks/useCache";
import { Agent, Company, KbArticle, Ticket, User } from "@/Api/genApi.schemas";
import { AgentAvatar } from "../../Components/Avatar/AgentAvatar";
import { UserAvatar } from "../../Components/Avatar/UserAvatar";
import { CompanyAvatar } from "../../Components/Avatar/CompanyAvatar";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";

interface SearchResultAgentProps {
	agent: Agent;
}

export function SearchResultAgent(props: SearchResultAgentProps) {
	return (
		<Link to={"/config/manage/agents/" + props.agent.id}>
			<div className="p-3 bg-white border-b flex items-center gap-3">
				<div className="inline-flex gap-2 items-center">
					<AgentAvatar agent={props.agent} widthPx={30} />
					{props.agent.name}
				</div>

				{props.agent.email != null &&
					<>
						<span>&bull;</span>
						<div className="flex items-center gap-1">
							<HiOutlineMail /> {props.agent.email}
						</div>
					</>}

				{props.agent.phoneNumber != null &&
					<>
						<span>&bull;</span>
						<div className="flex items-center gap-1">
							<MdOutlinePhone /> {props.agent.phoneNumber}
						</div>
					</>}
			</div>
		</Link>
	);
}

interface SearchResultCompanyProps {
	company: Company;
}

export function SearchResultCompany(props: SearchResultCompanyProps) {
	return (
		<Link to={"/companies/" + props.company.id}>
			<div className="p-3 bg-white border-b">
				<div className="inline-flex gap-2 items-center">
					<CompanyAvatar company={props.company} widthPx={30} />
					{props.company.name}
				</div>
			</div>
		</Link>
	);
}

interface SearchResultTicketProps {
	ticket: Ticket;
}

export function SearchResultTicket(props: SearchResultTicketProps) {
	const cache = useCache();

	const priority = cache.getPriority(props.ticket.priorityId);
	const user = cache.getUser(props.ticket.userId);
	const dateString = datetimeToWhenString(props.ticket.customCreated);

	return (
		<Link to={"/tickets/" + props.ticket.id}>
			<div className="p-3 bg-white border-b">
				<span className="text-gray-600"># {props.ticket.id}</span> - {props.ticket.description}

				<div className="flex items-center gap-3 mt-2">
					{user &&
						<div className="inline-flex gap-2 items-center">
							<UserAvatar user={user} widthPx={30} />
							{user.name}
						</div>}

					{dateString != "" &&
						<>
							<span>&bull;</span>
							<span>Opened: {dateString}</span>
						</>}

					{priority?.name != null &&
						<>
							<span>&bull;</span>
							<span>Priority: {priority?.name}</span>
						</>}
				</div>
			</div>
		</Link>
	);
}

interface SearchResultUserProps {
	user: User;
}

export function SearchResultUser(props: SearchResultUserProps) {
	return (
		<Link to={"/users/" + props.user.id}>
			<div className="p-3 bg-white border-b flex items-center gap-3">
				<div className="inline-flex gap-2 items-center">
					<UserAvatar user={props.user} widthPx={30} />
					{props.user.name}
				</div>

				{props.user.email != null &&
					<>
						<span>&bull;</span>
						<div className="flex items-center gap-1">
							<HiOutlineMail /> {props.user.email}
						</div>
					</>}

				{props.user.phoneNumber != null &&
					<>
						<span>&bull;</span>
						<div className="flex items-center gap-1">
							<MdOutlinePhone /> {props.user.phoneNumber}
						</div>
					</>}
			</div>
		</Link>
	);
}

interface SearchResultArticleProps {
	article: KbArticle;
}

export function SearchResultArticle(props: SearchResultArticleProps) {
	const cache = useCache();
	const folder = cache.getKbFolder(props.article.kbSectionId);

	const articleContent = props.article.articleContent != null && props.article.articleContent.length > 0 ? props.article.articleContent[0] : null;

	return (
		<Link to={"/kb/article/" + props.article.id}>
			<div className="p-3 bg-white border-b">
				{articleContent?.title} &bull; Folder: {folder?.name}
			</div>
		</Link>
	);
}
