import { useMemo } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { ColDef } from "@ag-grid-community/core";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { generateColumnDefs } from "@/Components/EntityTable/EntityTableFunctions";
import { useCache } from "@/Hooks/useCache";
import { useAppDispatch } from "@/Store/hooks";
import { getDummyData } from "@/Components/Layouts/ReadOnlyTable/dummyData";
import { Entities } from "@shared/Entities/Entities";

interface ReadOnlyLayoutTableProps {
	columnIds: string[];
}

export default function ReadOnlyLayoutTable(props: ReadOnlyLayoutTableProps) {
	const cache = useCache();
	const dispatch = useAppDispatch();

	const columnDefs: ColDef[] = generateColumnDefs(Entities.TICKET, cache, dispatch, props.columnIds);

	const defaultColDef: ColDef = {
		resizable: true,
		sortable: true,
		suppressMovable: true,
		flex: 1,
		minWidth: 60,
		comparator: () => 0, // Disables the effects of client side sorting but keeps the visuals.
	};

	const dummyData = useMemo(() => getDummyData(cache), [cache]);

	return (
		<div className="ag-theme-alpine read-only-table" style={{ height: "100%", width: "100%" }}>
			<AgGridReact
				columnDefs={columnDefs}
				defaultColDef={defaultColDef}
				rowData={dummyData}
				animateRows={true}
			/>
		</div>
	);
}
