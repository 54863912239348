import { useMemo } from "react";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useCache } from "@/Hooks/useCache";
import { CacheFunctions } from "../../../Helpers/CacheFunctions";
import { GD_SUPPORT_AGENT_ID } from "@shared/Enums/Enums";
import Dropdown, { DropdownOption, DropdownProps } from "@shared/Components/FormComponents/Dropdown/Dropdown";
import { alphabeticalSort } from "@shared/Helpers/SortFunctions";

export interface CacheDropdownProps extends Omit<DropdownProps<number>, "options"> {
	cacheSlice: CacheSlices;
}

/**
 * A dropdown that returns results from the selected cacheSlice.
 */
export default function CacheDropdown(props: CacheDropdownProps) {
	const cache = useCache();

	const optionz: DropdownOption<number>[] = useMemo(() => {
		// Select the correct cacheSlice.
		const cacheToDisplay = CacheFunctions.getCacheSlice(cache, props.cacheSlice);
		const labelProperty = "name";

		// Create options array.
		const options: DropdownOption<number>[] = [];

		// If cacheSlice is empty.(+ Special case for companies).
		if (cacheToDisplay == null || cacheToDisplay.length == 0 || (props.cacheSlice == CacheSlices.Companies && cacheToDisplay.length == 1)) {
			// Dummy value so users don't get confused.
			const pluralName = CacheFunctions.getCacheSlicePluralName(props.cacheSlice);
			options.push({ value: undefined, label: "No " + pluralName + " found." });
		} else {
			if (props.mandatory != true) {
				options.push({ value: undefined, label: "--" });
			}

			if (cacheToDisplay != null) {
				cacheToDisplay.forEach((element: any) => {
					if (props.cacheSlice == CacheSlices.Companies && element.id == -1) {
						// Single users special company. Do not include in the list.
					} else {
						if (props.cacheSlice == CacheSlices.Agents || props.cacheSlice == CacheSlices.Users) {
							// Check it's not disabled or deleted.
							if (!element.disabled && !element.deleted) {
								if ((props.cacheSlice == CacheSlices.Agents || props.cacheSlice == CacheSlices.Users) && element.id == GD_SUPPORT_AGENT_ID) {
									// Ignore the GoDesk support agent.
								} else {
									options.push({ value: element.id, label: element[labelProperty] });
								}
							}
						} else {
							options.push({ value: element.id, label: element[labelProperty] });
						}
					}
				});
			}
		}

		options.sort((a, b) => {
			if (typeof a.label == "string" && typeof b.label == "string") {
				return alphabeticalSort(a.label, b.label);
			} else {
				return 0;
			}
		});

		return options;
	}, [cache, props.cacheSlice, props.mandatory]);

	return (
		<Dropdown<number>
			{...props}
			value={props.value}
			dataname={props.dataname}
			onChange={props.onChange}
			options={optionz}
		/>
	);
}
