
import { IoFilterOutline } from "react-icons/io5";
import { useCache } from "@/Hooks/useCache";
import { SelectLayoutButton } from "@/Components/Layouts/SelectLayoutButton/SelectLayoutButton";
import { TicketTable } from "../../Components/EntityTable/Tables/TicketTable";
import { TicketsViewList } from "./ViewList/TicketsViewList";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { customFilterOpened, customFilterPanelCleared, layoutApplied, pageChanged, ticketsSortCleared, ticketsSortUpdated } from "../../Store/Reducers/ticketsPageSlice";
import { Button } from "../../Components/Button/Button";
import { TableSort } from "@shared/Enums/Enums";
import { Hotkeys } from "@shared/lib/Hotkeys";

export default function Tickets() {
	document.title = "Tickets | GoDesk";

	const dispatch = useAppDispatch();
	const cache = useCache();

	const customFilters = useAppSelector(state => state.ticketsPage.customFilters);
	const customFiltersDirty = useAppSelector(state => state.ticketsPage.customFiltersDirty);
	const stateViewId = useAppSelector(state => state.ticketsPage.viewId);
	const page = useAppSelector(state => state.ticketsPage.page);
	const sort = useAppSelector(state => state.ticketsPage.sort);
	const layout = useAppSelector(state => state.ticketsPage.layout);

	let viewId = undefined;
	let view = undefined;

	if (customFilters != null) {
		view = {
			filters: customFilters
		};
	} else {
		viewId = stateViewId;
	}

	function onPageChange(newPage: number) {
		dispatch(pageChanged(newPage));
	}

	function onSortChange(newSort?: TableSort) {
		if (newSort != null) {
			dispatch(ticketsSortUpdated(newSort));
		} else {
			dispatch(ticketsSortCleared());
		}
	}

	function toggleFilters() {
		if (customFilters == null) {
			// Get current view's filters.
			const viewFilters = cache.getView(stateViewId)?.filters ?? {};

			// Open filters
			dispatch(customFilterOpened(viewFilters));
		} else {
			// Clear filters + custom view.
			dispatch(customFilterPanelCleared());
		}
	}

	const showFiltersPanel = customFilters != null;

	return (
		<div className="flex" style={{ height: "100%" }}>
			<Hotkeys hotkeys="f" callback={toggleFilters} />

			<TicketsViewList />

			<div className="h-full w-full flex flex-col overflow-x-hidden">
				<div className="flex justify-between items-end border-b border-gray-200  mx-3 py-3" >
					<div className="ml-1 text-2xl font-medium text-gray-600 flex items-end leading-6">
						{cache.getView(stateViewId)?.name}
						{customFiltersDirty && " (Custom)"}
					</div>
					<div className="flex gap-3">
						<SelectLayoutButton
							entity="TICKET"
							onSelected={layout => {
								console.log("beep");
								dispatch(layoutApplied(layout));
							}}
							activeLayout={layout}
						/>

						<Button
							icon={<IoFilterOutline />}
							label="Filter"
							tooltip="Filter (F)"
							active={showFiltersPanel}
							onClick={toggleFilters}
						/>
					</div>
				</div>

				<TicketTable
					viewId={viewId}
					view={view}
					showFiltersPanel={showFiltersPanel}
					page={page}
					onPageChange={onPageChange}
					sort={sort}
					onSortChange={onSortChange}
					layout={layout}
				/>
			</div>
		</div>
	);
}
