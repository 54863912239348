import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import toast from "react-hot-toast";
import { RefreshCache } from "@/legacy/ApiCallerOld";
import { CompanyDetailsPane } from "@/Pages/Companies/CompanyViewer/CompanyDetailsPane";
import { UserTable } from "@/Components/EntityTable/Tables/UserTable";
import { TicketTable } from "@/Components/EntityTable/Tables/TicketTable";
import { useAppDispatch } from "@/Store/hooks";
import api from "@/Api/Api";
import DeleteWarningModal from "@/Components/Utility/Modals/DeleteWarningModal";
import { useAgent } from "@/Hooks/useAgent";
import { NotesTab } from "@/Pages/Companies/CompanyViewer/CompanyViewerTabs/NotesTab";
import { renderCustomField } from "@/Components/CustomFields";
import { useCache } from "@/Hooks/useCache";
import { Company, User } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";
import { View } from "@shared/Models/View";
import "react-tabs/style/react-tabs.scss";
import { CustomFieldEntity } from "@shared/Enums/Enums";

interface CompanyViewerProps {
	id: number;
	onNameChange(): Promise<void>;
}

export function CompanyViewer(props: CompanyViewerProps) {
	const agent = useAgent();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const cache = useCache();

	const [deleteCompanyModalOpen, setDeleteCompanyModalOpen] = useState(false);

	const [company, setCompany] = useState<Company | null>();
	const [tabIndex, setTabIndex] = useState(0);

	const customFields = cache.CustomFields.filter(cf => cf.entity == CustomFieldEntity.COMPANY);

	useEffect(() => {
		setTabIndex(0);
		doFetch();

		async function doFetch() {
			// TODO: Set undefined?

			const response = await api.getEntity<Company>(Entities.COMPANY, props.id);

			if (response.successful && response.data != null) {
				setCompany(response.data);
			} else {
				setCompany(null);
			}
		}
	}, [props.id]);

	async function handleSubmitCF(key: string, newValue: string | undefined) {
		const dataCopy = Object.assign({}, company);

		dataCopy.customValues = { ...dataCopy.customValues };

		if (newValue == undefined) { // Remove
			delete dataCopy.customValues[key];
		} else { // Update
			dataCopy.customValues[key] = newValue;
		}

		if (props.id != -1) {
			const req = await api.updateEntity<Company>(Entities.COMPANY, props.id, dataCopy);

			if (req.successful && req.data != null) {
				setCompany(req.data);

				if (company?.name != dataCopy?.name) {
					props.onNameChange();
				}
			} else {
				toast.error("Could not update company.");
			}
		}

		props.onNameChange();
	}

	async function deleteCompany() {
		setDeleteCompanyModalOpen(false);

		if (company != null) {
			const res = await api.deleteEntity(Entities.COMPANY, company.id);

			if (!res.successful) {
				toast.error("Could not delete company.");
			}

			navigate("/companies");
			const toastMsg = "Company '" + company.name + "' deleted.";
			toast.success(toastMsg);

			await props.onNameChange();
			await RefreshCache(dispatch);
		}
	}

	const userView: View = {
		filters: {
			companyId: { type: "number", comparison: "equals", value: props.id },

		}
	};

	const ticketView: View = {
		filters: {
			companyId: { type: "number", comparison: "equals", value: props.id },
			deleted: { type: "bool", comparison: "equals", value: false },
		}
	};

	if (agent != null && !agent.isAdmin && agent.teams != null) {
		const teamsToInclude = agent.teams.map(agentTeams => agentTeams.teamId);
		teamsToInclude.push(-1); // No team.

		ticketView.filters.teamId = { type: "set", comparison: "contains", values: teamsToInclude };
	}

	const defaultUser: User = {
		id: -1,
		companyId: props.id
	};

	return (
		<div className="h-full p-2">
			<div className="flex flex-col h-full">

				<div className="p-2">
					<CompanyDetailsPane companyId={props.id} deleteCompany={() => setDeleteCompanyModalOpen(true)} refreshCompanyList={props.onNameChange} refreshCache={() => RefreshCache(dispatch)} />
				</div>
				<div className="grow">
					<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} selectedTabPanelClassName="react-tabs__tab-panel--selected h-full" className="h-full flex flex-col" >
						<div className="p-2">
							<TabList>
								<Tab>Users</Tab>
								<Tab>Tickets</Tab>
								<Tab>Notes</Tab>

								{customFields.length > 0 &&
									<Tab>Custom fields</Tab>
								}
							</TabList>
						</div>

						<TabPanel>
							<UserTable
								view={userView}
								defaultNewData={defaultUser}
							/>
						</TabPanel>

						<TabPanel >
							<TicketTable
								view={ticketView}
								sort={{
									sortField: "customCreated",
									sortType: "desc"
								}}
							/>
						</TabPanel>

						<TabPanel >
							{company != null &&
								<NotesTab companyId={company.id} />
							}
						</TabPanel>

						{customFields.length > 0 &&
							<TabPanel>
								<div className="w-[300px] flex flex-col gap-y-3 px-2">
									{customFields.map(customField => {
										const value = company?.customValues != null ? company.customValues[customField.name] : undefined;

										return (
											<div key={customField.id}>
												{renderCustomField(customField, handleSubmitCF, value)}
											</div>
										);
									})}
								</div>
							</TabPanel>
						}
					</Tabs>
				</div>

			</div>

			<DeleteWarningModal
				isOpen={deleteCompanyModalOpen}
				onDelete={deleteCompany}
				onCancel={() => setDeleteCompanyModalOpen(false)}
				message="This will delete all of this company's users as well."
			/>

		</div>
	);
}
