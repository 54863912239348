import toast from "react-hot-toast";
import api from "@/Api/Api";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";

export class EmailFunctions {
	static async imapTest(channel: ChannelsEmail) {
		const toastId = toast.loading("Testing IMAP...");

		const res = await api.BasicPost<string>("/channels_email/test_imap", channel);

		if (res.data == "VALID") {
			toast.success("IMAP Credentials are valid.", { id: toastId, duration: 5000 });
			return true;
		} else {
			// Normal channel error msgs will come in through res.data. Fallback to res.errorMsg incase there is a timeout.
			toast.error(res.data ?? res.errorMsg ?? "Network error.", { id: toastId });
			return false;
		}
	}

	static async smtpTest(channel: ChannelsEmail) {
		const toastId = toast.loading("Testing SMTP...");

		const res = await api.BasicPost<string>("/channels_email/test_smtp", channel);

		if (res.data == "VALID") {
			toast.success("SMTP Credentials are valid.", { id: toastId, duration: 5000 });
			return true;
		} else {
			// Normal channel error msgs will come in through res.data. Fallback to res.errorMsg incase there is a timeout.
			toast.error(res.data ?? res.errorMsg ?? "Network error.", { id: toastId });
			return false;
		}
	}
}
