import { FaEllipsisV, FaEdit, FaTrash } from "react-icons/fa";
import toast from "react-hot-toast";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { IconButton, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { Layout } from "@/Api/genApi.schemas";
import { useDeleteLayouts } from "@/Api/genApi";
import { useAppDispatch } from "@/Store/hooks";
import { RefreshCache } from "@/legacy/ApiCallerOld";

interface LayoutListItemProps {
	layout: Layout;
	onSelect(layout: Layout): void;
	editLayout(): void;
	isActive?: boolean;
	disableContextMenu?: boolean;
}

export function LayoutListItem(props: LayoutListItemProps) {
	const deleteLayouts = useDeleteLayouts();
	const dispatch = useAppDispatch();

	async function deleteLayout() {
		if (props.layout.id != null) {
			deleteLayouts.mutate(
				{ id: props.layout.id },
				{
					onSuccess: async () => {
						await RefreshCache(dispatch);
						toast.success("Layout deleted.");
					},
					onError: (error) => {
						toast.error("Could not delete layout: " + error.errorMsg);
					}
				}
			);
		}
	}

	const activeStyles = props.isActive ? "bg-blue-50 font-bold" : "";

	return (
		<div className={"flex items-center cursor-pointer hover:bg-blue-100 active:bg-blue-200 border-b py-2 px-4 " + activeStyles} >
			<div
				className="text-sm grow"
				onClick={() => {
					props.onSelect(props.layout);
				}}
			>
				{props.layout.name}
			</div>
			{!props.disableContextMenu &&
				<ContextMenu
					onEdit={props.editLayout}
					onRemove={deleteLayout}
				/>}
		</div>
	);
}

interface ContextMenuProps {
	onEdit(): void;
	onRemove(): void;
}

function ContextMenu(props: ContextMenuProps) {
	const popupState = usePopupState({ variant: "popover" });

	return (
		<>
			<IconButton
				{...bindTrigger(popupState)}
			>
				<FaEllipsisV fontSize="1rem" />
			</IconButton>

			<Menu {...bindMenu(popupState)}>
				<MenuItem
					onClick={() => {
						popupState.close();
						props.onEdit();
					}}
				>
					<ListItemIcon>
						<FaEdit />
					</ListItemIcon>

					Edit
				</MenuItem>

				<MenuItem
					onClick={() => {
						popupState.close();
						props.onRemove();
					}}
				>
					<ListItemIcon>
						<FaTrash />
					</ListItemIcon>

					Remove
				</MenuItem>
			</Menu>
		</>
	);
}
