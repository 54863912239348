import { useCache } from "@/Hooks/useCache";
import GenericListPage from "./_GenericListPage";
import { Entities } from "@shared/Entities/Entities";
import { SettingInt } from "@shared/Enums/SettingEnums";

export default function ManageAgents() {
	const cache = useCache();

	const agentLimit = cache.getSettingInt(SettingInt.LIMITS_AGENTS);
	const title = "Agents " + (agentLimit != null ? " (Limit: " + agentLimit + ")" : "");

	return (
		<GenericListPage
			urlTitle="Agents | Config"
			title={title}
			description="Agents are the people who use GoDesk to answer user's queries."
			entityType={Entities.AGENT}
		/>
	);
}
