import toast from "react-hot-toast";
import { ApiUpdateEntity, RefreshCache } from "@/legacy/ApiCallerOld";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import { useAgent } from "@/Hooks/useAgent";
import api from "@/Api/Api";
import MultiEntitySelect from "../../FormComponents/MultiEntitySelect/MultiEntitySelect";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { customFilterCleared, customFilterPanelCleared, customViewCleared, customViewUpdated, viewChanged } from "../../../Store/Reducers/ticketsPageSlice";
import { Button } from "../../Button/Button";
import { EmojiKeyboard } from "@shared/Components/FormComponents/EmojiKeyboard/EmojiKeyboard";
import { ViewVisibility } from "@shared/Enums/Enums";
import Dropdown, { DropdownOption } from "@shared/Components/FormComponents/Dropdown/Dropdown";
import { View } from "@shared/Models/View";
import TextInput from "@shared/Components/FormComponents/TextInput/TextInput";
import { Entities } from "@shared/Entities/Entities";

interface CustomViewPanelProps {
	customView: View;
}

export function CustomViewPanel(props: CustomViewPanelProps) {
	const dispatch = useAppDispatch();
	const agent = useAgent();

	const filters = useAppSelector(state => state.ticketsPage.customFilters);

	function updateNewCustomViewName(newValue: string) {
		const newView: View = { ...props.customView };
		newView.name = newValue;

		dispatch(customViewUpdated(newView));
	}

	function updateNewIcon(newValue: string) {
		const newView: View = { ...props.customView };
		newView.icon = newValue;

		dispatch(customViewUpdated(newView));
	}

	function updateVisibility(_key: string, newValue: ViewVisibility) {
		const newView: View = { ...props.customView };
		newView.visibility = newValue;

		if (newValue != ViewVisibility.TEAMS) {
			newView.visibilityTeamsIds = [];
		}

		if (newValue != ViewVisibility.AGENTS) {
			newView.visibilityAgentsIds = [];
		}

		dispatch(customViewUpdated(newView));
	}

	function updateAgentVisibility(_key: string, newIds?: number[]) {
		if (newIds != null) {
			const newView: View = { ...props.customView };
			newView.visibilityAgentsIds = newIds;

			dispatch(customViewUpdated(newView));
		}
	}

	function updateTeamVisibility(_key: string, newIds?: number[]) {
		if (newIds != null) {
			const newView: View = { ...props.customView };
			newView.visibilityTeamsIds = newIds;

			dispatch(customViewUpdated(newView));
		}
	}

	async function postView() {
		if (filters != null) {
			if (props.customView.name == null || props.customView.name == "") {
				toast.error("View name must not be empty.");
				return;
			}

			const customViewCopy = { ...props.customView };

			customViewCopy.filters = filters;
			customViewCopy.creatorAgentId = agent?.id;

			const createRes = await api.createEntity<View>(Entities.VIEW, customViewCopy);

			if (createRes.successful && createRes.data != null) {
				dispatch(customFilterPanelCleared());
				dispatch(viewChanged(createRes.data));

				await RefreshCache(dispatch);
			} else {
				toast.error("Error. " + createRes.errorCode + ": " + createRes.errorMsg);
			}
		}
	}

	async function updateView() {
		if (props.customView.id != null && filters != null) {
			const customViewCopy = { ...props.customView };

			customViewCopy.filters = filters;

			if (customViewCopy.id == undefined || typeof customViewCopy.id == "string") {
				return;
			}

			const createRes = await ApiUpdateEntity(Entities.VIEW, customViewCopy.id, customViewCopy);

			if (createRes.successful) {
				dispatch(customViewCleared());
				dispatch(customFilterCleared());

				await RefreshCache(dispatch);
			} else {
				toast.error("Error. " + createRes.errorCode + ": " + createRes.errorMsg);
			}
		}
	}

	const visibilityOptions: DropdownOption<ViewVisibility>[] = [
		{ label: "Selected teams", value: ViewVisibility.TEAMS },
		{ label: "Selected agents", value: ViewVisibility.AGENTS },
	];

	if (agent?.id == props.customView?.creatorAgentId) {
		visibilityOptions.push({ label: "Just me", value: ViewVisibility.PRIVATE });
	}

	// Don't let non-admins add 'everyone' views.
	if (agent?.isAdmin) {
		visibilityOptions.push({ label: "Everyone", value: ViewVisibility.GLOBAL });
	} else {
		visibilityOptions.push({
			label: "Everyone (only available to admins)",
			value: ViewVisibility.GLOBAL,
			isDisabled: true
		});
	}

	return (
		<div className="px-7 py-5 border-t">

			<div className="flex gap-2">
				<EmojiKeyboard
					label="Icon"
					updateValue={updateNewIcon}
					value={props.customView.icon}
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
				/>
				<TextInput
					mandatory
					dataname="name"
					label="Name"
					value={props.customView.name}
					onChange={updateNewCustomViewName}
				/>
			</div>

			<div className="pb-5">
				<Dropdown<ViewVisibility>
					dataname="visibility"
					label="Visibility"
					value={props.customView.visibility ?? ViewVisibility.PRIVATE}
					options={visibilityOptions}
					onChange={updateVisibility}
				/>

				{props.customView.visibility == ViewVisibility.AGENTS &&
					<MultiEntitySelect
						dataname="AgentId"
						label="Agents"
						mandatory
						cacheSlice={CacheSlices.Agents}
						value={props.customView.visibilityAgentsIds}
						handleSubmit={updateAgentVisibility}
					/>}

				{props.customView.visibility == ViewVisibility.TEAMS &&
					<MultiEntitySelect
						dataname="TeamId"
						label="Teams"
						mandatory
						cacheSlice={CacheSlices.Teams}
						value={props.customView.visibilityTeamsIds}
						handleSubmit={updateTeamVisibility}
					/>}
			</div>

			<div className="flex gap-4">
				<Button className="grow justify-center" label="Cancel" btnClass="btn-grey" onClick={() => dispatch(customViewCleared())} />

				{props.customView.id == undefined ?
					<Button className="grow justify-center" label="Create view" btnClass="btn-blue" onClick={postView} />
					:
					<Button className="grow justify-center" label="Update view" btnClass="btn-blue" onClick={updateView} />}
			</div>
		</div>
	);
}
