import { DateTime } from "luxon";
import { Alert } from "@mui/material";
import { useCache } from "@/Hooks/useCache";
import { useGetTicketsByCategoryReport } from "@/Api/genApi";
import { SimpleReport } from "../SimpleReport";

interface TicketsByCategoryProps {
	dateRange: [DateTime, DateTime];
}

export function TicketsByCategory(props: TicketsByCategoryProps) {
	const cache = useCache();

	const reportReq = useGetTicketsByCategoryReport({ dateFrom: props.dateRange[0].toISODate(), dateTo: props.dateRange[1].toISODate() });

	if (reportReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	function dataXTransformer(data: number): string {
		return cache.getCategory(data)?.name ?? "Unknown";
	}

	return (
		<SimpleReport
			title="Tickets by Category"
			subtitle="Shows tickets in the reporting period split by category."
			axisTitles={{ xLabel: "Category", yLabel: "Tickets" }}
			data={reportReq.data}
			dataXTransformer={dataXTransformer}
			dateRange={props.dateRange}
		/>
	);
}
