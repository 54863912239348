import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Avatar from "@/Components/Avatar/Avatar";
import { UserAvatar } from "@/Components/Avatar/UserAvatar";
import { AgentAvatar } from "@/Components/Avatar/AgentAvatar";
import { useCache } from "@/Hooks/useCache";
import { FeedItem } from "@/Api/genApi.schemas";
import { datetimeToWhenString } from "@shared/Helpers/DateFunctions";
import { removeAllHtml } from "@shared/Helpers/HtmlFunctions";

interface FeedItemViewerProps {
	feedItem: FeedItem;
}

export function FeedItemViewer(props: FeedItemViewerProps) {
	const cache = useCache();

	const user = cache.getUser(props.feedItem.userId);
	const agent = cache.getAgent(props.feedItem.agentId);

	const noHtmlNote = removeAllHtml(props.feedItem.noteBody?.trim());

	let feedAvatar;

	if (user != null) {
		feedAvatar = <Link to={"/users/" + props.feedItem.userId}>
			<UserAvatar user={user} widthPx={30} />
		</Link>;
	} else if (agent != null) {
		feedAvatar = <Link to={"/config/manage/agents/" + props.feedItem.agentId}>
			<AgentAvatar agent={agent} widthPx={30} />
		</Link>;
	} else {
		feedAvatar = <Avatar widthPx={30} avatarHashString={"System_" + props.feedItem.id} />;
	}

	return (
		<div className="flex p-2 border rounded border-gray-300 bg-white mb-2 shadow-sm break-all">
			<div className="mr-2">
				{feedAvatar}
			</div>
			<Link to={"/tickets/" + props.feedItem.ticketId}>
				<div>
					<div className="font-semibold line-clamp-1">
						# {props.feedItem.ticketId} - {props.feedItem.description}
					</div>
					<div className="my-2 line-clamp-2">
						{noHtmlNote}
					</div>
					<div className="text-gray-500">{datetimeToWhenString(props.feedItem.customCreated)}</div>
				</div>
			</Link>
		</div>
	);
}

export function FeedItemViewerSkeleton() {
	return (
		<div className="flex p-2 border rounded border-gray-300 bg-white mb-2 shadow-sm break-all">
			<div className="mr-2">
				<Skeleton variant="circular" width={30} height={30} />
			</div>
			<div className="grow">
				<div className="font-semibold line-clamp-1">
					<Skeleton variant="text" sx={{ fontSize: "1rem" }} />
				</div>
				<div className="my-2 line-clamp-2">
					<Skeleton variant="rectangular" height={50} />
				</div>
				<Skeleton variant="rectangular" height="1rem" width={100} />
			</div>
		</div>
	);
}
