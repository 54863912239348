import { useState } from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { GrChapterAdd } from "react-icons/gr";
import { PiRobotFill } from "react-icons/pi";
import { Menu, MenuItem } from "@mui/material";
import { usePopupState, bindTrigger, bindMenu } from "material-ui-popup-state/hooks";
import NewTicketModal from "@/Components/Utility/Modals/NewTicketModal/NewTicketModal";
import KbDraftArticleModal from "@/Components/Utility/Modals/KbDraftArticleModal";
import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import { Action, Ticket } from "@shared/Entities/EntityTypes";
import { SettingBool } from "@shared/Enums/SettingEnums";

interface ActionContextMenuProps {
	ticket: Ticket;
	action: Action;
}

/**
 * Hard-coded context menu for ticket actions.
 * In the future make this a context menu component with context item children with callbacks.
 */
export default function ActionContextMenu(props: ActionContextMenuProps) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [gptModalOpen, setGptModalOpen] = useState(false);

	const popupState = usePopupState({ variant: "popover" });

	const cache = useCache();

	const actionForNewTicket: Action = { ...props.action, id: 0 };

	return (
		<>
			<NewTicketModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				preventSendAsEmail
				defaultNewTicket={{
					id: 0,
					actions: [actionForNewTicket],
					userId: props.ticket.userId,
					teamId: props.ticket.teamId,
					agentId: props.ticket.agentId
				}}
			/>

			{gptModalOpen &&
				<KbDraftArticleModal
					onClose={() => setGptModalOpen(false)}
					action={props.action}
				/>}

			<div className="flex flex-row gap-2">
				{cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) && props.action.agentId != null &&
					<Button icon={<PiRobotFill />} label="Draft KB article" onClick={() => setGptModalOpen(true)} tooltip="Use AI to draft a KB article from this message." />
				}

				<div {...bindTrigger(popupState)}>
					<Button icon={ <BiDotsHorizontalRounded />} />
				</div>

				<Menu
					{...bindMenu(popupState)}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
				>
					<MenuItem
						onClick={() => {
							popupState.close();
							setIsModalOpen(true);
						}}
					>
						<GrChapterAdd className="mr-1" /> Copy to new ticket
					</MenuItem>
				</Menu>
			</div>

		</>
	);
}
