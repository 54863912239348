import { useState } from "react";
import { Link } from "react-router-dom";
import { Button as MuiButton } from "@mui/material";
import ContactSupportModal from "@/Components/Utility/Modals/ContactSupportModal";
import { useAgent } from "@/Hooks/useAgent";
import { Button } from "@/Components/Button/Button";
import { useCache } from "@/Hooks/useCache";
import { NewFeatureBanner } from "@/Pages/home/NewFeatureBanner";
import { InviteAgentsModal } from "@/Components/EntityTable/Tables/AgentTable/InviteAgentsModal";
import { SettingBool } from "@shared/Enums/SettingEnums";

export function GetStartedGuide() {
	const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);
	const [addAgentModalOpen, setAddAgentModalOpen] = useState(false);

	const loggedInAgent = useAgent();

	const cache = useCache();

	return (
		<>
			{!cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) && loggedInAgent?.isAdmin &&
				<NewFeatureBanner title="AI Powered KB Article Drafts" link="/config/ai/assistant" />
			}
			<div className="p-10">
				<div className="font-semibold text-3xl">
					Welcome to GoDesk!
				</div>
				<div className="mt-5 mb-10">
					<div className="mb-4">
						You've just taken your first step to better customer service.

						{loggedInAgent?.isAdmin &&
							" Follow the steps below to begin setting up your help desk:"
						}
					</div>
				</div>
				{loggedInAgent?.isAdmin &&
					<>
						<div className="my-10">
							<div className="flex items-center font-semibold text-xl">
								<img className="h-7 mr-3" src="/emoji-envelope.png" />
								Connect Your Emails
							</div>
							<div className="my-3">
								Get started by linking your mailbox and pulling in your first emails.
							</div>

							<MuiButton component={Link} to="/config/manage/channels/email/-1" variant="contained">Link mailbox</MuiButton>
						</div>
						<div className="my-10">
							<div className="flex items-center font-semibold text-xl">
								<img className="h-7 mr-3" src="/invite.svg" />
								Invite Your Team
							</div>
							<div className="my-3">
								Add the people you work with to GoDesk. They will be sent an email invite with instructions to join you.
							</div>
							<MuiButton onClick={() => setAddAgentModalOpen(true)} variant="contained">Invite agents</MuiButton>
						</div>
					</>}

				{!cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) &&
					<div className="my-10 p-4  -mx-4 bg-[#F2F2F3] rounded-lg">
						<div className="flex items-center font-semibold text-xl">
							<img className="h-7 mr-3" src="robot-emoji.png" />
							Knowledge Base assistant
						</div>
						<div className="my-3">
							Save time and super charge your knowledge base with AI powered articles drafted for you.
						</div>
						{loggedInAgent?.isAdmin &&
							<Link to="/config/ai/assistant" >
								<Button label="Manage" btnClass="btn-purple" />
							</Link>
						}
					</div>
				}

				<div className="my-10">
					<div className="flex items-center font-semibold text-xl">
						<img className="h-7 mr-3" src="/emoji-telephone.png" />
						Need help? Got feedback?
					</div>
					<div className="my-3">
						Click the live chat button at the bottom right of the screen or drop me an email at <a className="text-blue-700" href="mailto:tom.roberts@getgodesk.com">tom.roberts@getgodesk.com</a>.
					</div>
					<MuiButton variant="outlined" onClick={() => setContactSupportModalOpen(true)} color="neutral">Message support</MuiButton>
				</div>

				<InviteAgentsModal isOpen={addAgentModalOpen} closeModal={() => setAddAgentModalOpen(false)} />
				<ContactSupportModal isOpen={contactSupportModalOpen} closeModal={() => setContactSupportModalOpen(false)} />
			</div>
		</>
	);
}
