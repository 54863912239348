import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ApiGetSettings, ApiUpdateSettings } from "@/legacy/ApiCallerOld";
import { SettingsBrandingPage } from "@/Pages/Config/SettingsBrandingPage";
import { SettingsNotificationsPage } from "@/Pages/Config/SettingsNotificationsPage";
import { SettingsHelpDesk } from "./SettingsHelpdesk";
import SettingsWorkday from "./SettingsWorkday";
import { SettingsEmailNotifications } from "./SettingsEmailNotifications";
import { Setting } from "@shared/Entities/EntityTypes";

export enum SettingPages {
	HELPDESK, WORKDAY, EMAILNOTIFICATIONS, BRANDING, NOTIFICATIONSPAGE,
}

interface SettingsPageProps {
	page: SettingPages;
}

export default function SettingsPage(props: SettingsPageProps) {
	const [data, setData] = useState<Setting[] | undefined>();

	switch (props.page) {
		case SettingPages.EMAILNOTIFICATIONS:
			document.title = "Automated Emails | Config | GoDesk";
			break;
		case SettingPages.HELPDESK:
			document.title = "Help Desk | Config | GoDesk";
			break;
		case SettingPages.WORKDAY:
			document.title = "Workdays | Config | GoDesk";
			break;
		case SettingPages.BRANDING:
			document.title = "Branding | Config | GoDesk";
			break;

	}

	useEffect(() => {
		getSettings();
	}, [props.page]);

	async function getSettings() {
		let params = undefined;

		switch (props.page) {
			case SettingPages.EMAILNOTIFICATIONS:
				params = { keyparent: "emailNotifications" };
				break;
			case SettingPages.HELPDESK:
				params = { keyparent: "" };
				break;
			case SettingPages.WORKDAY:
				params = { keyparent: "workday" };
				break;
			case SettingPages.BRANDING:
				params = { keyparent: "branding" };
				break;
		}

		const response = await ApiGetSettings(params);

		if (response != null && response.data != null) {
			setData(response.data);
		}
	}

	async function updateSettings(settingsData: Setting[]) {
		if (settingsData != null) {
			const response = await ApiUpdateSettings(settingsData);

			if (response.successful && response.data != null) {
				setData(response.data);
				toast.success("Settings updated.");
			} else {
				toast.error("Error. " + response.errorCode + ": " + response.errorMsg);
			}
		}
	}

	let page = null;

	switch (props.page) {
		case SettingPages.EMAILNOTIFICATIONS:
			page = <SettingsEmailNotifications data={data} updateData={updateSettings} refreshData={getSettings} />;
			break;
		case SettingPages.HELPDESK:
			page = <SettingsHelpDesk data={data} updateData={updateSettings} refreshData={getSettings} />;
			break;
		case SettingPages.WORKDAY:
			page = <SettingsWorkday data={data} updateData={updateSettings} />;
			break;
		case SettingPages.BRANDING:
			page = <SettingsBrandingPage data={data} updateData={updateSettings} refreshData={getSettings} />;
			break;
		case SettingPages.NOTIFICATIONSPAGE:
			page = <SettingsNotificationsPage />;
	}

	return (
		data != null ?
			page
			: null
	);
}
