import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEntity } from "@/legacy/useEntity";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import W_MultiEntitySelect from "@/Components/FormComponents/MultiEntitySelect/W_MultiEntitySelect";
import { TeamDisplay } from "@/Fragments/EntityViewer/EntitySpecific/Team/TeamDisplay";
import { MoveTicketsModal } from "@/Fragments/EntityViewer/EntitySpecific/Team/MoveTicketsModal";
import OtherFunctions from "../../../../Helpers/OtherFunctions";
import { EntityViewerToolbar } from "../../EntityViewerToolbar";
import W_EmojiKeyboard from "@shared/Components/FormComponents/EmojiKeyboard/W_EmojiKeyboard";
import { FormError } from "@shared/Components/Form/FormError";
import { AutoAssignMethod } from "@shared/Enums/Enums";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import { Team } from "@shared/Entities/EntityTypes";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { Entities } from "@shared/Entities/Entities";
import { FormHotkeys } from "@shared/Components/Utils/FormHotkeys";

export function TeamViewer() {
	const [id, setId] = useState<number>();
	const [editmode, setEditmode] = useState<boolean>(false);
	const [moveTicketsModalOpen, setMoveTicketsModalOpen] = useState<boolean>(false);

	const navigate = useNavigate();
	const params = useParams();
	const { data, formError, fieldErrors, updateEntity, createEntity, deleteEntity } = useEntity<Team>(id, Entities.TEAM);
	const { control, handleSubmit, reset, setError, watch } = useForm<Team>({ defaultValues: data });

	useEffect(() => {
		// Override form data when data changes.
		reset(data);
	}, [data]);

	useEffect(() => {
		// Override errors when they change in props.
		OtherFunctions.setFieldErrors(setError, fieldErrors);
	}, [fieldErrors]);

	// Get id from url.
	useEffect(() => {
		if (params.id != null && !isNaN(parseInt(params.id))) {
			const paramsId = parseInt(params.id);
			setId(paramsId);

			if (paramsId == -1) {
				setEditmode(true);
			}
		}
	}, [params.id]);

	async function onDelete() {
		const deleteSuccess = await deleteEntity();

		if (deleteSuccess) {
			navigate("/config/manage/teams");
		}
	}

	function cancelEdit() {
		setEditmode(false);
		reset(data);

		if (id == -1) {
			navigate("/config/manage/teams");
		}
	}

	async function formSubmit(data: Team) {
		if (data.id > 0) {
			const updateSuccess = await updateEntity(data);

			if (updateSuccess) {
				setEditmode(false);
			}
		} else {
			const newId = await createEntity(data);

			if (newId != null && newId > -1) {
				setEditmode(false);
				navigate("/config/manage/teams/" + newId);
			}
		}
	}

	const name = watch("name");

	if (id == null || data == null) {
		return;
	}

	return (
		<>
			<form onSubmit={handleSubmit(formSubmit)} className="h-full w-full flex flex-col">
				<FormHotkeys onSubmit={handleSubmit(formSubmit)} onCancel={cancelEdit} />

				<EntityViewerToolbar
					editmode={editmode}
					isNew={id == -1}
					backUrl="/config/manage/teams"
					onEdit={() => setEditmode(true)}
					onCancel={cancelEdit}
					onDelete={() => setMoveTicketsModalOpen(true)}
				/>

				{editmode ?
					<FormParent title={id == -1 ? "New Team" : (name ?? "")}>
						<FormError error={formError} />

						<FormSection title="Details">
							<div className="w-full flex gap-3">
								<W_EmojiKeyboard
									control={control}
									dataname="icon"
									label="Icon"
									anchorOrigin={{
										vertical: "center",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "left",
									}}
								/>
								<W_TextInput
									containerClassName="grow"
									control={control}
									dataname="name"
									label="Name"
									mandatory
									autoFocus={id < 0}
								/>
							</div>

							<W_TextInput
								control={control}
								dataname="description"
								label="Description"
							/>

							<W_MultiEntitySelect
								control={control}
								dataname="agentIds"
								label="Agents"
								cacheSlice={CacheSlices.Agents}
							/>
						</FormSection>

						<FormSection title="Ticket Assignment">
							<W_Dropdown
								control={control}
								dataname="autoAssignMethod"
								label="Auto Assign"
								toolTip="Auto assign will automatically assign new tickets to an agent. Round robin will assign tickets to agents in order. Load balance will assign tickets to the agent with the fewest open tickets."
								options={[
									{ label: "--", value: null },
									{ label: "Round Robin", value: AutoAssignMethod.ROUND_ROBIN },
									{ label: "Load balance", value: AutoAssignMethod.LOAD_BALANCE }
								]}
							/>
						</FormSection>
					</FormParent>
					:
					<TeamDisplay data={data} />}
			</form>

			<MoveTicketsModal
				isOpen={moveTicketsModalOpen}
				onCancel={() => setMoveTicketsModalOpen(false)}
				onComplete={onDelete}
				fromTeamIds={[data.id]}
			/>
		</>
	);
}
