import { useSearchParams } from "react-router-dom";
import { useCache } from "@/Hooks/useCache";
import { GmailOauthButton } from "./GmailOauthButton";
import { MsOauthButton } from "./MsOauthButton";
import { getMicrosoftError, getMicrosoftStatus } from "./getMicrosoftStatus";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { EmailSecurity } from "@shared/Enums/Enums";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";
import { FormParent } from "@shared/Components/Form/FormParent";
import { FormSection } from "@shared/Components/Form/FormSection";
import { FormReadBox } from "@shared/Components/Form/FormReadBox";
import { FormReadPair } from "@shared/Components/Form/FormReadPair";

interface DisplayEmailChannelProps {
	data: ChannelsEmail;
}

export function DisplayEmailChannel(props: DisplayEmailChannelProps) {
	const cache = useCache();
	const team = cache.getTeam(props.data.teamId);

	const [searchParams] = useSearchParams();
	const gmailres = searchParams.get("gmailres");
	const microsoftres = searchParams.get("microsoftres");
	const microsoftEmailFail = searchParams.get("microsoftemailfail");
	const codeIsExpired = searchParams.get("expired") == "true";

	const address = props.data.address;
	const isGodeskManaged = props.data.isGodeskManaged;
	const isGmail = props.data.isGmail;

	const isMicrosoft = props.data.isMicrosoft;
	const microsoftStatus = getMicrosoftStatus(props.data);
	const microsoftError = getMicrosoftError(props.data);

	const isOauth = isGmail || isMicrosoft;

	const tenantId = cache.getTenantId();

	let goDeskEmail = "support@" + tenantId + ".getgodesk.com";

	if (address != goDeskEmail) {
		goDeskEmail = address?.replaceAll("@", "").replaceAll(".", "") + "@" + tenantId + ".getgodesk.com";
	}

	return (
		<FormParent title={props.data.name} className="w-[550px]" >
			<FormSection title="Details">
				<FormReadBox>
					<FormReadPair
						name="Name"
						value={props.data.name}
					/>
					<FormReadPair
						name="Email"
						value={props.data.address}
					/>
					<FormReadPair
						name="Team"
						value={team?.name}
					/>
				</FormReadBox>

				{codeIsExpired &&
					<AlertBox alertType="error">
						Error adding connector. Code is expired. Please try again or contact our team at <a href="mailto:support@getgodesk.com" className="underline">support@getgodesk.com</a>.
					</AlertBox>}
			</FormSection>

			{isGmail &&
				<FormSection title="Gmail">
					{gmailres == "success" ?
						<AlertBox alertType="info">
							The Gmail connector has been added successfully.
						</AlertBox>
						: gmailres == "fail" ?
							<AlertBox alertType="error">
								Error adding Gmail connector. Please try again or Contact our team at <a href="mailto:support@getgodesk.com" className="underline">support@getgodesk.com</a>.
							</AlertBox>
							: null}

					<FormReadBox>
						<FormReadPair
							name="Status"
							value={props.data.hasGmailTokens ? "Active" : "Invalid. Please reconfigure."}
						/>
					</FormReadBox>

					<div className="py-2" />

					{!props.data.hasGmailTokens &&
						<GmailOauthButton channel={props.data} />
					}
				</FormSection>}

			{isMicrosoft &&
				<FormSection title="Microsoft">
					{microsoftres == "success" ?
						<AlertBox alertType="info">
							The Microsoft 365 connector has been added successfully.
						</AlertBox>
						: microsoftres == "fail" ?
							<AlertBox alertType="error">
								Error adding Microsoft 365 connector. Please try again or Contact our team at <a href="mailto:support@getgodesk.com" className="underline">support@getgodesk.com</a>.
							</AlertBox>
							: null}

					{microsoftEmailFail != null ?
						<AlertBox alertType="error">
							Error adding Microsoft 365 connector. Incorrect account used:

							<div className="grid grid-cols-3 grid-rows-2 my-3">
								<div >Expected:</div>
								<div className="col-span-2">{props.data.address}</div>
								<div className="row-start-2">Account used:</div>
								<div className="col-span-2 row-start-2">{microsoftEmailFail}</div>
							</div>

							If you need any extra help email us at: <a href="mailto:support@getgodesk.com" className="underline">support@getgodesk.com</a>.
						</AlertBox>
						: null }

					<FormReadBox>
						<FormReadPair
							name="Status"
							value={microsoftStatus ? "Active" : microsoftError}
						/>
					</FormReadBox>

					{!props.data.hasMsTokens &&
						<MsOauthButton channel={props.data} />
					}
				</FormSection>}

			{isGodeskManaged || isGmail ?
				<>
					{address != null && address != "" ?
						<div className="p-4">
							<div className="py-6">
								Now <a>forward emails</a> from this address to:
								<br />
								<span className="inline-block ml-1 italic border border-gray-300 bg-gray-200 p-1 my-2">{goDeskEmail}</span>
								<br />
								Any emails sent there will be converted into tickets in GoDesk.
							</div>

							{!isOauth &&
								<AlertBox alertType="info">
									Complete email setup using <a href="https://support.getgodesk.com/support/knowledge-base/93/godesk-email-setup" className="underline" target="_blank" >this guide</a> to ensure successful email delivery.
								</AlertBox>
							}
						</div>
						: null}
				</>
				: !isMicrosoft ?
					<>
						<FormSection title="IMAP Details">
							<FormReadBox>
								<FormReadPair
									name="Server"
									value={props.data.imapServer}
								/>
								<FormReadPair
									name="Port"
									value={props.data.imapPort ? props.data.imapPort.toString() : null}
								/>
								<FormReadPair
									name="Security"
									value={props.data.imapSecurity == EmailSecurity.STARTTLS ? "STARTTLS" : props.data.imapSecurity == EmailSecurity.TLSSSL ? "TLS / SSL" : "-"}
								/>
								<FormReadPair
									name="Username"
									value={props.data.imapUser}
								/>
								<FormReadPair
									name="Password"
									value={"-"}
								/>
							</FormReadBox>
						</FormSection>

						<FormSection title="SMTP Details">
							<FormReadBox>
								<FormReadPair
									name="Server"
									value={props.data.smtpServer}
								/>
								<FormReadPair
									name="Port"
									value={props.data.smtpPort ? props.data.smtpPort.toString() : null}
								/>
								<FormReadPair
									name="Security"
									value={props.data.smtpSecurity == EmailSecurity.STARTTLS ? "STARTTLS" : props.data.smtpSecurity == EmailSecurity.TLSSSL ? "TLS / SSL" : "-"}
								/>
								<FormReadPair
									name="Username"
									value={props.data.smtpUser}
								/>
								<FormReadPair
									name="Password"
									value={"-"}
								/>
							</FormReadBox>
						</FormSection>
					</>
					: null}
		</FormParent>
	);
}
