import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { IoCheckmarkCircleOutline, IoCloseCircleOutline, IoChevronDown, IoChevronUp, IoMailOutline } from "react-icons/io5";
import { Control, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import W_Radio from "@/Components/FormComponents/Radio/W_Radio";
import { useCache } from "@/Hooks/useCache";
import api from "@/Api/Api";
import { GmailOauthButton } from "./GmailOauthButton";
import { MsOauthButton } from "./MsOauthButton";
import { Button } from "../../../../Components/Button/Button";
import { EmailFunctions } from "../../../../Helpers/EmailFunctions";
import { ChannelTypeButton } from "./ChannelTypeButton";
import { getMicrosoftError, getMicrosoftStatus } from "./getMicrosoftStatus";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";
import { EmailSecurity, MailboxValidation } from "@shared/Enums/Enums";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";
import { InputTypes } from "@shared/Components/FormComponents/TextInput/InputTypes";
import { ChannelsEmail } from "@shared/Entities/EntityTypes";

interface EmailServerProps {
	control: Control<ChannelsEmail, any>;
	getValues: UseFormGetValues<ChannelsEmail>;
	setValue: UseFormSetValue<ChannelsEmail>;
	watch: UseFormWatch<ChannelsEmail>;
	save(): Promise<ChannelsEmail | undefined>;
	page: undefined | 1 | 2 | 3;
	advancePage(): void;
}

export function EmailServer(props: EmailServerProps) {
	const [imapValidity, setImapValidity] = useState<MailboxValidation>(MailboxValidation.UNTESTED);
	const [smtpValidity, setSmtpValidity] = useState<MailboxValidation>(MailboxValidation.UNTESTED);
	const [expanded, setExpanded] = useState<boolean>(true);
	const cache = useCache();

	const page = props.page != null ? props.page : 3;

	const securityOptions = [{ label: "STARTTLS", value: EmailSecurity.STARTTLS }, { label: "TLS / SSL", value: EmailSecurity.TLSSSL }];

	const address = props.watch("address");
	const isGodeskManaged = props.watch("isGodeskManaged");

	const tenantId = cache.getTenantId();

	let goDeskEmail = "support@" + tenantId + ".getgodesk.com";

	if (address != goDeskEmail) {
		goDeskEmail = address?.replaceAll("@", "").replaceAll(".", "") + "@" + tenantId + ".getgodesk.com";
	}

	function copyAndTrimData() {
		const dataCopy = { ...props.getValues() };

		dataCopy.imapServer = dataCopy.imapServer != null ? dataCopy.imapServer.trim() : undefined;
		dataCopy.imapUser = dataCopy.imapUser != null ? dataCopy.imapUser.trim() : undefined;
		dataCopy.smtpServer = dataCopy.smtpServer != null ? dataCopy.smtpServer.trim() : undefined;
		dataCopy.smtpUser = dataCopy.smtpUser != null ? dataCopy.smtpUser.trim() : undefined;

		return dataCopy;
	}

	async function imapTest() {
		const testResult = await EmailFunctions.imapTest(copyAndTrimData());

		setImapValidity(testResult ? MailboxValidation.VALID : MailboxValidation.INVALID);

		return testResult;
	}

	async function smtpTest() {
		const testResult = await EmailFunctions.smtpTest(copyAndTrimData());

		setSmtpValidity(testResult ? MailboxValidation.VALID : MailboxValidation.INVALID);

		return testResult;
	}

	async function imapNextClick() {
		const success = await imapTest();

		if (success) {
			const username = props.getValues("imapUser");
			const password = props.getValues("imapPass");

			props.setValue("smtpUser", username);
			props.setValue("smtpPass", password);
			props.advancePage();
		}
	}

	async function smtpNextClick() {
		const success = await smtpTest();

		if (success) {
			props.advancePage();
			setExpanded(false);
		}
	}

	function getStatusIcon(status: MailboxValidation): JSX.Element | null {
		switch (status) {
			case MailboxValidation.VALID:
				return <IoCheckmarkCircleOutline size={20} className="text-green-300" />;
			case MailboxValidation.DIRTY:
				return <IoCheckmarkCircleOutline size={20} className="text-yellow-200" />;
			case MailboxValidation.INVALID:
				return <IoCloseCircleOutline size={20} className="text-red-500" />;
			case MailboxValidation.UNTESTED:
				return null;
		}
	}

	async function oauthPrefire() {
		return await props.save();
	}

	const combinedStatusIcon = smtpValidity == MailboxValidation.UNTESTED || imapValidity == MailboxValidation.UNTESTED ? getStatusIcon(MailboxValidation.UNTESTED) :
		smtpValidity == MailboxValidation.INVALID || imapValidity == MailboxValidation.INVALID ? getStatusIcon(MailboxValidation.INVALID) :
			smtpValidity == MailboxValidation.DIRTY || imapValidity == MailboxValidation.DIRTY ? getStatusIcon(MailboxValidation.DIRTY) :
				smtpValidity == MailboxValidation.VALID || imapValidity == MailboxValidation.VALID ? getStatusIcon(MailboxValidation.VALID) : null;

	const isGmail = props.watch("isGmail") ?? false;
	const isMs = props.watch("isMicrosoft") ?? false;
	const gmailConfigured = props.watch("isGmail") && props.watch("gmailRefreshToken");
	const microsoftGood = getMicrosoftStatus(props.getValues());
	const microsoftError = getMicrosoftError(props.getValues());

	const isCustom = !isGmail && !isMs;

	const smtpLooksLikeImap = props.watch("smtpServer")?.toUpperCase().startsWith("IMAP.");
	const imapLooksLikeSmtp = props.watch("imapServer")?.toUpperCase().startsWith("SMTP.");

	useEffect(() => {
		let type;

		if (isGmail) {
			type = "Gmail";
		} else if (isMs) {
			type = "Microsoft";
		} else {
			type = isGodeskManaged ? "GoDesk managed" : "Direct connect";
		}

		api.metricEvent("new_email_type_selected", { type: type, address: address });
	}, []);


	function setChannelMs() {
		props.setValue("isGmail", false); props.setValue("isMicrosoft", true);
		api.metricEvent("new_email_type_selected", { type: "Ms365", address: address });
	}

	function setChannelGmail() {
		props.setValue("isGmail", true); props.setValue("isMicrosoft", false);
		api.metricEvent("new_email_type_selected", { type: "Gmail", address: address });
	}

	function setChannelCustom() {
		props.setValue("isGmail", false); props.setValue("isMicrosoft", false);
		api.metricEvent("new_email_type_selected", { type: isGodeskManaged ? "GoDesk managed" : "Direct connect", address: address });
	}

	return (
		<div className="bg-gray-100 flex flex-col shadow-md rounded-lg px-3">
			<div className="m-4 flex flex-row justify-between">
				<div className="flex items-center space-x-1">
					<span className="text-2xl">Email Server</span>
					{combinedStatusIcon}
				</div>

				<button className="cursor-pointer flex items-center hover:bg-gray-200 transition-colors rounded w-[30px] h-[30px]" type="button" onClick={() => setExpanded(!expanded)}>
					{ expanded ?
						<IoChevronDown size={30} />
						:
						<IoChevronUp size={30} /> }
				</button>
			</div>

			<motion.div
				className="overflow-hidden my-6"
				variants={{
					open: { height: "auto" },
					closed: { height: "0", margin: "0" }
				}}
				transition={{ ease: "easeInOut", duration: 0.5 }}
				animate={expanded ? "open" : "closed"}
			>
				<div className="flex justify-around">
					<ChannelTypeButton
						icon={<IoMailOutline size={30} />}
						label="Custom"
						onClick={setChannelCustom}
						active={isCustom}
					/>

					<ChannelTypeButton
						icon={<img title="Gmail Setup" alt="Gmail" src="/gmail-logo.svg" className="p-6" />}
						label="Gmail"
						tooltip="Works for personal Gmail accounts and Google workspace accounts."
						onClick={setChannelGmail}
						active={isGmail}
					/>

					<ChannelTypeButton
						icon={<img title="Microsoft Setup" alt="Microsoft" src="/outlook-logo.svg" className="p-6" />}
						label="Microsoft 365"
						tooltip="Works for personal Outlook / Hotmail accounts and Microsoft 365 accounts."
						onClick={setChannelMs}
						active={isMs}
					/>
				</div>

				{ isGmail &&
					<>
						<GmailOauthButton channel={props.watch()} prefireCallback={oauthPrefire} />

						{ gmailConfigured &&
							<AlertBox alertType="info">
								Gmail connector active.
							</AlertBox>}
					</>
				}

				{ isMs &&
					<>
						<MsOauthButton channel={props.getValues()} prefireCallback={oauthPrefire} />

						{ microsoftGood &&
							<AlertBox alertType="info">
								Microsoft connector active.
							</AlertBox>}

						{ props.getValues().id > 0 && microsoftError &&
							<AlertBox alertType="error">
								{microsoftError}
							</AlertBox>}
					</>
				}

				{ isCustom &&
					<>
						<div className="flex justify-around my-4 ">
							<W_Radio<boolean>
								control={props.control}
								dataname="isGodeskManaged"
								options={[
									{ label: "Direct connect", value: false },
									{ label: "GoDesk managed", value: true }
								]}
							/>
						</div>

						{isGodeskManaged || isGodeskManaged == undefined ?
							<>
								{address != null && address != "" &&
									<div className="py-6">
										Save this channel, then <a>forward emails</a> from this address to:
										<br />
										<span className="inline-block ml-1 italic border border-gray-300 bg-gray-200 p-1 my-2">{goDeskEmail}</span>
										<br />
										Any emails sent there will be converted into tickets in GoDesk.
									</div>}
							</>
							:
							<>
								<span className="text-lg flex items-center space-x-2">
									<span>IMAP Details</span>
									{getStatusIcon(imapValidity)}
								</span>
								<div className="mx-2">
									<div className="flex flex-row justify-between">
										<W_TextInput
											control={props.control}
											className="w-full"
											dataname="imapServer"
											label="Server address"
											placeholder="imap.example.com"
											mandatory
										/>

										<W_TextInput
											control={props.control}
											className="w-[120px] ml-2"
											dataname="imapPort"
											type={InputTypes.Number}
											label="Port"
											mandatory
										/>
									</div>

									{imapLooksLikeSmtp &&
										<div className="pb-1">
											<AlertBox alertType="warning">
												This address looks like it might be an SMTP server. (Starts with 'smtp.')
											</AlertBox>
										</div>}

									<W_Dropdown
										control={props.control}
										dataname="imapSecurity"
										label="Connection security"
										options={securityOptions}
										mandatory
									/>

									<W_TextInput
										control={props.control}
										dataname="imapUser"
										label="Username / Email address"
										placeholder="support@example.com"
										mandatory
									/>

									<W_TextInput
										control={props.control}
										dataname="imapPass"
										type={InputTypes.Password}
										minLength={0}
										label="Password"
										mandatory
									/>

									{ page == 1 ?
										<Button btnClass="btn-blue" className="ml-2 mb-4" label="Next" asyncOnClick={imapNextClick} />
										:
										<Button label="Test IMAP" asyncOnClick={async () => {await imapTest();}} className="mb-4" />}
								</div>

								{ page > 1 &&
									<>
										<span className="text-lg flex items-center space-x-2">
											<span>SMTP Details</span>
											{getStatusIcon(smtpValidity)}
										</span>

										<div className="mx-2">
											<div className="flex flex-row justify-between">
												<W_TextInput
													control={props.control}
													className="w-full"
													dataname="smtpServer"
													label="Server address"
													placeholder="smtp.example.com"
													mandatory
												/>

												<W_TextInput
													control={props.control}
													className="w-[120px] ml-2"
													dataname="smtpPort"
													type={InputTypes.Number}
													label="Port"
													mandatory
												/>
											</div>

											{smtpLooksLikeImap &&
												<AlertBox alertType="warning">
													This address looks like it might be an IMAP server.(Starts with 'imap.')
												</AlertBox>}

											<W_Dropdown
												control={props.control}
												dataname="smtpSecurity"
												label="Connection security"
												options={securityOptions}
												mandatory
											/>

											<W_TextInput
												control={props.control}
												dataname="smtpUser"
												label="Username / Email address"
												placeholder="support@example.com"
												mandatory
											/>

											<W_TextInput
												control={props.control}
												dataname="smtpPass"
												type={InputTypes.Password}
												minLength={0}
												label="Password"
												mandatory
											/>

											{ page == 2 ?
												<Button
													aria-busy
													btnClass="btn-blue"
													className="mb-4"
													label="Next"
													asyncOnClick={smtpNextClick}
												/>
												:
												<Button label="Test SMTP" asyncOnClick={async () => {await smtpTest();}} className="mb-4" />}
										</div>
									</>}
							</>}
					</>
				}
			</motion.div>
		</div>
	);
}
