import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoInfo } from "react-icons/go";
import toast from "react-hot-toast";
import { Alert } from "@mui/material";
import { ApiUpdateEntity } from "@/legacy/ApiCallerOld";
import { HeaderTabEntities } from "@/Components/Header/GlobalTab";
import { useCache } from "@/Hooks/useCache";
import api, { getApiUrl } from "@/Api/Api";
import { useAgent } from "@/Hooks/useAgent";
import { TicketAiSection } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketAiSection";
import { useAddCollisionNote, useGetCollisionNotes, useRemoveAllCollisionNotes } from "@/Api/genApi";
import { CollisionNoteAction } from "@/Api/genApi.schemas";
import { useAppDispatch, useAppSelector } from "@/Store/hooks";
import { TicketHistorySection } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketHistory";
import { TicketSlaSection } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketSlaSection";
import { ActionButtons } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/ActionButtons";
import { TicketNewAction } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketNewAction";
import { TicketHeader } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketHeader";
import { TicketDetails } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/TicketDetails";
import OtherFunctions from "@/Helpers/OtherFunctions";
import { globalTabRemoved } from "@/Store/Reducers/globalTabsSlice";
import { getNewUrlAfterTabClose } from "@/Components/Header/HeaderTabs";
import { TicketActions } from "@/Fragments/EntityViewer/EntitySpecific/Ticket/Action/TicketActions";
import { SettingBool } from "@shared/Enums/SettingEnums";
import { Hotkeys } from "@shared/lib/Hotkeys";
import { Entities } from "@shared/Entities/Entities";
import { InfoToast } from "@shared/Components/Utils/Toasts";
import { Ticket, Action } from "@shared/Entities/EntityTypes";
import { FormErrorCode, GD_SUPPORT_AGENT_ID, TicketStatuses } from "@shared/Enums/Enums";
import { AlertBox } from "@shared/Components/AlertBox/AlertBox";

interface TicketViewerProps {
	id: number;
	data?: Ticket;
	createNew(data: Ticket): Promise<void>;
	updateData(data: Ticket): Promise<void>;
	refreshData(): void; // This is needed as the TicketViewer is also responsible for Actions.
}

export function TicketViewer(props: TicketViewerProps) {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const cache = useCache();

	const [addingNewAction, setAddingNewAction] = useState(false);
	const [newActionIsPublic, setNewActionIsPublic] = useState(false);
	const [data, setData] = useState<Ticket | undefined>();
	const [attemptedToSetRead, setAttemptedToSetRead] = useState(false);

	const agent = useAgent();
	const tabsState = useAppSelector(state => state.globalTabs);

	const addCollisionNote = useAddCollisionNote();
	const removeAllCollisionNotes = useRemoveAllCollisionNotes();

	const collisionNotes = useGetCollisionNotes("TICKET", props.id, { query: {
		refetchInterval: 5000
	} });

	const sendCollisionNote = useCallback((action: CollisionNoteAction) => {
		if (agent?.id != null) {
			addCollisionNote.mutate({ data:
				{
					action: action,
					agentId: agent.id,
					entity: "TICKET",
					entityId: props.id
				}
			});
		}
	}, [agent?.id, props.id]); // addCollisionNote excluded.

	useEffect(() => {
		// Override internal data when data changes in props.
		setData(props.data);

		setTicketRead();
	}, [props.data]); // setTicketRead excluded - legacy.

	useEffect(() => {
		sendCollisionNote("VIEWING");

		return () => {
			removeAllCollisionNotes.mutate({ entity: "TICKET", id: props.id });
		};
	}, [sendCollisionNote, props.id]); // removeAllCollisionNotes excluded.

	const onVisibilityChange = useCallback(() => {
		if (agent?.id != undefined) {
			if (document.hidden && agent?.id != undefined) {
				const url = getApiUrl() + "/agent_collisions/remove_all/TICKET/" + props.id + "?agentId=" + agent.id;

				navigator.sendBeacon(url);
			} else {
				sendCollisionNote("VIEWING");
			}
		}
	}, [agent?.id, props.id, sendCollisionNote]);

	useEffect(() => {
		// Unfortunatly, this fires for closing the tab AND just navigating away. We should find a way to fix this!
		document.addEventListener("visibilitychange", onVisibilityChange);

		return () => document.removeEventListener("visibilitychange", onVisibilityChange);
	}, [onVisibilityChange]);

	function setTicketRead() {
		// Weird check to get around EntityViewer PUTing a user as a ticket when you switch between those screens.
		// Remove this when we get rid of EntityViewer.
		if (props.data == null || props.data.ticketread == null) {
			return;
		}

		if (props.data != null && props.data.ticketread != true && !attemptedToSetRead && agent?.id != GD_SUPPORT_AGENT_ID) {
			const dataCopy = Object.assign({}, props.data);
			const newData = OtherFunctions.SafeChangeProperty(dataCopy, "ticketread", true);

			// Auto update if id != -1
			if (props.id != -1) {
				props.updateData(newData);
			} else {
				// If this is a new ticket store the changes locally.
				setData(newData);
			}

			setAttemptedToSetRead(true); // So it doesn't get stuck in an infinite loop if there's an issue.
		}
	}

	async function addReply() {
		sendCollisionNote("ADDING");

		setAddingNewAction(true);
		setNewActionIsPublic(true);
	}

	function addPrivateNote() {
		sendCollisionNote("ADDING");

		setAddingNewAction(true);
		setNewActionIsPublic(false);
	}

	function clearActionPassback() {
		setAddingNewAction(false);

		sendCollisionNote("STOPPED_ADDING");
	}

	async function addAction(action: Action) {
		const res = await api.createEntity<Action>(Entities.ACTION, action);

		if (res.successful) {
			setAddingNewAction(false);

			props.refreshData();
		} else if (res.formErrorCode == FormErrorCode.SUPPORT_AGENT_ACTION) {
			toast.error("Remote support agent cannot add an action on a client instance.");
		} else {
			toast.error("Could not send message: " + res.errorMsg);
		}
	}

	/**
	 * @deprecated This ruins TS! Use useForm to keep and change an obj in state.
	 */
	async function handleChangeInFormComponent(key: string, newValue: any) {
		const dataCopy = Object.assign({}, data);
		const newData = OtherFunctions.SafeChangeProperty(dataCopy, key, newValue);

		// Auto update if id != -1
		if (props.id != -1) {
			await props.updateData(newData);
		} else {
			// If this is a new ticket store the changes locally.
			setData(newData);
		}
	}

	async function closeTicket() {
		await handleChangeInFormComponent("statusId", TicketStatuses.CLOSED);

		toast.success("Ticket closed.");
		dispatch(globalTabRemoved({ id: props.id, entity: HeaderTabEntities.TICKETS }));
		navigate("/tickets");
	}

	async function updateAction(action: Action) {
		await ApiUpdateEntity(Entities.ACTION, action.id, action);

		props.refreshData();
	}

	async function deleteAction(id: number) {
		const res = await api.deleteEntity(Entities.ACTION, id);

		if (!res.successful) {
			toast.error("Could not delete private note.");
		}

		props.refreshData();
	}

	async function deleteTicket() {
		let newData = Object.assign({}, props.data);
		newData = OtherFunctions.SafeChangeProperty(newData, "deleted", true);

		await props.updateData(newData);

		navigate(getNewUrlAfterTabClose(tabsState.tabs, { id: newData.id, entity: HeaderTabEntities.TICKETS, title: "" }));
		dispatch(globalTabRemoved({ id: newData.id, entity: HeaderTabEntities.TICKETS }));

		toast("Ticket deleted.", InfoToast);
	}

	async function undeleteTicket() {
		let newData = Object.assign({}, props.data);
		newData = OtherFunctions.SafeChangeProperty(newData, "deleted", false);

		await props.updateData(newData);

		toast("Ticket un-deleted.", InfoToast);
	}

	const mergedParentTicket = props.data?.mergedTickets;
	const mergedIntoTicket = data?.mergedIntoTicket;

	const mergedTicketLinks = mergedParentTicket?.map(mergedParentTicket =>
		<Link to={"/tickets/" + mergedParentTicket.id} className="underline ml-1">#{mergedParentTicket.id} {mergedParentTicket.description}</Link>
	);

	const mergedTicketParent = mergedParentTicket && mergedParentTicket.length > 0 &&
		<div className="bg-white pl-3 pr-3 pt-3 -mb-3">
			<AlertBox alertType="info">
				The following tickets have been merged into this:{mergedTicketLinks}
			</AlertBox>
		</div>;

	const mergedTicketChild = mergedIntoTicket &&
		<div className="bg-white pl-3 pr-3 pt-3 -mb-3">
			<AlertBox alertType="info">
				This ticket has been merged into: <Link to={"/tickets/" + mergedIntoTicket.id} className="underline">#{mergedIntoTicket.id} {mergedIntoTicket.description}</Link>
			</AlertBox>
		</div>;

	if (props.id == -1) {
		return (
			<Alert severity="error">
				This is an invalid ticket ID.
			</Alert>
		);
	}

	if (data == null) {
		return "LOADING!!!!";
	}

	return (
		<div className="flex h-full w-full">
			<Hotkeys hotkeys="r" callback={addReply} />
			<Hotkeys hotkeys="p" callback={addPrivateNote} />

			<div className="w-[18rem] lg:w-[22rem] overflow-auto border-r border-gray-300 shrink-0">
				<div className="text-center py-3"><GoInfo /> DETAILS</div>
				<TicketDetails ticket={data} handleSubmit={handleChangeInFormComponent} />
			</div>
			<div className="flex-grow h-full flex flex-col overflow-x-auto">
				<TicketHeader
					ticket={data}
					handleSubmit={handleChangeInFormComponent}
					collisionNotes={collisionNotes.data}
				/>

				<div className="overflow-y-auto grow flex flex-col">
					{mergedTicketParent}
					{mergedTicketChild}

					{addingNewAction &&
						<TicketNewAction
							ticket={data}
							isPublic={newActionIsPublic}
							clearActionPassback={clearActionPassback}
							addActionPassback={addAction}
						/>}

					<div className="bg-white p-3 flex-grow">
						<TicketActions ticket={data} updatePrivateNote={updateAction} deletePrivateNote={deleteAction} />
					</div>
				</div>
			</div>
			<div className="w-[18rem] lg:w-[22rem] overflow-auto border-l border-gray-300 shrink-0 flex flex-col">
				<div className="text-center py-3">ACTIONS</div>
				<ActionButtons
					ticket={data}
					addReply={addReply}
					addPrivateNote={addPrivateNote}
					closeTicket={closeTicket}
					deleteTicket={deleteTicket}
					undeleteTicket={undeleteTicket}
				/>
				{agent?.isAdmin && !cache.getSettingBool(SettingBool.GPT_INSTRUCTIONS_SAVED) &&
					<>
						<div className="border-t border-gray-300 text-center py-3">ASSISTANT TOOLS</div>
						<TicketAiSection />
					</>
				}
				<div className="border-t border-gray-300 text-center py-3">SLA DETAILS</div>
				<TicketSlaSection ticket={data} handleSubmit={handleChangeInFormComponent} />
				<div className="border-t border-gray-300 text-center py-3 uppercase">Ticket History</div>
				<TicketHistorySection ticketId={data.id} ticketHistory={data.ticketHistory} />
			</div>
		</div>
	);
}
