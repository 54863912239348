import { useState } from "react";
import toast from "react-hot-toast";
import { ApiMicrosoftGetOauthCode } from "@/legacy/ApiCallerOld";
import { OauthButtonProps } from "./GmailOauthButton";

export function MsOauthButton(props: OauthButtonProps) {
	const [awaitingCode, setAwaitingCode] = useState(false);

	async function onClick() {
		if (awaitingCode) {
			return;
		}

		let channel = props.prefireCallback != null ? await props.prefireCallback() : props.channel;

		// Replace the channel var if we are in edit mode. (prefireCallback will return undefined).
		if (channel == undefined) {
			channel = props.channel;
		}

		setAwaitingCode(true);

		// Send request for our state code to the BE.
		const res = await ApiMicrosoftGetOauthCode(channel);

		setAwaitingCode(false);

		if (!res.successful || res.data == undefined || res.data.length < 1) {
			toast.error("Error. " + res.errorCode + ": " + res.errorMsg);
			return;
		}

		const microsoftAuthUrl = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize";

		let redirectUri: string;

		if (process.env.NODE_ENV == "development") {
			redirectUri = "http://localhost:8080/oauth"; // Dev env.
		} else if (window.location.hostname == "support.getgodesk.com" || window.location.hostname == "emailtest.getgodesk.com") {
			redirectUri = "https://oauth-stag.getgodesk.com"; // Staging.
		} else {
			redirectUri = "https://oauth.getgodesk.com"; // Production.
		}

		const scopes = "offline_access openid email Mail.ReadWrite Mail.ReadWrite.Shared Mail.Send Mail.Send.Shared";

		const searchParams = new URLSearchParams({
			client_id: "c9060471-c6fe-4d22-b932-96fa5a77ced6",
			redirect_uri: redirectUri,
			scope: scopes,
			response_type: "code",
			response_mode: "query",
			state: res.data[0]
		});

		const authUrl = microsoftAuthUrl + "?" + searchParams.toString();

		window.location.href = authUrl;
	}

	return (
		<img
			title="Activate MS 365"
			alt="Activate MS 365"
			className="cursor-pointer w-[215px]"
			onClick={onClick}
			src="/ms-signin-light.png"
		/>
	);
}
