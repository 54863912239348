import { RxCrossCircled } from "react-icons/rx";
import Tooltip from "@mui/material/Tooltip";
import { Alert, CircularProgress } from "@mui/material";
import toast from "react-hot-toast";
import { useCache } from "@/Hooks/useCache";
import { getGetPendingAgentInvitesQueryKey, useGetPendingAgentInvites, useRevokeAgentInvite } from "@/Api/genApi";
import { queryClient } from "@/Api/UseQueryProvider";
import { Button } from "../../../Button/Button";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";

interface PendingAgentInvitesModalProps {
	isOpen: boolean;
	closeModal(): void;
}

export function PendingAgentInvitesModal(props: PendingAgentInvitesModalProps) {
	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.closeModal}
			title="Pending agent invites"
		>
			<ModalContent>
				<Invites {...props} />
			</ModalContent>

			<ModalButtons>
				<Button onClick={props.closeModal} label="Close" />
			</ModalButtons>
		</Modal>
	);
}

function Invites(props: PendingAgentInvitesModalProps) {
	const cache = useCache();

	const pendingInvitesReq = useGetPendingAgentInvites({ query: {
		enabled: props.isOpen
	} });

	const revokeInviteReq = useRevokeAgentInvite({ mutation: {
		onSettled: () => queryClient.invalidateQueries({ queryKey: getGetPendingAgentInvitesQueryKey() }),
		onError: error => toast.error("Could not remove invite: " + error.errorMsg)
	} });

	function getTeamNameList(ids: number[]): string {
		return ids.map(id => cache.getTeam(id)?.name)
			.filter(name => name != null)
			.join(", ");
	}

	function revokeInvite(email: string) {
		revokeInviteReq.mutate({ params: { email: email } });
	}

	if (pendingInvitesReq.isPending) {
		return <CircularProgress />;
	}

	if (pendingInvitesReq.error) {
		return <Alert severity="error">
			Could not load report.
		</Alert>;
	}

	return (
		pendingInvitesReq.data != null && pendingInvitesReq.data.length > 0 ?
			<div>
				<div className="grid grid-cols-10 grid-rows-1 gap-4 pl-4">
					<div className="col-span-3 font-semibold">Email</div>
					<div className="col-span-5 font-semibold">Teams</div>

					<div className="mr-4 font-semibold">Role</div>

					<div className="font-semibold">Cancel</div>
				</div>

				{pendingInvitesReq.data.map(invite => (
					<div key={invite.email} className="border rounded-lg shadow-sm overflow-hidden p-4 my-4">
						<div className="grid grid-cols-10 grid-rows-1 gap-4">
							<div className="col-span-3 truncate pr-4">{invite.email}</div>
							<div className="col-span-5 truncate">{getTeamNameList(invite.teams)}</div>

							<div className=" px-4 col-span-1">
								{invite.isAdmin ? "Admin" : "User"}
							</div>
							<div className="pl-8 col-span-1">
								<Tooltip title="Cancel invite">
									<button
										onClick={() => revokeInvite(invite.email)}
										className="text-red-700"
										disabled={revokeInviteReq.isPending}
									>
										<RxCrossCircled size={20} />
									</button>
								</Tooltip>
							</div>
						</div>
					</div>
				))}
			</div>
			:
			<div>
				<div className="text-lg font-medium">No active pending invites.</div>
			</div>
	);
}
