import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Markdown from "react-markdown";
import { Button } from "@/Components/Button/Button";
import W_CacheDropdown from "@/Components/FormComponents/CacheDropdown/W_CacheDropdown";
import { CacheSlices } from "@/Store/Reducers/cache/CacheTypes";
import api from "@/Api/Api";
import { PostOneNew } from "@/legacy/ApiHttp";
import { useCache } from "@/Hooks/useCache";
import Modal from "@shared/Components/Modal/Modal";
import ModalButtons from "@shared/Components/Modal/ModalButtons";
import ModalContent from "@shared/Components/Modal/ModalContent";
import W_TextInput from "@shared/Components/FormComponents/TextInput/W_TextInput";
import { KbArticle, kbArticleContent } from "@shared/Entities/EntityTypes";
import { Entities } from "@shared/Entities/Entities";
import W_Dropdown from "@shared/Components/FormComponents/Dropdown/W_Dropdown";

interface CreateKbArticleModalProps {
	isOpen: boolean;
	onClose(): void;
	articleDraft: string;
	articleDraftHtml: string;
}

interface KnowledgeBaseFormDataType {
	kbSectionId?: number;
	kbId: number;
	articleTitle: string;
}

export function CreateKbArticleModal(props: CreateKbArticleModalProps) {
	const navigate = useNavigate();
	const { control, handleSubmit, watch, setValue } = useForm<KnowledgeBaseFormDataType>();
	// Get the cache
	const cache = useCache();

	// watch form.kbId
	const kbId = watch("kbId");

	useEffect(() => {
		setValue("kbSectionId", undefined);
	}, [kbId, setValue]);

	// Filter down the cache's KB folders based on form.kbId.
	const kbfolders = cache.KbSections.filter(section => section.kbId == kbId);

	// Map the folders into dropdown options.
	const kbFolderDropdownOptions = kbfolders.map(folder => {
		return {
			label: folder.name, value: folder.id
		};

	});

	// (In the render) use the dropdown opts in a W_Dropdown

	async function onSubmit(data: KnowledgeBaseFormDataType) {
		if (data.kbSectionId == null) {
			return;
		}

		const createKbArticleData: KbArticle = { defaultArticleTitle: data.articleTitle, id: -1, kbSectionId: data.kbSectionId, published: false };

		const res = await api.createEntity<KbArticle>(Entities.KBARTICLE, createKbArticleData);

		if (res.data != null && res.successful) {

			const content: kbArticleContent = {
				id: {
					articleId: res.data.id,
					language: "en_GB"
				},
				title: data.articleTitle,
				body: props.articleDraftHtml
			};

			const createArticleContent = await PostOneNew("kb_article_content", content);

			if (createArticleContent.data != null && createArticleContent.successful) {
				toast.success("Draft saved");
				navigate("/kb/article/" + res.data.id);
			} else {
				toast.error("Could not create draft");
			}
		} else {
			toast.error("Could not create draft");
		}
	}

	function close() {
		props.onClose();
	}

	return (
		<Modal
			className="w-[700px]"
			isOpen={props.isOpen}
			onClose={close}
			title="Create article draft"
		>

			<ModalContent>
				<div >
					<W_TextInput control={control} dataname="articleTitle" label="Article name" mandatory />
					<W_CacheDropdown
						control={control}
						cacheSlice={CacheSlices.Kbs}
						dataname="kbId"
						label="Knowledge Base"
						mandatory
					/>

					{
						<W_Dropdown
							control={control}
							options={kbFolderDropdownOptions}
							dataname="kbSectionId"
							label="Knowledge Base folder"
							mandatory
						/>
					}
				</div>

				<div className="overflow-auto h-[300px]">
					<div className="text-xl py-3">Article draft</div>
					<Markdown>
						{props.articleDraft}
					</Markdown>
				</div>
			</ModalContent>

			<ModalButtons>
				<Button
					btnClass="btn-grey"
					label="Cancel"
					onClick={close}
				/>

				<Button
					btnClass="btn-blue"
					label="Save as draft"
					onClick={() => handleSubmit(onSubmit)()}
				/>
			</ModalButtons>
		</Modal>
	);
}
